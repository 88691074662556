export const TokenInitialState = {
    TokenArr: [],
    Tokenloading: false,


};
export const TokenReducer = (state = TokenInitialState, action) => {
    console.log(action);
    switch (action.type) {

        case 'INITIAL_TOKENLOADING':
            return {
                ...state,
                Tokenloading: action.TokenLoading
            }

        case 'INITIAL_TOKEN':

            return {
                ...state,
                TokenArr: [...state.TokenArr, action.payload],

                Tokenloading: true,

            };

        default:
            return state
    }
}