import styled from "styled-components";


export const FooterTitle = styled.p`
font-family:Sofia Pro;
color:#fff;
font-size:18px;
margin-bottom:30px ;

`
export const FooterTitle1 = styled.p`
font-family:Sofia Pro;
color:#fff;
font-size:18px;

`
export const FooterSubTitle = styled.p`
font-family:Sofia Pro;
color:#fff;
font-size:16px;
opacity:0.7;
`

export const MarketPlaceContainer = styled.div`
 display:flex;
 flex:1;
 justify-content:center;
 align-items:center;
 flex-direction:column;
`
export const MarketplaceBtn = styled.div`
   width:100%;
   height:50px;
   border-radius:50px;
   display:flex;
   justify-content:center;
   align-items:center;
margin-bottom:45px ;
   background-color:#3E9ED6;
   color:#fff;
`
export const FooterFlexCombine = styled.div` 
display:flex ;
width:100% ;
margin-top:5px ;

`
export const FooterIconBox = styled.button` 
width:23% ;
height:35px ;
border-radius:5px ;
outline:none ;
cursor: pointer;
background-color:rgb(24 40 56) ;
border-color:white ;
margin-right:2.5% ;
`
export const FooterFirst = styled.div` 
width:16.6%;
margin-top:50px ;

`
export const FooterFirst1 = styled.div` 
width:17.6%;
margin-top:50px ;

`
export const FooterMiddleContain = styled.div` 
display:flex;
width:87% ;
height:auto;
margin-bottom:40px ;
background-color: rgb(24 40 56) ;
`