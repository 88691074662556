import React from "react"



export const Section4 =()=>{
    return (
        <React.Fragment>
             <section className="section4">
        <div>
            <h2 className="section_3_title">Why AirNFTs?</h2>
        </div>
        <div>
            <p className="section_3_subtitle">Easy user experience, low fees, one-click minting, AirNFTs was built for artists by artist</p>
        </div>
        <div className="image_container">
            <img src="https://uploads-ssl.webflow.com/60098c5b239e4890fa5ee1aa/60b3e5b3ead2119b2cb169e8_why-airnfts.png" loading="lazy"  alt="Difference between airnfts, superrare, rarible, opensea and foundation" className="image-11"/>
        </div>
    </section>
        </React.Fragment>
    )
}