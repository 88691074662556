import { useCallback } from "react";
import { useMoralis } from "react-moralis";
import { useAppContext } from "../../context/AppContext";



export const useConnect = ()=>{
    const {  authenticate } = useMoralis()
   const UseConnectFunction = useCallback(async()=>{
        try{
          return  await authenticate()
         
        }
        catch(error){
           console.log(error)
        }
    })
    return UseConnectFunction
}