import React from "react"



export const Section6 =()=>{
    return (
        <React.Fragment>
            <section className="section6">
        <div className="container">
                <div className="row">
                      <div className="col">
                            <div className="section6_left">
                                <h2 className="section6_title">The AIRT <br/> Token</h2>
                            </div>
                            <div className="responsive_section6_left">
                                <h2 className="section6_title">The AIRT  Token</h2>
                            </div>
                      </div>
                      <div className="col section6_right_container" >
                            <div className="section6_right">
                                 <h5 className="section6_right_subtitle">Token economy is built around the AIRT Token, a unit of value on the platform that enables token holders to use applications on this platform, participate in network governance, and earn additional token rewards by using this project.</h5>
                                 <div className="section6_btn_container">
                                    <button className="section6_btn">
                                          Buy AIRT
                                    </button> 
                                    <button className="section6_right_btn">
                                       Roadmap
                                  </button> 
                                 </div>
                               </div>
                            </div>
                            
                </div>
        </div>
    </section>
        </React.Fragment>
    )
}