export const TipInitialState = {
    TipArr: [],
    Tiploading: false,


};
export const TipReducer = (state = TipInitialState, action) => {

    switch (action.type) {

        case 'INITIAL_TIPLOADING':
            return {
                Tiploading: action.TipLoading
            }

        case 'INITIAL_TIP':

            return {
                ...state,
                TipArr: action.payload,
                Tiploading: true,

            };

        default:
            return state
    }
}