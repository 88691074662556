import styled from "styled-components"
export const SectionContainer = styled.div` 
width:100%;
height:auto;
background-color:rgb(10, 25, 41) ;
display:flex ;
justify-content:center ;
`
export const SectionInnerContainer = styled.div` 
width:87%;
height:auto ;
padding-top:15px ;

background-color:rgb(10, 25, 41);
`
export const SectionHeader = styled.div` 
width:100% ;
display:flex ;
justify-content:center ;
margin-bottom:30px ;

`
export const SectionHeaderContent = styled.div` 
font-size:40px ;
color:white ;
font-family:Sofia Pro ;
font-weight:bold ;
`
export const SectionCard = styled.div` 
width:22% ;
height:auto ;
border-radius:12px ;
padding:20px ;
background-color:rgb(0,30,60) ;
display:flex ;
flex-direction:column ;
margin-bottom:20px ;
`
export const CardHead = styled.div` 
display:flex ;
justify-content:center ;
padding-bottom:15px ;
`
export const Cardtitle = styled.div` 
color:white ;
font-weight:bold ;
font-size:20px ;
font-family:Sofia Pro ;
`
export const SectionCardMiddle = styled.div` 
display:flex ;
margin-top:5px ;

justify-content:space-between ;

`
export const SectionFlex = styled.div` 
display:flex ;
`
export const SectionContentHead = styled.div` 
font-size:17px ;
font-family:Sofia Pro ;
margin-bottom:3px ;
display:flex ;
//width:105px ;
color:white ;
font-weight:500 ;

`
export const SectionFlexContent = styled.div` 
color:#94A1B7 ;
font-weight:bold ;
font-size:16px ;

font-family:Sofia Pro ;

`
export const TipButton = styled.button` 
justify-content: center;
  flex-wrap: wrap;
  width:110px ;
  height:40px ;
  font-family: Sofia Pro;
  //flex: 0 0 auto;
  border: 2px solid #2f6dff;
  border-radius: 10px;
  background-color: #fff;
  color: #2f6dff;
  font-weight: 600;
`
export const CardFooter = styled.div` 
display:flex ;
justify-content:center ;
padding-bottom:5px ;
padding-top:15px ;
`
