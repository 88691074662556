import StarIcon from '@mui/icons-material/Star';
import HandshakeIcon from '@mui/icons-material/Handshake';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PersonIcon from '@mui/icons-material/Person';
export const TokenData = [

    {
        name: "Bitcoin (BTC)",
        transaction: "0.30488306 BTC",
        image: <StarIcon />,
        payment: "13,633.15",
        id: "@44716.0000000000",
        token_id: "1"
    },
    {
        name: "Geist DAI (gDAI)",
        transaction: "0.30488306 gDAI",
        image: <HandshakeIcon />,
        payment: "121.15",
        id: "@44716.0000000000",
        token_id: "2"

    },
    {
        name: "DEUS (DEUS)",
        transaction: "0.30488306 DEUS",
        image: <AttachMoneyIcon />,
        payment: "72,633.15",
        id: "@44716.0000000000",
        token_id: "3"

    },
    {
        name: "Geist FTM (gFTM)",
        transaction: "0.30488306 BTC",
        image: <PersonIcon />,
        payment: "30,633.15",
        id: "@44716.0000000000",
        token_id: "4"

    },


]