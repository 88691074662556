import styled from "styled-components";

export const ProjectContainer = styled.div`
width:100% ;
height:auto;
background-color:rgb(10, 25, 41) ;

`
export const ProjectContainer2 = styled.div` 
width:100% ;
height:auto;
background-color:rgb(10, 25, 41) ;
display:flex ;
justify-content:center ;
`
export const ProjectInnerContainer = styled.div` 
width:85% ;
height: auto;
border-bottom:2px solid #424D60 ;
display:flex ;
justify-content:center ;
flex-direction:column ;

`
export const ProjectHeaderContain = styled.div` 
width:100% ;
height: auto;
margin-top:130px ;
display:flex ;

`
export const ProjectHeadImage = styled.img` 
height:156.7px ;
width:156.7px;
margin-bottom:20px ;
border-radius:5px;
@media only screen and (max-width: ${props => props.theme.bpxs}) {
 height:100px ;
 width:100px ;

}
`
export const HeaderBlock = styled.div` 
padding-left:55px ;
display:block ;
padding-top:20px ;
width:70% ;
@media only screen and (max-width: ${props => props.theme.bpxs}) {
  padding-left:5px ;
  width:50% ;
}
`
export const ProjectTitle = styled.div` 
margin-bottom:20px ;
white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
font-family:  Sofia Pro;
   display:flex;
   width:30% ;
  padding-bottom: 20px;
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
  font-family: sofia-pro, Sofia Pro;
  color: #FFFFFF;
`
export const ProjectTitle1 = styled.div` 
margin-bottom:10px ;
white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
font-family:  Sofia Pro;
margin-right:10px ;
  font-size: 1.6rem;
  font-family: sofia-pro, Sofia Pro;
  color: #FFFFFF;
  
`
export const ApprovedImage = styled.img` 
margin-left:5px ;
height:18px ;
width:18px ;
position:relative;
top:15px;
`
export const ButtonflexRow = styled.div`
display:flex ;
margin-bottom:20px ;
@media only screen and (max-width: ${props => props.theme.bpxs}) {
display:none ;
}
`
export const TwitterButton = styled.button` 
width:11.5%;
height:46px ;
border-top-left-radius:12px ;
border-bottom-left-radius:12px ;
background-color: #009BEF;
border:none ;
cursor: pointer;

display:flex ;
justify-content:space-around ;
align-items:center ;
@media only screen and (max-width: ${props => props.theme.bplg}) {
width:20.5% ;
}
@media only screen and (max-width: ${props => props.theme.bpsm}) {
width:30%;
}

`

export const TwitterText1 = styled.div`
 color: rgb(255,220,200);
 font-size:15px ;
 font-family:sans-serif ;
 display:flex ;
 `

export const TwitterRate = styled.button` 
width:7% ;
height:46px ;
border-top-right-radius:12px ;
border-bottom-right-radius:12px ;
border:none ;
margin-right:3% ;
background-color:rgb(38,49,67) ;
@media only screen and (max-width: ${props => props.theme.bplg}) {
width:10% ;
}
@media only screen and (max-width: ${props => props.theme.bpsm}) {
width:20% ;
}

`
export const TwitterButton2 = styled.div` 
width:16.5%;
height:46px ;
border-radius:12px ;
background-color: #009BEF;
border:none ;
display:flex ;
padding-left:10px ;
//justify-content:space-evenly ;
align-items:center ;
margin-right:3% ;
cursor: pointer;

@media only screen and (max-width: ${props => props.theme.bplg}) {
width:20.5% ;
}
@media only screen and (max-width: ${props => props.theme.bpsm}) {
width:30%;
}
`
export const TwitterButton3 = styled.div` 
width:19.5%;
height:65px ;
padding-top:5px;
padding-left:5px ;
padding-right:10px ;
bottom:5px ;

border-radius:12px ;
background-color:  rgb(10, 25, 41);
border-color: #5092b5;
border-style:dashed ;
/* display:flex ;
flex-direction:column ;
align-items:center ; */
margin-right:3% ;
cursor: pointer;
:hover{
  background-color:rgb(0, 30, 60) ;
  border-color:#009BEF ;
}

@media only screen and (max-width: ${props => props.theme.bplg}) {
width:20.5% ;
}
@media only screen and (max-width: ${props => props.theme.bpsm}) {
width:30%;
}
`
export const DiscardButton = styled.button` 
width:11.5%;
height:46px ;
border-top-left-radius:12px ;
border-bottom-left-radius:12px ;
background-color: rgb(88,102,242);
border:none ;
display:flex ;
justify-content:space-around ;
align-items:center ;
cursor: pointer;

@media only screen and (max-width: ${props => props.theme.bplg}) {
width:20.5% ;
}
@media only screen and (max-width: ${props => props.theme.bpsm}) {
width:30% ;
}
/* @media only screen and (max-width: ${props => props.theme.bpsm}) {
display:none ;
} */
`
export const DiscardButton2 = styled.div` 
width:16.5%;
height:46px ;
border-radius:12px ;
background-color: rgb(88,102,242);
margin-right:3% ;
cursor: pointer;
padding-left:10px ;
border:none ;
display:flex ;
//justify-content:space-evenly ;
align-items:center ;
@media only screen and (max-width: ${props => props.theme.bplg}) {
width:20.5% ;
}
@media only screen and (max-width: ${props => props.theme.bpsm}) {
width:30%;
}
`
export const DiscardButton3 = styled.div` 
width:19.5%;
height:65px ;
border-radius:12px ;
padding-left:5px ;
padding-right:10px ;
border-color :rgb(96 103 177);
border-style:dashed ;
padding-top:5px ;
background-color: rgb(10, 25, 41);
margin-right:3% ;
cursor: pointer;
:hover{
  background-color:rgb(0, 30, 60) ;
  border-color:rgb(88,102,242) ;
}

/* display:flex ;
flex-direction:column ;
align-items:center ; */
@media only screen and (max-width: ${props => props.theme.bplg}) {
width:20.5% ;
}
@media only screen and (max-width: ${props => props.theme.bpsm}) {
width:30%;
}
`
export const OpenseaButton = styled.button` 
width:16.5%;
height:46px ;
border-top-left-radius:12px ;
border-bottom-left-radius:12px ;
border-top-right-radius:12px ;
border-bottom-right-radius:12px ;
background-color: rgb(32,129,226);
border:none ;
margin-right:3% ;
display:flex ;
//justify-content:space-around ;
align-items:center ;
cursor: pointer;

@media only screen and (max-width: ${props => props.theme.bplg}) {
width:20.5% ;
}
@media only screen and (max-width: ${props => props.theme.bpsm}) {
display:none ;
}

`
export const OpenseaButton2 = styled.button` 
width:19.5%;
height:65px ;
border-radius:12px ;
background-color: rgb(10, 25, 41);
margin-right:3% ;
padding-right:10px ;
border-color:rgb(63 99 134)  ;
border-style:dashed ;
padding-left:5px ;
//display:flex ;
/* flex-direction:column ; */
align-items:center ;
:hover{
  background-color:rgb(0, 30, 60) ;
  border-color:rgb(32,129,226) ;
}
cursor: pointer;

@media only screen and (max-width: ${props => props.theme.bplg}) {
width:20.5% ;
}
@media only screen and (max-width: ${props => props.theme.bpsm}) {
display:none ;
}

`
export const DisplayingFlex = styled.div` 
display:flex ;
`

export const ExploreButton = styled.button` 
width:16.5%;
height:46px ;
border-top-left-radius:12px ;
border-bottom-left-radius:12px ;
border-top-right-radius:12px ;
border-bottom-right-radius:12px ;
background-color: rgb(83,184,117);
border:none ;
display:flex ;
padding-left:10px ;
//justify-content:space-around ;
align-items:center ;
cursor: pointer;
@media only screen and (max-width: ${props => props.theme.bplg}) {
width:20.5% ;
}
@media only screen and (max-width: ${props => props.theme.bpsm}) {
display:none ;
}
`
export const ExploreButton2 = styled.button` 
width:20.5%;
height:65px ;
border-top-left-radius:12px ;
border-bottom-left-radius:12px ;
border-top-right-radius:12px ;
border-bottom-right-radius:12px ;
background-color:  rgb(10, 25, 41);
border-color:rgb(66 107 80) ;
border-style:dashed ;
padding-right:10px ;
padding-left:5px ;
:hover{
  background-color:rgb(0, 30, 60) ;
  border-color:rgb(83,184,117) ;
}
/* display:flex ;
flex-direction:column ;
align-items:center ; */
cursor: pointer;
@media only screen and (max-width: ${props => props.theme.bplg}) {
width:20.5% ;
}
@media only screen and (max-width: ${props => props.theme.bpsm}) {
display:none ;
}
`
export const TwitterText = styled.div`
 color:#5092b5;
 font-size:16px ;
 font-family:Sofia Pro ;
 display:flex ;
 margin-left:3px ;
 :hover{
   color:#009BEF ;
 }
 `
export const TwitterText3 = styled.div`
 color: rgb(96 103 177);
 font-size:16px ;
 font-family:Sofia Pro ;
 display:flex ;
 margin-left:3px ;
 :hover{
   color:rgb(88,102,242) ;
 }
 `
export const TwitterText4 = styled.div`
 color: rgb(63 99 134);
 font-size:16px ;
 font-family:Sofia Pro ;
 display:flex ;
 margin-left:3px ;
 :hover{
   color:rgb(32,129,226) ;
 }
 `
export const TwitterText5 = styled.div`
 color: rgb(66 107 80);
 font-size:16px ;
 font-family:Sofia Pro ;
 display:flex ;
 margin-left:3px ;
 :hover{
   color:rgb(83,184,117) ;
 }
 `
export const TwitterText2 = styled.div`
 color: #FFFFFF;
 font-size:16px ;
 font-family:Sofia Pro ;
 display:flex ;
 `
export const RateColor = styled.div` 
 color: #009BEF;
 `
export const RateColor2 = styled.div` 
color: #5865F2;
`
export const ProjectBodyContainer = styled.div`
width:100% ;
height:auto;
display:flex ;
justify-content:center ;
`
export const ProjectBodyContainer2 = styled.div` 
width:85% ;
height:auto;
border-bottom:2px solid #424D60 ;
display:flex ;
`
export const ProjectBox = styled.div`
width:auto ;
height:100.8px ;
background-color: #263143;
border-radius: 12px;
padding-bottom: 10px;
padding-top: 10px;

margin-top:25px ;
margin-bottom:25px ;
display:flex ;
align-items:center ;
box-sizing:border-box ;
justify-content:center ;
`
export const BoxHeader = styled.div`
display:flex ;
flex-direction:column ;
align-items:center ;
justify-content:center ;
`
export const EthText = styled.div` 
color: #FFFFFF;
font-size:1.2rem ;
margin-top:5px ;
font-family:  Sofia Pro;

`
export const PriceText = styled.div`
color: #94A1B7;
margin-top:5px ;
font-size:14px ;
font-family:Sofia Pro ;
`
export const BoxDisplay = styled.div`
width: 100%;
 display: grid;
 grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
 box-sizing: border-box;
 column-gap: 25px;
 @media only screen and (max-width: ${props => props.theme.bplg}) {
  grid-template-columns: 1fr 1fr 1fr 1fr;

}
@media only screen and (max-width: ${props => props.theme.bpsm}) {
  grid-template-columns: 1fr 1fr 1fr ;

}
@media only screen and (max-width: ${props => props.theme.bpxs}) {
  grid-template-columns: 1fr 1fr  ;

}
@media only screen and (max-width: ${props => props.theme.bpxz}) {
  grid-template-columns: 1fr  ;

}
 `
export const TextContainer = styled.div` 
 width:100% ;
 height:auto ;
 display:flex ;
 justify-content:center ;
 `
export const TextInnerContainer = styled.div` 
 width:85% ;
 height:auto ;
border-bottom:2px solid #424D60 ;

 `
export const ContainerText1 = styled.div` 
padding-top:15px ;
padding-bottom:1rem ;
color: #94A1B7;
font-size:16px ;
font-family:Sofia Pro ;
line-height:25px ;
`
export const ContainerText = styled.div` 


padding-bottom:14px ;
color: #94A1B7;
font-size:16px ;
font-family:Sofia Pro ;
line-height:25px ;
`

export const ButtonContainer = styled.div` 
width:100% ;
height:auto ;
display:flex ;
justify-content:center ;
`
export const ButtonInnerContainer = styled.div` 
width:85% ;
height:160px ;
display:flex ;
justify-content:space-between ;
border-bottom:2px solid #424D60 ;
padding-top:35px ;

`
export const ButtonVote = styled.button` 
  background-color:#009BEF;
  color: #FFFFFF;
  height:46px ;
  width:140px;
  border:none ;
  font-size:15px ;
  font-family:Sofia Pro ;
  padding-left: 15px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  cursor: pointer;
  outline:none ;
`
export const ButtonVoted = styled.button` 
 background-color: rgb(38,49,67);
  color: rgb(62, 158, 214);
  height:46px ;
  width:140px;
  border:none ;
  padding-left: 15px;
  font-size:14px ;
  font-family:Sofia Pro ;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  cursor: pointer;
  outline:none ;

`
export const ButtonVote1 = styled.button` 
  background-color: #5A6BFF;
  color: #FFFFFF;
  height:46px ;
  width:140px;
  border:none ;
  font-size:14px ;
  letter-spacing:1.5px ;
  font-family:Sofia Pro ;
  font-size:16px ;
  padding-left: 15px;
font-family:  Sofia Pro;

  border-radius:12px ;
  cursor: pointer;
  outline:none ;

`
export const ButtonRated = styled.button` 
  background-color: rgb(38,49,67);
  color: rgb(62, 158, 214);
  height:46px ;
  width:140px;
  border:none ;
  font-size:14px ;
  letter-spacing:1.5px ;
  font-family:Sofia Pro ;
  padding-left: 15px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  cursor: pointer;
  outline:none ;

`
export const ButtonRated1 = styled.button` 
 background-color: #009BEF;
  color: #FFFFFF;
  height:46px ;
  width:140px ;
  border:none ;
  font-size:15px ;
  font-family:Sofia Pro ;
  padding-left: 15px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  cursor: pointer;
  outline:none ;

`

export const VoteRateButton = styled.button` 
display:flex;
height:45px ;
width:220px ;
align-items:center ;
justify-content:center ;
border-radius:15px ;
border:none ;
background-color:rgb(0, 30, 60) ;
outline:none ;
//width:49% ;
`
export const VoteRateButton2 = styled.div` 
display:flex;

//width:49% ;
`
export const VoteRateButton1 = styled.div` 
display:flex;
justify-content:flex-end ;
margin-bottom:10px ;

//width:49% ;
`
export const BlockMerge = styled.div` 
display:block ;
`
export const VoteText = styled.div` 
color:white;
font-size:1.2rem ;
font-family:Sofia Pro ;
margin-top:40px ;
`
export const VoteText2 = styled.nav` 
color:rgb(51, 153, 255);
font-size:1.2rem ;
font-weight:bold ;
font-family:Sofia Pro ;
`
export const VoteSearch = styled.input` 
width:20%;
height:46px ;
background-color: #263143;
border-radius: 12px;
border:none;
padding-left:10px ;
`
export const CardContainer1 = styled.div` 
width:100% ;
height:auto ;
display:flex ;
justify-content:center ;
`
export const CardInnerContainer1 = styled.div` 
width:85% ;
height:auto ;
`
export const DisplayingNone = styled.div` 
width:100% ;
height:30px ;
display:flex ;
justify-content:center ;
color:white ;
font-size:16px ;
padding-top:30px ;
`
export const ProjectCard = styled.div` 
width:auto;
height:479.5px ;
border-radius:15px ;
background-color: rgb(0, 30, 60);
margin-top:15px ;
margin-bottom:50px ;
display: inline-table;
padding-bottom:10px ;
`
export const ProjectCardImage = styled.img` 
width:100% ;
height:295.9px ;
border-top-left-radius:15px ;
border-top-right-radius:15px ;
cursor: pointer;
`
export const InnerCardFullContain = styled.div` 
width:100% ;
height:55px ;
display:flex ;
justify-content:center ;
`
export const InnerCardContainer = styled.div` 
width:87%;
height:55px ;
border-bottom:2px solid #424D60 ;

`
export const CardContainerText = styled.div` 
font-size:1.1rem ;
color:white ;
font-weight:bold ;
font-family:Sofia Pro ;
padding-top:15px ;
`
export const CardFooterContiner = styled.div` 
width:100% ;
height:80px ;
display:flex ;
//justify-content:space-around ;
align-items:center ;
flex-direction:column ;
box-sizing:border-box ;
padding-left:5px ;
padding-right:5px ;
padding-top:15px ;
padding-bottom:10px ;

`
export const BoxTwitterImg = styled.img` 
vertical-align:middle ;
color:red ;
margin-left:25px ;
cursor: pointer;
:hover{
  background-color:black ;
}
`
export const CardFooterContainer = styled.div` 
width:100% ;
height:110px ;
display:flex ;
//justify-content:space-around ;
align-items:center ;
flex-direction:column ;
box-sizing:border-box ;
padding-left:5px ;
padding-right:5px ;
padding-top:15px ;
padding-bottom:15px ;

`
export const FooterText = styled.div` 
color: #94A1B7;
font-size:16px;
font-family:Sofia Pro;


`
export const RightContent = styled.div`
display:block;
`
export const RightCombine = styled.div` 
display:flex ;
justify-content:space-between ;
width:100% ;
box-sizing:border-box ;
padding-left:15px ;
padding-right:15px ;


`
export const CardContainerText1 = styled.div` 
font-size:14px ;
color:white ;
letter-spacing:0.4px ;
font-family:Sofia Pro ;
`
export const CardContainerText2 = styled.div` 
font-size:0.9rem ;
color:white ;
font-family:Sofia Pro ;
margin-left:30px ;
margin-top:5px ;
`
export const CardProjectDisplay = styled.div` 
width: 100%;
 display: grid;
 grid-template-columns: 1fr 1fr 1fr 1fr ;
 box-sizing: border-box;
 column-gap: 15px;
 @media only screen and (max-width: ${props => props.theme.bplg}) {
  grid-template-columns: 1fr 1fr 1fr ;

}
@media only screen and (max-width: ${props => props.theme.bpsm}) {
  grid-template-columns: 1fr 1fr 1fr ;

}
@media only screen and (max-width: ${props => props.theme.bpxs}) {
  grid-template-columns: 1fr 1fr  ;

}
@media only screen and (max-width: ${props => props.theme.bpxz}) {
  grid-template-columns: 1fr  ;

}
`
export const VoteText1 = styled.div` 
color:white;
font-size:1.2rem ;
font-family:Sofia Pro ;
margin-top:15px ;
margin-bottom:15px ;
`
export const ProjectFooterContainer = styled.div`
width:100% ;
height:auto;
display:flex ;
align-items:center ;
justify-content:center ;
`
export const ProjectFooterInner = styled.div`
width:85% ;
height:70px ;
//border-bottom:2px solid #424D60 ;
display:flex ;
justify-content:flex-end!important ;
align-items:center ;
`

