import styled from "styled-components";


export const SalesContainer = styled.div` 
width:100% ;
 height:auto;
 display:flex ;
 justify-content:center ;
 background-color:#1E2634;
`
export const SalesInnerConainer = styled.div` 
width:87% ;
 height:auto ;
border-bottom:3px solid #424D60 ;
`
export const SalesCardDisplay = styled.div` 
 width: 100%;
 display: grid;
 grid-template-columns: 1fr 1fr 1fr ;
 box-sizing: border-box;
 column-gap: 25px;
 margin-bottom:30px ;
`
export const SalesCard = styled.div` 
width:100% ;
height:474px ;
background-color:rgb(38,49,67) ;
border-radius:15px ;
margin-bottom:30px ;
`
export const SalesCardImage = styled.img` 
width:100% ;
height:386px ;
border-top-left-radius:15px ;
border-top-right-radius:15px ;
margin-bottom:20px ;
`
export const SalesCardMiddleContainer = styled.div`
padding-left:15px ;
padding-right:15px ;
display:flex ;
width:100% ;
height:15px ;
box-sizing:border-box ;
align-items:center ;
//vertical-align:middle ;
justify-content:space-between ;
`
export const SalesCardText = styled.div` 
  color: #FFFFFF;
`
export const SalesMiddleCombine = styled.div` 
display:flex;
height:15px ;
vertical-align:middle ;
align-items:center ;
`
export const SalesCardCost = styled.div` 
color:#FFFFFF ;
margin-left:10px ;
vertical-align:middle ;

`
export const SalesMiddleImage = styled.img` 
width:8.9px ;
height:14.2px ;
`
export const SalesCardFooter = styled.div` 
padding-left:15px ;
padding-right:15px ;
display:flex ;
width:100% ;
height:35px ;
box-sizing:border-box ;
align-items:center ;
//vertical-align:middle ;
justify-content:space-between ;
`
export const CardFooterText = styled.div`
  color: #94A1B7;
  text-decoration: underline;
`
export const SalesBoxMiddle = styled.div` 
height:66px ;
 width:100% ;
 background-color: #263143;
 border-radius: 12px;
   font-weight: bold;
   margin-top: 10px;
   border: 1px solid rgba(255,255,255,0.05);
   display:flex ;
   align-items:center ;
   padding-left:10px ;
   margin-bottom:20px ;
   box-sizing:border-box ;
`
export const SalesBottomText = styled.div` 
color:white ;
margin-bottom:45px ;
`
export const SalesTable = styled.div` 
width:100% ;
height:66px ;
background-color:rgb(25,31,40) ;
align-items:center ;
padding-left:15px ;
display:flex ;
border-bottom:1px solid #424D60 ;
border-top-left-radius:15px ;
border-top-right-radius:15px ;
box-sizing:border-box ;
`
export const NftTextContainer = styled.div` 
width:20%

`