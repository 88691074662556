import styled from "styled-components";

export const CollectionContainer = styled.div`
width:100% ;
 height:auto;
 display:flex ;
 justify-content:center ;
 background-color:#1E2634;
`
export const CollectionInnerContainer = styled.div` 
width:87% ;
 height:auto ;
border-bottom:3px solid #424D60 ;

`
export const CollectionListText = styled.div` 
  color: #FFFFFF;
  font-size:16px ;
  font-family:'Sofia Pro ;
`
export const CollectionTextContainer = styled.div` 
width:100% ;
height:80px ;
display:flex ;
justify-content:center ;
align-items:center ;
`
export const CollectionBoxMiddle = styled.div` 
height:66px ;
 width:100% ;
 background-color: #263143;
 border-radius: 12px;
   font-weight: bold;
   margin-top: 10px;
   border: 1px solid rgba(255,255,255,0.05);
   display:flex ;
   align-items:center ;
   padding-left:10px ;
   /* margin-bottom:50px ; */
   box-sizing:border-box ;
`
export const CollectionCardContainer = styled.div` 
width:100% ;
 height:178.7px ;
 display:flex;
 justify-content:space-between ;
 align-items:center ;
 margin-bottom:100px ;
`