import styled from "styled-components";

export const BodyContainer = styled.div` 
width:100% ;
min-height:92.2vh;
background-color: rgb(10, 25, 41) ;
display:flex ;
justify-content:center ;

//margin-top:112.3 ;
`
export const BodyContainer1 = styled.div` 
width:100% ;
min-height:92.2vh;
background-color: rgb(24 40 56);
display:flex ;
justify-content:center ;

//margin-top:112.3 ;
`
export const BodyContainer2 = styled.div` 
width:100% ;
height:auto;
background-color: rgb(24 40 56);
display:flex ;
justify-content:center ;

//margin-top:112.3 ;
`
export const MiddleContainer = styled.div` 
width:87% ;
height:auto;
background-color: rgb(10, 25, 41) ;
@media only screen and (max-width: ${props => props.theme.bpxxl}) {
  max-width:87% ;
  
}
@media only screen and (max-width: ${props => props.theme.bplg}) {
  max-width:90% ;
  
}
@media only screen and (max-width: ${props => props.theme.bpmd}) {
  max-width:95% ;
  
}

`
export const MiddleContainer2 = styled.div` 
width:87% ;
height:auto;
background-color: rgb(24 40 56) ;
@media only screen and (max-width: ${props => props.theme.bpxxl}) {
  max-width:87% ;
  
}
@media only screen and (max-width: ${props => props.theme.bplg}) {
  max-width:90% ;
  
}
@media only screen and (max-width: ${props => props.theme.bpmd}) {
  max-width:95% ;
  
}

`

export const BackButtonContainer = styled.div`
display:flex;
flex:1;
justify-content:flex-end;
align-items:flex-start;
`


export const BackButton = styled.button` 
width:35px ;
height:35px ;
border-radius:35px ;
background-color:#263143 ;
border:none ;
outline: none;
align-items: center;
  display: flex;
  justify-content: center;
cursor: pointer;
:hover
{
background-color:#2f3949 ;

}

`
export const BodyHeader1 = styled.div` 
font-weight: bold;
  font-size: 3rem;
margin-top:95px   ;
font-family:"Sofia Pro";
box-sizing: border-box;
color: #FFFFFF;
`
export const BodyHeader = styled.div` 
font-weight: bold;
  font-size: 3rem;
margin-top:115px   ;
box-sizing: border-box;
color: #FFFFFF;
font-family:  Sofia Pro;
`
export const BodyText = styled.div` 
  font-family: Sofia Pro;
  margin-top: 20px;
  font-size: 1.2rem;
font-family:Sofia Pro;

  color: #94A1B7;
  box-sizing:border-box;
  margin-bottom: 1rem;
`
export const Box = styled.div` 
height:66px ;
width:100% ;
background-color: #263143;
border-radius: 12px;
  font-weight: bold;
  margin-top: 30px;
  border: 1px solid rgba(255,255,255,0.05);
  display:flex ;
  align-items:center ;
  padding-left:10px ;
  padding-right:10px ;
box-sizing:border-box ;
  margin-bottom:50px ;
  
`
export const BodyButton = styled.button` 
color: #94A1B7;
background-color: #263143;
border:none;
margin-right: 30px;
cursor: pointer;
display:flex ;
@media only screen and (max-width: ${props => props.theme.bpxz}) {
    display:flex ;
    flex-direction:column ;
  }

`
export const BodyButtonText = styled.button` 
  color: #FFFFFF ;
  font-weight: bold;
  font-size:15px ;
font-family:  Sofia Pro;

  margin-right: 30px;
  background-color: #263143;
border:none;
`
export const BodyButtonText2 = styled.button` 
color: #94A1B7;
font-weight: bold;
  font-size:15px ;
font-family:  Sofia Pro;

  margin-right: 30px;
  background-color: #263143;
border:none;
`
export const Card = styled.div` 
height:676px;
width:100% ;  
box-sizing: border-box;
background-color:rgb(38,49,67) ;
border-radius:15px ;
margin-top:25px ;
`
export const CardImage = styled.img` 
width:100% ;
height:300px ;
border-top-left-radius:15px ;
border-top-right-radius:15px ;

`
export const CardtextHead = styled.div` 
color: #FFFFFF;
font-size: 1.2rem;
font-family:Sofia Pro;

  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 5px;
  width:100% ;
  padding-top:25px ;
`
export const CardMiddle = styled.div` 
padding-top:15px ;
display:flex ;
align-items:center ;
//padding-left:13px ;
`
export const CalenderImage = styled.img` 
width:14px ;
height:14px ;
`
export const CardMiddleText = styled.div` 
  margin-left: 10px;
  color: #94A1B7;
  font-size:1rem ;
font-family: Sofia Pro;

`
export const MiddleCardContainer = styled.div` 
height:110px ;
width:100% ;
display:flex ;
box-sizing:border-box ;

justify-content:center ;
`
export const MiddleInnerContain = styled.div` 
height:110px;
width:90% ;
box-sizing:border-box ;

//padding-top:25px ;
/* align-items:center ;
display:inline-block ; */
border-bottom:3px solid #424D60 ;
`
export const MiddleCardContainer2 = styled.div` 
height:110px ;
width:100% ;
display:flex ;
box-sizing:border-box ;

justify-content:center ;
`
export const MiddleInnerContain2 = styled.div` 
height:110px;
width:90% ;
//padding-top:25px ;
align-items:center ;
display:flex ;
box-sizing:border-box ;
border-bottom:3px solid #424D60 ;
`
export const CombineFlex = styled.div`
display:block;
width:100% ;
`
export const CombineContent = styled.div` 
display:flex ;
justify-content:space-between ;
margin-bottom:0.2rem ;
`
export const Text = styled.div` 
  color: #94A1B7;
  font-size:1rem ;
font-family:Sofia Pro;

`
export const Rating = styled.div` 
color: #FFFFFF;
font-size:1rem ;
font-family:  Sofia Pro;

`
export const ButtonContainer = styled.div` 
display:flex;
width:45% ;
;
`
export const TwitterButton = styled.button` 
  background-color: ${props => props.color};
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
 

  width:50% ;
  height:46px ;
  border:none ;
`
export const ViewsButton = styled.button` 
  background-color: ${props => props.background};
  color: ${props => props.color};
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
 

  width:50% ;
  height:46px ;
  border:none ;
`
export const ButtonMergeContainer = styled.div` 
width:100% ;
height:125px ;
`
export const ButtonFlex = styled.div` 
display:flex ;
padding-left: 15px;
  margin-top: 15px;
  padding-right: 15px; 
  justify-content :space-between ;
`
export const WebsiteButton = styled.button` 
width:45% ;
  height:46px ;
  border:none ;
  border-radius:12px ;
  box-sizing:border-box ;
  padding-left:15px ;
  padding-right:15px ;
  background-color: #94A1B7;
  
  display:flex ;
  align-items:center ;
  justify-content:space-evenly ;
  @media only screen and (max-width: ${props => props.theme.bpmd}) {
flex-direction  :column ;
}
`
export const WebsiteText = styled.div` 
 color:#FFFFFF;
 font-size:16px ;
font-family:  Sofia Pro;

 `
export const CardDisplay = styled.div`
 width: 100%;
 display: grid;
 grid-template-columns: 1fr 1fr 1fr 1fr ;
 box-sizing: border-box;
 column-gap: 35px;
 @media only screen and (max-width: ${props => props.theme.bpxlg}) {
  column-gap: 15px;
        
  }
  @media only screen and (max-width: ${props => props.theme.bpmd}) {
    grid-template-columns: 1fr 1fr 1fr ;
  }
  @media only screen and (max-width: ${props => props.theme.bpsm}) {
    grid-template-columns: 1fr 1fr  ;
  }
  @media only screen and (max-width: ${props => props.theme.bpxz}) {
    grid-template-columns: 1fr   ;
  }
 //padding:10px ;
 `
export const BodyFooterContainer = styled.div` 
 width:100% ;
 height:120px;
// border-bottom:2px solid #3C4555 ;
display:flex ;
align-items:center ;
justify-content:space-between ;
 `
export const BodyFooterContainer1 = styled.div` 
 width:100% ;
 height:120px;
border-bottom:2px solid #3C4555 ;
display:flex ;
align-items:center ;
justify-content:center ;
 `
export const BodyFooterText = styled.div` 
   color:${props => props.theme.mi_primary};
   font-size:15px ;
font-family:  Sofia Pro;

 `
export const ViewButton = styled.button` 
 width:13% ;
 height:40px ;
 border-radius: 12px;
 background-color:${props => props.theme.primary};
 border: 1px solid ${props => props.theme.mi_primary} ;
 display:flex ;
 align-items:center ;
 &:hover{
  background-color:${props => props.theme.secondary};

 }
 justify-content:space-evenly ;
 @media only screen and (max-width: ${props => props.theme.bpxz}) {
   width:40% ;
  }
 `
export const PersonIcon = styled.img` 
 /* margin-left:1% ; */
 margin-right:1% ;
 width:35px ;
 height:35px ;
 `
export const ViewButtonText = styled.div` 
 color: ${props => props.theme.mi_primary};
 font-size:14px ;
 font-weight:600 ;
font-family:  Sofia Pro;

 cursor: pointer;
font-family:Sofia Pro;

 `

export const NameText1 = styled.div` 
 font-weight: bold;
color: #94A1B7;
font-size:14px ;
min-width:23% ;

font-family:Sofia Pro;

display:flex ;
justify-content:start ;
@media only screen and (max-width: ${props => props.theme.bplg}) {
        min-width:22% ;
  }
  @media only screen and (max-width: ${props => props.theme.bpmd}) {
       min-width:40% ;
  }
  @media only screen and (max-width: ${props => props.theme.bpsm}) {
        
  }
  @media only screen and (max-width: ${props => props.theme.bpxs}) {
        min-width:60% ;
  }
  @media only screen and (max-width: ${props => props.theme.bpxz}) {
        min-width:70% ;
  }
  
 `
export const NameText6 = styled.div` 
 font-weight: bold;
color: #94A1B7;
font-size:14px ;
min-width:21.5% ;

font-family:Sofia Pro;

display:flex ;
justify-content:start ;
@media only screen and (max-width: ${props => props.theme.bplg}) {
        min-width:22% ;
  }
  @media only screen and (max-width: ${props => props.theme.bpmd}) {
       min-width:40% ;
  }
  @media only screen and (max-width: ${props => props.theme.bpsm}) {
        
  }
  @media only screen and (max-width: ${props => props.theme.bpxs}) {
        min-width:60% ;
  }
  @media only screen and (max-width: ${props => props.theme.bpxz}) {
        min-width:70% ;
  }
  
 `
export const NameText5 = styled.div` 
 font-weight: bold;
color: #94A1B7;
font-size:14px ;
min-width:27% ;
font-family:Sofia Pro;

display:flex ;
justify-content:start ;
@media only screen and (max-width: ${props => props.theme.bplg}) {
        min-width:22% ;
  }
  @media only screen and (max-width: ${props => props.theme.bpmd}) {
       min-width:40% ;
  }
  @media only screen and (max-width: ${props => props.theme.bpsm}) {
        
  }
  @media only screen and (max-width: ${props => props.theme.bpxs}) {
        min-width:60% ;
  }
  @media only screen and (max-width: ${props => props.theme.bpxz}) {
        min-width:70% ;
  }
  
 `
export const Nametext = styled.div` 
 font-weight: bold;
color: #94A1B7;
font-size:14px ;
min-width:17.7% ;
display:flex ;
font-family:  Sofia Pro;


justify-content:start ;
@media only screen and (max-width: ${props => props.theme.bpmd}) {
    min-width:25% ;
        
  }
  @media only screen and (max-width: ${props => props.theme.bpxs}) {
        min-width:40% ;
  }
  
`
export const Nametext1 = styled.div` 
 font-weight: bold;
color: #94A1B7;
font-size:14px ;
min-width:20.3% ;
display:flex ;
font-family:  sans-serif;


justify-content:start ;
@media only screen and (max-width: ${props => props.theme.bpmd}) {
    min-width:25% ;
        
  }
  @media only screen and (max-width: ${props => props.theme.bpxs}) {
        min-width:40% ;
  }
  
`
export const Nametext2 = styled.div` 
 font-weight: bold;
color: #94A1B7;
font-size:14px ;
min-width:12% ;
display:flex ;
font-family:  sans-serif;


justify-content:start ;
`
export const Nametext5 = styled.div` 
 font-weight: bold;
color: #94A1B7;
font-size:14px ;
min-width:11% ;
display:flex ;
font-family:  sans-serif;


justify-content:start ;
`
export const Nametext6 = styled.div` 
 font-weight: bold;
color: #94A1B7;
font-size:14px ;
min-width:13% ;
display:flex ;
font-family:  sans-serif;


justify-content:start ;
`
export const HashText2 = styled.div` 
min-width:9% ;
color: #94A1B7;
font-family:  Sofia Pro;

`
export const NameText4 = styled.div` 
 font-weight: bold;
color: #94A1B7;
font-size:14px ;
font-family:  Sofia Pro;

display:flex;
justify-content:center ;
min-width:26% ;
@media only screen and (max-width: ${props => props.theme.bplg}) {
  min-width:30% ;
        
  }
  @media only screen and (max-width: ${props => props.theme.bpmd}) {
  
        min-width:35% ;
  }
  @media only screen and (max-width: ${props => props.theme.bpsm}) {
    
        min-width:39% ;
  }
  @media only screen and (max-width: ${props => props.theme.bpxs}) {
    min-width:47% ;
        
  }
  @media only screen and (max-width: ${props => props.theme.bpxz}) {
    min-width:65% ;
        
  }
  @media only screen and (max-width: ${props => props.theme.bpxy}) {
    min-width:70% ;
        
  }
 `
export const NameText2 = styled.div` 
 font-weight: bold;
color: #94A1B7;
font-size:14px ;
font-family:  Sofia Pro;

display:flex;
justify-content:center ;
min-width:25.5% ;
@media only screen and (max-width: ${props => props.theme.bplg}) {
  min-width:30% ;
        
  }
  @media only screen and (max-width: ${props => props.theme.bpmd}) {
  
        min-width:40% ;
  }
  @media only screen and (max-width: ${props => props.theme.bpsm}) {
    
        min-width:42% ;
  }
  @media only screen and (max-width: ${props => props.theme.bpxs}) {
    min-width:50% ;
        
  }
  @media only screen and (max-width: ${props => props.theme.bpxz}) {
    min-width:70% ;
        
  }
 `
export const NameText3 = styled.div` 
 font-weight: bold;
color: #94A1B7;
font-size:14px ;
font-family:  Sofia Pro;

min-width:17.7% ;
display:flex ;
justify-content:start ;

  @media only screen and (max-width: ${props => props.theme.bplg}) {
min-width:17% ;
        
}
@media only screen and (max-width: ${props => props.theme.bpmd}) {
   min-width:30% ;
        
  }
  @media only screen and (max-width: ${props => props.theme.bpxs}) {
        min-width:40% ;
  }
`
export const Nametext3 = styled.div` 
 font-weight: bold;
color: #94A1B7;
font-size:14px ;
font-family:  sans-serif;

min-width:17.5% ;
display:flex ;
justify-content:start ;

  @media only screen and (max-width: ${props => props.theme.bplg}) {
min-width:17% ;
        
}
@media only screen and (max-width: ${props => props.theme.bpmd}) {
   min-width:30% ;
        
  }
  @media only screen and (max-width: ${props => props.theme.bpxs}) {
        min-width:40% ;
  }
`

export const SerialNo1 = styled.div` 
  color: white;
min-width:4% ;
font-family:  Sofia Pro;

:hover{
  color:#394364 ;
}
@media only screen and (max-width: ${props => props.theme.bpxz}) {
  min-width:10% ;
        
}
`
export const TextHead1 = styled.div` 
 font-weight: bold;
 color: #94A1B7;
 font-size:14.5px ;
font-family:  Sofia Pro;

min-width:27.5% ;
display:flex ;
justify-content:center ;
 @media only screen and (max-width: ${props => props.theme.bplg}) {
  
        
  }
  @media only screen and (max-width: ${props => props.theme.bpmd}) {
min-width:38% ;
        
  }
  @media only screen and (max-width: ${props => props.theme.bpsm}) {
  
        
  }
  @media only screen and (max-width: ${props => props.theme.bpxs}) {
    min-width:50% ;
  
        
}
  @media only screen and (max-width: ${props => props.theme.bpxz}) {
  
        
  }
  
 `
export const TextHead2 = styled.div` 
 font-weight: bold;
 color: #94A1B7;
 font-size:14.5px ;
font-family:  Sofia Pro;

 width:15% ;
 display:flex ;
 justify-content:center ;
 `
export const TextHeadFloor1 = styled.div` 
 font-weight: bold;
 color: #94A1B7;
 font-size:14.5px ;
font-family:  Sofia Pro;

 min-width:22% ;
 display:flex ;
 justify-content:center ;
 @media only screen and (max-width: ${props => props.theme.bpmd}) {
 min-width:40% ;
        
}
@media only screen and (max-width: ${props => props.theme.bpxs}) {
 min-width:50% ;
        
}
 `
export const TextHeadFloor2 = styled.div` 
 font-weight: bold;
 color: #94A1B7;
 font-size:14.5px ;
 min-width:15% ;
font-family:  Sofia Pro;

 display:flex ;
 justify-content:start ;
 @media only screen and (max-width: ${props => props.theme.bpmd}) {
 min-width:30% ;
        
}
@media only screen and (max-width: ${props => props.theme.bpxs}) {
        min-width:60% ;
  }
 `

export const FanText4 = styled.div` 
 font-weight: bold;
 color: #94A1B7;
 font-size:14.5px ;
font-family:  Sofia Pro;

display:flex ;
justify-content:center ;
width:16% ;
@media only screen and (max-width: ${props => props.theme.bplg}) {
  
        
}
@media only screen and (max-width: ${props => props.theme.bpmd}) {

   min-width   :40% ;
}
@media only screen and (max-width: ${props => props.theme.bpsm}) {
      
}
@media only screen and (max-width: ${props => props.theme.bpxs}) {

  min-width:45% ;
      
}
 `
export const FanText2 = styled.div`
font-weight: bold;
 color: #94A1B7;
 font-size:14.5px ;
display:flex ;
font-family:  Sofia Pro;

justify-content:center ;
min-width:17% ;
 @media only screen and (max-width: ${props => props.theme.bplg}) {
  
        
  }
  @media only screen and (max-width: ${props => props.theme.bpmd}) {
  
     min-width   :40% ;
  }
  @media only screen and (max-width: ${props => props.theme.bpsm}) {
        
  }
  @media only screen and (max-width: ${props => props.theme.bpxs}) {
  
    min-width:50% ;
        
  }
  @media only screen and (max-width: ${props => props.theme.bpxz}) {
    
        
  }
`
export const FanText1 = styled.div` 
 font-weight: bold;
 color: #94A1B7;
 font-size:14.5px ;
font-family:  Sofia Pro;

 margin-right:13% ;
`
export const Fantext3 = styled.div` 
 font-weight: bold;
 color: #94A1B7;
 font-size:14.5px ;
min-width:19% ;

display:flex ;
justify-content:start ;
@media only screen and (max-width: ${props => props.theme.bpmd}) {
    min-width:30% ;
        
  }
  
  
`
export const FanText3 = styled.div` 
 font-weight: bold;
 color: #94A1B7;
 font-size:14.5px ;
min-width:14% ;

display:flex ;
justify-content:start ;
@media only screen and (max-width: ${props => props.theme.bpmd}) {
    min-width:30% ;
        
  }
  
  
`
export const CardIcon1 = styled.img` 
 width:44.7px ;
 height:44.7px;
 margin-right:5% ;
 
 `
export const CardIcon2 = styled.img` 
 width:44.7px ;
 height:44.7px;
 margin-right:0.7% ;
 border-radius:8px ;
 
 `
export const CardName1 = styled.div` 
 width:28.5% ;
 height:32px ;
 display:flex ;
 justify-content:start ;
 align-items:center ;
 @media only screen and (max-width: ${props => props.theme.bplg}) {
        
  }
  @media only screen and (max-width: ${props => props.theme.bpmd}) {
min-width:40% ;
        
  }
  @media only screen and (max-width: ${props => props.theme.bpsm}) {
    
  }
  @media only screen and (max-width: ${props => props.theme.bpxs}) {
   min-width:52% ;
   
  }
  @media only screen and (max-width: ${props => props.theme.bpxz}) {
        min-width:64% ;
  }
 `
export const CardName2 = styled.div` 
 min-width:29.5% ;
 height:32px ;
 display:flex ;
 justify-content:start ;
 align-items:center ;
 @media only screen and (max-width: ${props => props.theme.bpmd}) {
min-width:40% ;
        
}
@media only screen and (max-width: ${props => props.theme.bpxs}) {
        min-width:58% ;
  }
  
 `
export const CardName3 = styled.div` 
min-width:28.5% ;
height:32px ;
display:flex ;
justify-content:start ;
align-items:center ;
@media only screen and (max-width: ${props => props.theme.bpmd}) {
min-width:40% ;
       
}

@media only screen and (max-width: ${props => props.theme.bpxs}) {
        min-width:58% ;
  }
  
 `
export const CardName4 = styled.div` 
 min-width:24.5% ;
 height:32px ;
 display:flex ;
 justify-content:start ;
 align-items:center ;
 @media only screen and (max-width: ${props => props.theme.bpmd}) {
 min-width:40% ;
        
 }
 
 @media only screen and (max-width: ${props => props.theme.bpxs}) {
         min-width:58% ;
   }
   
  `
export const CardButtonLaunched1 = styled.div` 
 border:none ;
 color: white;
   height:40px ;
   align-items:center ;
   justify-content:start ;
   display:flex ;
   min-width:21.5% ;
   @media only screen and (max-width: ${props => props.theme.bpmd}) {
min-width:38.5% ;
        
}
   @media only screen and (max-width: ${props => props.theme.bpxs}) {
   min-width:48% ;
 }
 @media only screen and (max-width: ${props => props.theme.bpxz}) {
       
 }
 `
export const CardButtonLaunched2 = styled.div` 
 border:none ;
 color: white;
   height:40px ;
   justify-content:start ;
   align-items:center ;
   display:flex ;
   min-width:17% ;
   @media only screen and (max-width: ${props => props.theme.bpmd}) {
min-width:25% ;
        
}
@media only screen and (max-width: ${props => props.theme.bpxs}) {
        min-width:40% ;
  }
   `
export const CardButtonLaunched4 = styled.div` 
   border:none ;
   color: white;
     height:40px ;
     justify-content:start ;
     align-items:center ;
     display:flex ;
     min-width:19% ;
     @media only screen and (max-width: ${props => props.theme.bpmd}) {
  min-width:25% ;
          
  }
  @media only screen and (max-width: ${props => props.theme.bpxs}) {
          min-width:40% ;
    }
     `
export const CardButtonLaunched3 = styled.div` 
   border:none ;
   color: white;
     height:40px ;
     align-items:center ;
     justify-content:start ;
     display:flex ;
     min-width:12% ;
     `
export const VolumeContainer1 = styled.div` 
 min-width:20% ;

 justify-content:start ;
 align-items:center ;
 display:flex ;
 @media only screen and (max-width: ${props => props.theme.bpmd}) {
min-width:41% ;
        
}
 @media only screen and (max-width: ${props => props.theme.bpsm}) {
        
  }
  @media only screen and (max-width: ${props => props.theme.bpxs}) {
   min-width:52% ;
 }
 @media only screen and (max-width: ${props => props.theme.bpxz}) {
       
 }
 `
export const VolumeContainer2 = styled.div` 
 min-width:17% ;
 height:42px ;
 justify-content:start ;
 align-items:center ;
 display:flex ;
 
@media only screen and (max-width: ${props => props.theme.bplg}) {
    min-width:18% ;
        
  }
  @media only screen and (max-width: ${props => props.theme.bpmd}) {
min-width:32% ;
        
}
@media only screen and (max-width: ${props => props.theme.bpxs}) {
        min-width:40% ;
  }
 `
export const VolumeContainer4 = styled.div` 
 min-width:19% ;
 height:42px ;
 justify-content:start ;
 align-items:center ;
 display:flex ;
 
@media only screen and (max-width: ${props => props.theme.bplg}) {
    min-width:18% ;
        
  }
  @media only screen and (max-width: ${props => props.theme.bpmd}) {
min-width:32% ;
        
}
@media only screen and (max-width: ${props => props.theme.bpxs}) {
        min-width:40% ;
  }
 `
export const VolumeContainer3 = styled.div` 
 min-width:15.5% ;
 height:42px ;
 justify-content:start ;
 align-items:center ;
 display:flex ;
 @media only screen and (max-width: ${props => props.theme.bpmd}) {
min-width:32% ;
        
}
@media only screen and (max-width: ${props => props.theme.bpxs}) {
        min-width:35% ;
  }

 `
export const FloorContainer1 = styled.div` 
 width:7% ;
 align-items:center ;
 display:flex ;
 
 //margin-right:6.3% ;
 `
export const FloorContainer2 = styled.div` 
 min-width:16% ;
 height:40px ;
 
 justify-content:start ;
 align-items:center ;
 display:flex ;
 
 @media only screen and (max-width: ${props => props.theme.bpmd}) {
min-width:30% ;
        
}
 //margin-right:6.3% ;
 `
export const SpinContainer = styled.div` 
 width:100% ;
 display:flex ;
 justify-content:center ;
 margin-top:25px ;
 `
export const ResponsiveContainer = styled.div` 
 display:none ;
 @media only screen and (max-width: ${props => props.theme.bpxs}) {
display:flex ;
width:100% ;
height:auto ;
border-bottom: 1px solid #29313F;
margin-top:20px ;
flex-direction:column ;
}
 `
export const TableCard = styled.div` 
 width:100% ;
 height:auto ;
 background-color:rgb(25,31,40) ;
 border-radius:15px ;
 `
export const TableFlex = styled.div` 
display:flex ;
padding:15px ;
`
export const CardText1 = styled.div` 
color: white;
font-size:20px ;
font-family:Sofia Pro;
cursor: pointer;
`
export const CardText3 = styled.div` 
color:#94A1B7 ;
font-size:16px ;
font-family:Sofia Pro;
cursor: pointer;
`
export const CardText2 = styled.div` 
color: rgb(105,175,239);
font-size:16px ;
font-family:Sofia Pro;
cursor: pointer;
`
export const CardImageName1 = styled.img` 
height:20px ;
width:20px ;
margin-top:2% ;
margin-left:1% ;
`
export const TableFlex1 = styled.div` 
display:flex ;
`
export const CollectionTextBox = styled.div` 
width:50% ;
height:80px;
background-color:rgb(10, 25, 41) ;
margin-left:2% ;
padding:15px ;

margin-right:10px ;
border-radius:10px ;
margin-bottom:10px ;
`
export const CardProjectDisplay1 = styled.div`
display :none ;
 @media only screen and (max-width: ${props => props.theme.bpxs}) {

width: 100%;
 display: grid;
 grid-template-rows: 1fr  ;
 box-sizing: border-box;
 row-gap: 35px;
 }
`


