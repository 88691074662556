import { Grid, TextField } from "@mui/material"
import React from "react"
import { useNavigate } from 'react-router-dom'
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { BackButton, BodyContainer, BodyContainer1, BodyHeader, MiddleContainer } from "../LandingPage/index.styled.js"
import { UploadComponent, RootComponent, BottomComponent, SaveButton, BodyHeader1, Profile, ProfileImage } from "./index.style.js"
import Dropzone from "react-dropzone"
import { makeStyles } from "@material-ui/styles"
import { InfoOutlined, EyeOutlined, UserOutlined, LeftOutlined } from '@ant-design/icons';
import { Formik } from 'formik';
import { useFormik } from 'formik';
import * as yup from 'yup';



import {
  Card,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Collapse,
} from "reactstrap"
import { Link } from "react-router-dom"
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Toast } from "bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import makePostRequest from "../../../api/PostApi.js";
import { ThemeConsumer } from "styled-components";

const useStyles = makeStyles(theme => ({
  root: {
    '& label.Mui-focused': {
      color: 'rgb(19, 47, 76)',
      backgroundcolor: "rgb(19, 47, 76)"
    },
    "& .Mui-error": {
      color: "red! important"
    },
    '& .MuiInput-underline:after': {
      //backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgb(19, 47, 76)',
      },
      '&:hover fieldset': {
        borderColor: 'rgb(19, 47, 76)',
        // backgroundColor: "rgb(19, 47, 76)",
        color: "rgb(19, 47, 76)"

      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgb(19, 47, 76)',

      },
    },
  },
  icon: {
    color: "rgb(102, 178, 255)",
    fontSize: "25px"
  }


}));
const ProfileComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate()
  const [selectedFiles, setselectedFiles] = React.useState([])

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        // formattedSize: formatBytes(file.size),
      })
    )

    setselectedFiles(files)
  }
  const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    FanName: yup
      .string('Enter your Fan name')
      .min(1, 'Fan name Required')
      .required('Password is required'),
    // GitHubLink: yup
    //   .string('Enter your GitHubLink')
    //   .min(1, 'GitHubLink is required')
    //   .required('GitHubLink is required'),
    // DiscordLink: yup
    //   .string('Enter your Discord Link')
    //   .min(1, 'Discord is required')
    //   .required('Discord is required'),
    // MediumLink: yup
    //   .string('Enter your MediumLink')
    //   .min(1, 'MediumLink is required')
    //   .required('MediumLink is required'),
    // LinkedInLink: yup
    //   .string('Enter your LinkedInLink')
    //   .min(1, 'LinkedInLink is required')
    //   .required('LinkedInLink is required'),
  });

  const formik = useFormik({

    initialValues: {
      email: '',
      FanName: '',
      GitHubLink: "",
      DiscordLink: "",
      MediumLink: "",
      LinkedInLink: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (values) {
        let result = await makePostRequest('post/profile', values)
        if (result.error) {
          return toast.error(result.message, {
            style:
            {
              border: "1px solid rgb(19, 47, 76)",
              position: "relative",
              top: "80px",
              background: "rgb(19, 47, 76)",
              color: "rgb(102, 178, 255)"
            }
          })
        }
        toast.success("successfully Profile saved")
        navigate("/project")
        selectedFiles[0] && <p>Image is Required</p>

      }
    },

  });





  return (

    <BodyContainer>
      <Toaster position="top-right" />
      <MiddleContainer>
        {/* <BackButton onClick={() => navigate(-1)}>
          <LeftOutlined style={{ fontSize: "18px", color: "white" }} />
        </BackButton> */}
        <BodyHeader1> Profile</BodyHeader1>
        <RootComponent>

          <form onSubmit={formik.handleSubmit} >
            <Grid container spacing={1}>
              <Grid item lg={12}>
                <Dropzone
                  onDrop={acceptedFiles => {
                    handleAcceptedFiles(acceptedFiles)
                  }} validator={"image is required"}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick"

                      >

                        <Profile {...getRootProps()}>
                          <input {...getInputProps()} type="file" />

                          {selectedFiles[0]?.preview ? <ProfileImage src={selectedFiles[0]?.preview} /> : <CameraAltIcon className={classes.icon} />}
                        </Profile>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </Grid>



              <Grid item lg={6} sm={12} xs={12} >
                <TextField name="FanName" className={classes.root} inputProps={{ style: { color: "rgb(172 166 166)", fontFamily: "Sofia Pro" }, }} onChange={formik.handleChange} error={formik.touched.FanName && Boolean(formik.errors.FanName)}
                  InputLabelProps={{ style: { color: 'rgb(102, 178, 255)', fontFamily: "Sofia Pro" }, }} helperText={formik.touched.FanName && formik.errors.FanName}
                  id="outlined-basic" label="Fan Name*" variant="outlined" margin="dense" fullWidth />
              </Grid>
              <Grid item lg={6} sm={12} xs={12}>
                <TextField name="email" value={formik.values.email} className={classes.root} inputProps={{ style: { color: "rgb(172 166 166)", fontFamily: "Sofia Pro" }, }} onChange={formik.handleChange} error={formik.touched.email && Boolean(formik.errors.email)}
                  InputLabelProps={{ style: { color: 'rgb(102, 178, 255)', fontFamily: "Sofia Pro" }, }} helperText={formik.touched.email && formik.errors.email}
                  id="outlined-basic" label="Email*" variant="outlined" margin="dense" fullWidth />
              </Grid>
              <Grid item lg={6} sm={12} xs={12}>
                <TextField name="GitHubLink" className={classes.root} inputProps={{ style: { color: "rgb(172 166 166)", fontFamily: "Sofia Pro" }, }} error={formik.touched.GitHubLink && Boolean(formik.errors.GitHubLink)}
                  onChange={formik.handleChange} InputLabelProps={{ style: { color: 'rgb(102, 178, 255)', fontFamily: "Sofia Pro" }, }} helperText={formik.touched.GitHubLink && formik.errors.GitHubLink}
                  id="outlined-basic" label="GitHub Link" variant="outlined" margin="dense" fullWidth />
              </Grid>
              <Grid item lg={6} sm={12} xs={12}>
                <TextField name="DiscordLink" className={classes.root} inputProps={{ style: { color: "rgb(172 166 166)", fontFamily: "Sofia Pro" }, }} onChange={formik.handleChange} error={formik.touched.DiscordLink && Boolean(formik.errors.DiscordLink)}
                  InputLabelProps={{ style: { color: 'rgb(102, 178, 255)', fontFamily: "Sofia Pro" }, }} helperText={formik.touched.DiscordLink && formik.errors.DiscordLink}
                  id="outlined-basic" label="Discord Link" variant="outlined" margin="dense" fullWidth />
              </Grid>
              <Grid item lg={6} sm={12} xs={12}>
                <TextField name="MediumLink" className={classes.root} inputProps={{ style: { color: "rgb(172 166 166)", fontFamily: "Sofia Pro" }, }} onChange={formik.handleChange} error={formik.touched.MediumLink && Boolean(formik.errors.MediumLink)}
                  InputLabelProps={{ style: { color: 'rgb(102, 178, 255)', fontFamily: "Sofia Pro" }, }} helperText={formik.touched.MediumLink && formik.errors.MediumLink}
                  id="outlined-basic" label="Medium Link" variant="outlined" margin="dense" fullWidth />
              </Grid>
              <Grid item lg={6} sm={12} xs={12}>
                <TextField name="LinkedInLink" className={classes.root} inputProps={{ style: { color: "rgb(172 166 166)", fontFamily: "Sofia Pro" }, }} onChange={formik.handleChange} error={formik.touched.LinkedInLink && Boolean(formik.errors.LinkedInLink)}
                  InputLabelProps={{ style: { color: 'rgb(102, 178, 255)', fontFamily: "Sofia Pro" }, }} helperText={formik.touched.LinkedInLink && formik.errors.LinkedInLink}
                  id="outlined-basic" label="LinkedIn Link" variant="outlined" margin="dense" fullWidth />
              </Grid>
              {/* <Grid item lg={6} sm={12}  xs={12}>
                           <TextField id="outlined-basic" label="Outlined" variant="outlined" margin="dense" fullWidth />
                        </Grid>
                         */}




            </Grid>


            <BottomComponent>
              <SaveButton type="submit"  >Save</SaveButton>
            </BottomComponent>
          </form>

        </RootComponent>
      </MiddleContainer>
    </BodyContainer >
  )

}
export default ProfileComponent