import styled from "styled-components";

export const UploadComponent = styled.div`

display:flex;
width:100%;
height:250px;
background-color:rgb(24 40 56);
border-color: rgb(19, 47, 76);
justify-content:center;
flex-direction:column;
align-items:center;
border-radius:6px;
margin-top:40px;
margin-bottom:20px;

`

export const RootComponent = styled.div`
  border: 1px solid ${props => props.theme.secondary} ;
  width:100%;
  padding:20px;
  margin-top:20px;
  height:auto ;
  margin-bottom:180px ;
border-radius:8px;
`
export const BottomComponent = styled.div` 
 height:auto ;
 width:100% ;
 display:flex ;
 margin-top:40px;
 justify-content:center ;
`
export const SaveButton = styled.button`
width:100px;
height:40px ;
background-color: rgb(10,25,41) ;
border: 1px solid rgb(102, 178, 255)  ;
outline:none ;
color:rgb(102, 178, 255) ;
border-radius:5px ;
:hover{
  background-color:rgb(19, 47, 76)  ;
  border-color:rgb(19, 47, 76);
}
`
export const BodyHeader1 = styled.div` 
font-weight: bold;
font-size: 3rem;
margin-top:100px   ;
box-sizing: border-box;
color: #FFFFFF;
font-family:Sofia Pro;
`

export const Profile = styled.div`
 width:100px;
 height:100px;
 border-radius:70px;
 border:1px solid rgb(19, 47, 76);
 display:flex;
 justify-content:center;
 align-items:center;

`

export const ProfileImage = styled.img`
width:100px;
height:100px;
border-radius:70px;

`