export const projectInitialState = {
    projectArr: [],
    Projectloading: false,


};
export const ProjectReducer = (state = projectInitialState, action) => {
    switch (action.type) {
        case 'INITIAL_LOADING':
            return {
                Projectloading: action.payload
            };
        case 'INITIAL_PROJECT':
            return {
                projectArr: action.payload
            };
        default:
            return state
    }
}