import { useCallback, useState } from "react"
import {useNavigate, useRoutes, useSearchParams} from "react-router-dom"


const useSearchHooks = async()=>{
    const navigate = useNavigate()
    const [search,setSearchParams] = useSearchParams()
    const UseSearchApi =useCallback((name,value)=>{
          let searchValues  = Object.values(value)
           if(searchValues.length > 0){
               setSearchParams({[name]: value})
           }
           else{
                console.log(name);
                navigate("/project")
                search.delete(name)
           }
           return search
        },[search])

    return {UseSearchApi}

}
export default useSearchHooks