import React from 'react'
import { CollectionBoxMiddle, CollectionCardContainer, CollectionContainer, CollectionInnerContainer, CollectionListText, CollectionTextContainer } from './index.styled'
import { BoxMiddle, CardContainer, HeaderMiddleText, RankingBodyButton, RankingBodyButtonText, RankingBodyButtonText2, RankingHeader, RankingImage } from '../RankingPage/index.styled'
import RankingCardImg from '../../../Static/Image/RankingCardImage.jpg_large'


function CollectionSales() {
    return (
        <CollectionContainer>
            <CollectionInnerContainer>
                <RankingHeader>
                    Collection Sales
                </RankingHeader>
                <HeaderMiddleText>
                    No collection is selling more NFTs right now! Watch the hottest NFT collections live!


                </HeaderMiddleText>

                <CollectionBoxMiddle>
                    <RankingBodyButton>
                        <RankingBodyButtonText>15 minutes</RankingBodyButtonText>
                        <RankingBodyButtonText2>30 minutes</RankingBodyButtonText2>
                        <RankingBodyButtonText2>1 hour</RankingBodyButtonText2>
                        <RankingBodyButtonText2>24 hours</RankingBodyButtonText2>
                        <RankingBodyButtonText2>7 days</RankingBodyButtonText2>

                    </RankingBodyButton>
                </CollectionBoxMiddle>
                <CollectionTextContainer>
                    <CollectionListText>No sales in this time frame, check the other time frames. 😇</CollectionListText>
                </CollectionTextContainer>
                <CollectionCardContainer>
                    <RankingImage src={RankingCardImg} />
                    <RankingImage src={RankingCardImg} />
                    <RankingImage src={RankingCardImg} />
                </CollectionCardContainer>
            </CollectionInnerContainer>
        </CollectionContainer>
    )
}

export default CollectionSales