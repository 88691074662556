// import React from 'react'
// import { BirdIcon, ButtonDropDown, ButtonHeader, ButtonImage, ButtonText, HamburgerButton, HeaderButton, HeaderCombine, HeaderContainer1, HeaderImage, HeaderImageButton, InnerContainer, InnerContainerMobile, MobileContainerTop, MobileHeaderImage, NavButton, NavLink, SearchBox, TwitterImage } from './index.styled'
// import HeaderImg from '../../../Static/Image/Header.svg'
// import { Menu, Button, Dropdown } from 'antd';
// import { DownOutlined, UserOutlined, CaretDownFilled } from '@ant-design/icons';
// import EthereumImg from '../../../Static/Image/ethereum.svg';
// import DropDown from '../../../Static/Image/dropdown.png'
// import { SearchOutlined } from '@ant-design/icons';
// import BirdImage from '../../../Static/Image/bird.svg'
//import Hamburger from '../../../Static/Image/Hamburger.png'
// function Header() {
//     const menu = (
//         <Menu >
//             <Menu.Item key="0" icon={<UserOutlined />}>
//                 <img /><span>1st menu item</span>
//             </Menu.Item>

//         </Menu>
//     );

//     return (
//         <HeaderContainer1>
//             <InnerContainer>
//                 <HeaderImage src={HeaderImg} />
//                 <Dropdown overlay={menu} trigger={['click']}>
//                     <ButtonHeader onClick={e => e.preventDefault()}>
//                         <ButtonImage src={EthereumImg} />
//                         <CaretDownFilled style={{ color: "white", marginLeft: "10px", verticalAlign: "center" }} />
//                     </ButtonHeader>
//                 </Dropdown>
//                 <SearchBox placeholder='Search Projects...'>
//                 </SearchBox>
//                 <NavLink>
//                     <ButtonText>Ranking</ButtonText>
//                 </NavLink>
//                 <NavButton>
//                     <ButtonText>Trending</ButtonText>
//                 </NavButton>
//                 <NavButton>
//                     <ButtonText>Upcoming</ButtonText>
//                 </NavButton>
//                 <NavButton>
//                     <ButtonText>Collection Sales</ButtonText>
//                 </NavButton>
//                 <NavButton>
//                     <ButtonText>NFT Sales</ButtonText>
//                 </NavButton>
//                 <TwitterImage src={BirdImage} />
//             </InnerContainer>


//             <InnerContainerMobile>
//                 <MobileHeaderImage src={HeaderImg} />
//                 <HeaderCombine>
//                     <Dropdown overlay={menu} trigger={['click']}>
//                         <HeaderButton>
//                             <HeaderImageButton src={EthereumImg} />
//                             <CaretDownFilled style={{ color: "white", marginLeft: "10px", verticalAlign: "center" }} />
//                         </HeaderButton>
//                     </Dropdown>
//                     <HamburgerButton src={Hamburger} />
//                 </HeaderCombine>
//             </InnerContainerMobile>

//         </HeaderContainer1>
//     )
// }

// export default Header


import React, { useEffect } from "react";
import {
    HeaderContainer,
    LeftCont,
    RightCont,
    Logo,
    Chaincont,
    TabItem,
    Nft,
    Feed,
    HeaderRightCont,
    ProjectButton,
    HamburgerButton,
    HeaderBox,
    HeadBoxText,
    HeadBoxText1,
    ConnectButton,
    ProjectButton1,

} from "./index.styled";
import styled from "styled-components";
import '../../../'
import Suggest from "../../Layout/Header/HeaderPage";
import Hamburger from '../../../Static/Image/Hamburger.png'
import { Link, useNavigate } from "react-router-dom";
import ethereumImg from '../../../Static/Image/ethereum.svg'
import { useState } from "react";
import { HeartFilled, InfoOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { color } from "@mui/system";
import AvalancheImg from '../../../Static/Image/TipReceived.png'
import FantomImg from '../../../Static/Image/TipGiven.png'
import { makeStyles } from '@mui/styles';
import { useMoralis } from "react-moralis";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { red } from "@material-ui/core/colors";
import DropDownRight from "./HeaderDropDown";
import { useAppContext } from "../../../context/AppContext";
import { useConnect } from "../../../UI/helper/useConnect.js"
import Select from '@mui/material/Select';
import { NavDropdown } from "react-bootstrap";
import logo from '../../../Static/Image/lohomain.svg'
export const HeaderTrans = Object.freeze({
    Nft: "nft",
    Feed: "feed",
});

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: "30px",
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],

        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                fontFamily: "Sofia Pro",

                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: "white"

            },
        },
    },
}));
const useStyle = makeStyles((theme) => ({
    profile: {
        color: ""
    }
}))
export const ProfileBox = styled.div` 
    width:35px ;
    height:35px ;
    border-radius:10px ;
    background-color:rgb(24 40 56) ;
    margin-top:3px ;
    margin-left:10px;
    //background-color: ${({ backgroundColor }) => (backgroundColor ? "white" : "#394783")};
    :hover{
        background-color:#394364 ;
    }
    `

export const Header = () => {
    const tabItems = [
        {
            id: 0,
            value: "Home",
        },
        {
            id: 1,
            value: "Projects",
        },
        {
            id: 2,
            value: "Fans",
        },
        {
            id: 3,
            value: "Top Earner",
        },

    ];
    const navigate = useNavigate()

    const activeStyle = { color: 'red' };
    const [isActive, setIsActive] = useState(false)
    const classes = useStyle()
    const [projectActive, setProjectActive] = useState(true)
    const [earnedActive, setEarnedActive] = useState(false)
    const [dropDownItem, setDropDownItem] = useState(false)
    const [dropDownItem1, setDropDownItem1] = useState(true)
    // const open = Boolean(anchorEl);
    const UseConnectFunction = useConnect()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [state, setState] = useState()
    const [color, setColor] = useState(false)
    const { user, authenticate, isAuthenticated } = useMoralis()
    const { State, dispatch } = useAppContext()
    const [dropDownList, setDropDownList] = useState(true)
    const navigationPage = (e) => {
        navigate(`/fan`)
        setIsActive(true)
        setProjectActive(false)
        setEarnedActive(false)

    }
    const HomePage = () => {
        navigate(`/`)
        setIsActive(false)
        setProjectActive(true)
        setEarnedActive(false)

    }
    const EarnerPage = () => {
        navigate(`/topEarn`)
        setEarnedActive(true)
        setIsActive(false)
        setProjectActive(false)
    }
    const HeaderDropdown = () => {
        setDropDownItem(true)
        setDropDownList(false)
    }
    const HeaderDropdown1 = () => {
        setDropDownItem(false)
        setDropDownList(true)

    }
    const ConnectFunction = () => {
        UseConnectFunction()
        setDropDownItem(false)
        setDropDownList(true)

    }
    const handleClose = () => {
        setAnchorEl(null);
        setDropDownItem1(true)
        setDropDownList(false)
    };
    const handleNavigate = () => {
        setAnchorEl(null);
        setDropDownItem1(false)
        setDropDownList(true)
        navigate(`/profile`)
    }

    useEffect(() => {
        if (isAuthenticated) {
            return dispatch({
                type: "CREATE_AUTH",
                id: 1,
                wallet_id: user?.attributes?.ethAddress
            })

        }
    }, [isAuthenticated])

    console.log(State.Auth)
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const TipReceived = () => {
        navigate(`/tipReceived`)
    }
    const TipGiven = () => {
        navigate(`/tipGiven`)
    }
    const Tips = () => {
        navigate(`/topEarn`)
    }
    // setState(State.Auth.wallet_id)
    return (
        <HeaderContainer>
            <LeftCont>
                {/* <Logo onClick={(e) => { navigate(`/`) }}>
                    <Nft>{HeaderTrans.Nft}</Nft>
                    <Feed>{HeaderTrans.Feed}</Feed>
                </Logo> */}
                <img style={{ width: "118px", height: "45px", cursor: "pointer" }} onClick={(e) => { navigate(`/`) }} src={logo} />
                {/* <Chaincont> */}
                <Suggest />
                {/* </Chaincont> */}
            </LeftCont>
            {/* <RightCont>
                {tabItems.map((tabItems) => (
                    <TabItem>{tabItems.value}</TabItem>
                ))}
            </RightCont> */}
            <HeaderRightCont>

                <ProjectButton to="/project" activeclassname="anyClassNameWillWork" /*onClick={HomePage}*/
                // style={{
                //     color: projectActive ? 'white' : 'rgb(148,160,178)',
                // }}
                >Projects</ProjectButton>
                <ProjectButton1 to="/fan" activeclassname="anyClassNameWillWork" /*onClick={navigationPage}*/

                >Fans</ProjectButton1>
                <NavDropdown title="Tips" id="nav-dropdown">
                    <NavDropdown.Item id="nav-dropdown1" onClick={TipReceived} > <img src={AvalancheImg} style={{ width: "20px", marginRight: "8px" }} />Tips Received</NavDropdown.Item>
                    <NavDropdown.Item id="nav-dropdown1" onClick={TipGiven}     ><img src={FantomImg} style={{ width: "20px", marginRight: "8px" }} />Tips Given</NavDropdown.Item>


                </NavDropdown>



                {/* <HeaderBox>
                    {!dropDownItem && <HeadBoxText1>**********</HeadBoxText1>}
                    {dropDownItem && <HeadBoxText>0x3bba27c..</HeadBoxText>}
                    <img src={ethereumImg} />
                </HeaderBox> */}
                {
                    isAuthenticated ?
                        <HeaderBox>


                            {dropDownList && <DropDownRight setDropDownItem={setDropDownItem} setDropDownItem1={setDropDownItem1} setDropDownList={setDropDownList} wallet_id={user?.attributes?.ethAddress} />}

                        </HeaderBox> :
                        <HeaderBox>
                            {dropDownItem1 && <ConnectButton onClick={ConnectFunction}>Connect
                                <img src={ethereumImg} /></ConnectButton>}

                        </HeaderBox>
                }
                {/* {!dropDownItem && <EyeInvisibleOutlined style={{ fontSize: "20px", color: "rgb(148,160,178)", marginTop: "10px", marginLeft: "10px" }} onClick={HeaderDropdown} />}
                {dropDownItem && <EyeOutlined style={{ fontSize: "20px", color: "rgb(148,160,178)", marginTop: "10px", marginLeft: "10px" }} onClick={HeaderDropdown1} />} */}

                <HamburgerButton src={Hamburger} />
            </HeaderRightCont>
        </HeaderContainer>
    );
};