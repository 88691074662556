import React from 'react'
import { CardTitle } from 'reactstrap'
import { CardFooter, CardHead, Cardtitle, SectionCard, SectionCardMiddle, SectionContainer, SectionContentHead, SectionFlex, SectionFlexContent, SectionHeader, SectionHeaderContent, SectionInnerContainer, TipButton } from './index.styled'
import DoneIcon from '@mui/icons-material/Done';
import ApprovedImg from '../../../Static/Image/approved.png'

function Section7() {
    return (
        <SectionContainer>
            <SectionInnerContainer>
                <SectionHeader>
                    <SectionHeaderContent>
                        Plans
                    </SectionHeaderContent>
                </SectionHeader>
                <SectionCard>
                    <CardHead>
                        <Cardtitle>
                            Free
                        </Cardtitle>
                    </CardHead>
                    <SectionCardMiddle>
                        <SectionFlex>
                            <img src={ApprovedImg} style={{ width: "15px", height: "15px", marginRight: "15px", marginTop: "5px" }} />
                            <SectionContentHead>
                                Price in NFT
                            </SectionContentHead>
                        </SectionFlex>
                        <SectionFlexContent>
                            $0
                        </SectionFlexContent>
                    </SectionCardMiddle>
                    <SectionCardMiddle>
                        <SectionFlex>
                            <img src={ApprovedImg} style={{ width: "15px", height: "15px", marginRight: "15px", marginTop: "5px" }} />

                            <SectionContentHead>
                                Validity
                            </SectionContentHead>
                        </SectionFlex>
                        <SectionFlexContent>
                            NA
                        </SectionFlexContent>
                    </SectionCardMiddle><SectionCardMiddle>
                        <SectionFlex>
                            <img src={ApprovedImg} style={{ width: "15px", height: "15px", marginRight: "15px", marginTop: "5px" }} />

                            <SectionContentHead>
                                Commission
                            </SectionContentHead>
                        </SectionFlex>
                        <SectionFlexContent>
                            50%
                        </SectionFlexContent>
                    </SectionCardMiddle>
                    <SectionCardMiddle>
                        <SectionFlex>
                            <img src={ApprovedImg} style={{ width: "15px", height: "15px", marginRight: "15px", marginTop: "5px" }} />

                            <SectionContentHead>
                                Receivable
                            </SectionContentHead>
                        </SectionFlex>
                        <SectionFlexContent>
                            50%
                        </SectionFlexContent>
                    </SectionCardMiddle>
                    <CardFooter>
                        <TipButton>Buy now</TipButton>
                    </CardFooter>
                </SectionCard>
            </SectionInnerContainer>
        </SectionContainer >
    )
}

export default Section7