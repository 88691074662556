import styled from "styled-components";

export const FilterContainer = styled.div`
display:flex;
flex:1;
align-items:flex-end;
flex-direction:column;
justify-content:center;
z-index:2;
@media only screen and (max-width: ${props => props.theme.bpmd}) {
align-items:flex-start ;
}
`
export const FilterBox = styled.div`
border:1px solid rgb(19, 47, 76);
width:100%;
flex:1;
height:50px;
border-radius:5px;
flex-direction:row;
align-items:center;
display:flex;
padding:5px;
justify-content:space-between;
align-items:center;
margin-bottom:15px;
margin-right:20px;
&:hover {
  background-color: ${props => props.theme.secondary};
}

`
export const FilterName = styled.h4`
color: ${props => props.theme.main};
position:relative;
top:3px;
font-size:15px ;
`
export const FilterIcon = styled.div`
 display:flex;
 flex:1;
 justify-content:flex-end;

`
export const TotalFilterBox = styled.div` 
display: flex;
 width: 50%; 
 flex-direction: row; 
 @media only screen and (max-width: ${props => props.theme.bpsm}) {
width:70% ;
}
@media only screen and (max-width: ${props => props.theme.bpxz}) {
width:50% ;
flex-direction:column ;
}
`