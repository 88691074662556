import React from "react"



export const Section2 =()=>{
    return (
        <React.Fragment>
            <section className="section2">
        <div className="container responsive_container">
             <div>
                 <h3 className="section_2_title">Lover by communtiy <br></br> & partners</h3>
             </div>
             <div className="image_container">
                  <div className="responsive_image_container">
                    <img src="https://uploads-ssl.webflow.com/60098c5b3e6c5a3d3125082e/61c70db46dade9942e337b24_partner-trustwallet.png" loading="lazy" alt="trustwallet" className="brand_image"/>                
                 </div>
                 <div className="responsive_image_container">
                    <img src="https://uploads-ssl.webflow.com/60098c5b3e6c5a3d3125082e/61c70dbbd3e62000b4f2de12_partner-dappradar.png" loading="lazy" alt="dappradar" className="brand_image"/>                
                 </div>

                 <div className="responsive_image_container">
                    <img src="https://uploads-ssl.webflow.com/60098c5b3e6c5a3d3125082e/61c70dc11fa6f7fdc684296f_partner-dapp.png" loading="lazy" alt="dapp" className="brand_image"/>               
                </div>
                 <div className="responsive_image_container">
                    <img src="https://uploads-ssl.webflow.com/60098c5b3e6c5a3d3125082e/61c70dc740b8b98b1e265502_partner-coinmarketcap.png" loading="lazy" alt="coinmarketcap" className="brand_image"/>                 
                </div>
                <div className="responsive_image_container">
                    <img src="https://uploads-ssl.webflow.com/60098c5b3e6c5a3d3125082e/61c70dcc1ecff0a8d449ed1b_partner-betfury.png" loading="lazy" alt="betfury"  className="brand_image"/>                
                 </div>
                 <div className="responsive_image_container">
                    <img src="https://uploads-ssl.webflow.com/60098c5b3e6c5a3d3125082e/61c70dd440b8b95553265517_partner-binance.png" loading="lazy" alt="binance" className="brand_image"/>
                 </div>
             </div>
        </div>
    </section>
        </React.Fragment>
    )
}