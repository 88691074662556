export const AuthInitialState = {
    id:"" ,
    wallet_id:""
}

export const AuthReducer = (state=AuthInitialState,action)=>{
    switch(action.type){
      
        case "CREATE_AUTH":
        return {
            id:action.id,
            wallet_id:action.wallet_id
        }

        case "UPDATE_AUTH":
            return {
                wallet_id:action.wallet_id
        }
        case "AUTH_LOGOUT":
            return {
                id:"",
                wallet_id:""
            }

        default :
           return state

    }
}