import React from "react"
import { BodyContainer, MiddleContainer } from "../LandingPage/index.styled"



export const Section1 =()=>{
    return (
        <React.Fragment>
    <section className="section_1">
       <BodyContainer>
        <MiddleContainer>
             <div className="row responsive_container">
                  <div className="col" >
                        <div>
                            <h1 className="section_1_title" data-aos="fade-right">NFT Marketplace built on BSC, FTM and Polygon</h1>
                        </div>
                        <div data-aos="fade-right">
                           
                                <h3  id="subtitle">🖼 Mint, Trade and Earn with NFTs</h3>
                                <h3 id="subtitle">⚡️ Faster and cheaper fees under $1</h3>
                                <h3 id="subtitle">💰 Earn BNB, FTM and Matic</h3>
                        </div>

                        <div  className="button-wrapper" data-aos="fade-right">
                             <div className="responsive_btn_container">
                                <a href="#" target="_blank" className="button-secondary w-button" style={{backgroundColor:' #2f6dff',color: 'white'}}>Trade NFTs Now</a>
                            </div>
                            <div className="responsive_btn_container">
                                <a href="/buy-airt" target="_blank" className="button-secondary w-button">Buy AIRT&nbsp;&amp;&nbsp;Earn</a>
                            </div>
                        </div>
                        <div className="row view ">
                               <div className="col-4 responsive_col" data-aos="fade-right">
                                   <h3 className="views_title">240+</h3>
                                    <p className="views_subtitle">NFTS MINTED</p>
                               </div>
                               <div className="col-4  responsive_col" data-aos="fade-right">
                                    <h3 className="views_title">$14M+</h3>
                                    <p className="views_subtitle">TRADING VOLUMES</p>
                               </div>
                        </div>
                    </div>
                  <div className="col " data-aos="fade-up">
                    <img src="https://uploads-ssl.webflow.com/60098c5b239e4890fa5ee1aa/61d4b23e0885ed1a0c13bb42_nft-collectibles-hero.png"  height="" width="85%" alt="Shows examples of NFTs you can trade on market" className="hero-image"/>                  
                 </div>
             </div>
        </MiddleContainer>
     </BodyContainer> 
    </section>
        </React.Fragment>
    )
}