import logo from './logo.svg';
import { BrowserRouter, Route, Routes, } from 'react-router-dom';
import { ThemeProvider } from "styled-components";
import theme from './Static/theme';
import './App.css'
import { Header } from './UI/Layout/Header/index';
import FooterComponent from './UI/Components/Footer';
import { routes } from './route';
// css
import './css/Section1.css';
import './css/App2.css';
import './css/App3.css';
import './css/App4.css';
import './css/App5.css';
import './css/App6.css';
import './css/App7.css';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Header />
        <Routes>
          {routes.map((route, index) => {
            return (
              route.path === "/" ? <Route path={route.path} element={route.component} exact={true} key={index} /> : <Route path={route.path} element={route.component} key={index}></Route>
            )
          })}

        </Routes>
        <FooterComponent />
      </ThemeProvider>

    </BrowserRouter>
  );
}

export default App;
