import React from "react";
import { DataGrid } from "@mui/x-data-grid";

export const DataGridComponent = () => {
    const columns = [
        { field: "id", headerName: "id", width: 90 },
        {
            field: "id",
            headerName: "#",
            type: "number",
            width: 200,
            editable: true,
        },
        {
            field: "projectName",
            headerName: "Project Name",
            width: 200,
            editable: true,
        },
        {
            field: "Nfts",
            headerName: "NFTs",
            type: "number",
            width: 200,
            editable: true,
        },
        {
            field: "totaltips",
            headerName: "Total Tips",
            type: "number",
            width: 200,
            editable: true,
        },
    ];

    const rows = [
        { id: 1, id: "Snow", projectName: "Jon", Nfts: 35, totaltips: 1000 },
        { id: 2, id: "Snow", projectName: "Jon", Nfts: 35, totaltips: 1000 },
        { id: 3, id: "Snow", projectName: "Jon", Nfts: 35, totaltips: 1000 },
        { id: 4, id: "Snow", projectName: "Jon", Nfts: 35, totaltips: 1000 },
        { id: 5, id: "Snow", projectName: "Jon", Nfts: 35, totaltips: 1000 },
        { id: 6, id: "Snow", projectName: "Jon", Nfts: 35, totaltips: 1000 },
        { id: 7, id: "Snow", projectName: "Jon", Nfts: 35, totaltips: 1000 },
        { id: 8, id: "Snow", projectName: "Jon", Nfts: 35, totaltips: 1000 },
        { id: 9, id: "Snow", projectName: "Jon", Nfts: 35, totaltips: 1000 },
    ];

    return (
        <div style={{ width: "100%" }}>
            <div style={{ height: "500px", paddingTop: "20px" }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                    disableSelectionOnClick
                    style={{ color: "#94A1B7", backgroundColor: "#191F28" }}
                />
            </div>
        </div>
    );
};