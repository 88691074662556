export const assetsInitialState = {
    initialAsset: [],
    assetsArr: [],
    Assetsloading: false,
    TipAssetArr: [],
    assetsArray: [],
    InitialTipAsset: [],
    TipAssetloading: false,
    HeadAsset: [],
    DetailAsset: []

};
export const AssetsReducer = (state = assetsInitialState, action) => {

    switch (action.type) {
        case 'INITIAL_LOADING':
            return {
                ...state,
                Assetsloading: true
            };
        case 'INITIAL_ASSETS':
            return {
                ...state,
                initialAsset: action.payload,
                assetsArr: action.payload,
                assetsArray: action.payload
            };
        case 'HEAD_ASSETS':
            return {
                ...state,
                HeadAsset: action.payload

            };
        case 'DETAIL_ASSETS':
            return {
                ...state,
                DetailAsset: action.payload,
                assetsArr: action.payload


            }
        case 'CHANGE_ASSETS':
            return {
                ...state,
                assetsArr: action.payload
            };
        case 'SEARCH_ASSETS': {

            let filteredItem = state.initialAsset.filter((item) => {
                return item.name.toLowerCase().includes(action.searchText)
            })


            return {
                ...state,
                assetsArr: filteredItem
            }
        };
        case 'SEARCH_ASSETLIST': {
            let filteredItem = state.initialAsset.filter((item) => {
                return item.wallet_id.toLowerCase().includes(action.searchText)
            })


            return {
                ...state,
                assetsArr: filteredItem
            }
        };
        case 'INITIAL_TIPARRAY':
            return {
                ...state,
                InitialTipAsset: action.payload,
                TipAssetArr: action.payload,
                TipAssetloading: action.ploading,

            };
        case 'INITIAL_FANARRAY':
            return {
                ...state,
                InitialTipAsset: action.payload,
                TipAssetArr: action.payload,
                TipAssetloading: action.ploading,

            };
        case 'INITIAL_LOADING':
            return {
                ...state,
                TipAssetloading: action.ploading,

            }

        default:
            return state
    }
}