import styled from "styled-components";
export const RankingContainer = styled.div` 
 width:100% ;
 height:auto;
 display:flex ;
 justify-content:center ;
 background-color:#1E2634;
 `
export const RankingMiddleContainer = styled.div` 
 width:87% ;
 height:auto ;
border-bottom:3px solid #424D60 ;

 
 `
export const RankingHeader = styled.div` 
   font-weight: bold;
  font-size: 3rem;
  margin-top:60px ;
  color: #FFFFFF;
  margin-bottom: 0.5rem;
  font-family: Sofia Pro;
 `
export const HeaderMiddleText = styled.div` 
 color: #94A1B7;
 margin-top: 20px;
 font-size: 1.2rem;
 margin-bottom: 1.5rem;
  font-family:19.2px Sofia Pro;

 `
export const RankingImage = styled.img` 
   border-radius: 12px;
   height:138.7px ;
   width:31% ;
 `
export const CardContainer = styled.div` 
 width:100% ;
 height:178.7px ;
 display:flex;
 justify-content:space-between ;
 align-items:center ;
 `
export const BoxMiddle = styled.div` 
 height:66px ;
 width:100% ;
 background-color: #263143;
 border-radius: 12px;
   font-weight: bold;
   margin-top: 10px;
   border: 1px solid rgba(255,255,255,0.05);
   display:flex ;
   align-items:center ;
   padding-left:10px ;
   margin-bottom:50px ;
   box-sizing:border-box ;
 `
export const RankingBodyButton = styled.button` 
 color: #94A1B7;
 background-color: #263143;
 border:none;
 margin-right: 30px;
 cursor: pointer;
 //margin-top:25px ;
 display:flex ;
 
 `
export const RankingBodyButtonText = styled.div` 
   color: #FFFFFF ;
   font-weight: bold;
   font-size:15px ;
   margin-right: 30px;
 `
export const RankingBodyButtonText2 = styled.div` 
 color: #94A1B7;
 font-weight: bold;
   font-size:15px ;
   margin-right: 30px;
 `
export const TableContainer = styled.div` 
 width:100% ;
 height:auto ;
 margin-top:20px ;
 border-bottom: 1px solid #29313F;
 @media only screen and (max-width: ${props => props.theme.bpmd}) {
 overflow:scroll ;
 background-color: rgb(25,31,40);
 border-bottom: 1px solid #29313F;
}
@media only screen and (max-width: ${props => props.theme.bpxs}) {
display:none
}
 `
export const TableHead = styled.div` 
 width:100% ;
 height:65px;
 color: #94A1B7;
 
  background-color: rgb(25,31,40);
  border-top-left-radius: 12px;
  border-top-right-radius:12px ;
 

  box-sizing:border-box ;
  display:flex ;
  justify-content:center ;
 
`
export const HashText = styled.div` 
width:3% ;
color: #94A1B7;
@media only screen and (max-width: ${props => props.theme.bpmd}) {
 }
@media only screen and (max-width: ${props => props.theme.bpxs}) {
 }
 @media only screen and (max-width: ${props => props.theme.bpxy}) {
       
 }
 @media only screen and (max-width: ${props => props.theme.bpxz}) {
        
  }
`
export const HashText1 = styled.div` 
width:8% ;
color: #94A1B7;
`
export const NameText = styled.div` 
font-weight: bold;
color: #94A1B7;
font-size:14px ;
padding-right:21% ;

`
export const TextHead = styled.div` 
font-weight: bold;
color: #94A1B7;
font-size:14.5px ;
padding-right:5% ;
border-bottom: 1px solid #29313F;

@media only screen and (max-width: ${props => props.theme.bpxlg}) {
   padding-right:4% ;
  }
  @media only screen and (max-width: ${props => props.theme.bplg}) {
   padding-right:3% ;
  }
  @media only screen and (max-width: ${props => props.theme.bpmd}) {
   padding-right:2.5% ;
  }
`
export const TextHeadFloor = styled.div` 
font-weight: bold;
color: #94A1B7;
font-size:14.5px ;
padding-right:6% ;
@media only screen and (max-width: ${props => props.theme.bpmd}) {
   padding-right:3% ;
  }
`
export const TextHeadVotes = styled.div` 
font-weight: bold;
color: #94A1B7;
font-size:14.5px ;
padding-right:7% ;
@media only screen and (max-width: ${props => props.theme.bpxlg}) {
   padding-right:5% ;
  }
`
export const TableContent = styled.a` 
width:100% ;
height:65px;
 color: #94A1B7;
 background-color: rgb(25,31,40);
 padding-left: 25px;
  padding-right: 10px;
  box-sizing:border-box ;
  display:flex ;
  align-items:center ;
  :hover{
    background-color:rgb(10,28,60)  ;
  }
  /* @media only screen and (max-width: ${props => props.theme.bpsm}) {
 overflow:scroll ;

} */
`
export const InnerTableHead = styled.div` 
width:98%;
height:65px ;
padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid #29313F;
  display:flex ;
 align-items:center ;
 box-sizing:border-box ;
`
export const SerialNo = styled.div` 
  color: #1010bc;
  width:8.5% ;
`
export const DropDown = styled.img` 
width:32px ;
height:32px ;
margin-right: 3%;
`
export const CardIcon = styled.img` 
width:44.7px ;
height:44.7px;
margin-right:2.5% ;
`
export const CardName = styled.div` 
width:16.5% ;
height:32px ;
display:flex ;
justify-content:start ;
align-items:center ;
`
export const CardNameText = styled.div`
color: white;
font-size:16px ;
padding-right:10px ;
font-family:Sofia Pro;
cursor: pointer;

`
export const CardNameText1 = styled.div`
color: white;
font-size:16px ;
padding-right:10px ;
font-family:Sofia Pro;
cursor: pointer;
text-transform:capitalize ;

`

export const CardImageName = styled.img` 
height:15px ;
width:15px ;
`
export const CardButtonLaunched = styled.button` 
border:none ;
color: #43F6AB;
  background: rgba(67,246,171,0.03);
  border: 1px solid rgba(67,246,171,0.05);
  border-radius: 12px;
  height:40px ;
  width:7% ;
  margin-right:1% ;
`
export const VolumeContainer = styled.div` 
width:7% ;
justify-content:center ;
align-items:center ;
display:flex ;
margin-right:2% ;
`
export const VolumeContent = styled.div` 
color: white;
font-size:16px ;
font-family:Sofia Pro ;
`
export const FloorContainer = styled.div` 
width:7% ;
justify-content:center ;
align-items:center ;
display:flex ;

margin-right:2.5% ;
`
export const TwitterContainer = styled.div` 
width:6% ;
justify-content:center ;
align-items:center ;
display:flex ;

margin-right:1.5% ;
`
export const DiscardContainer = styled.div` 
width:7% ;
justify-content:center ;
align-items:center ;
display:flex ;

margin-right:1.5% ;
`
export const VoteButton = styled.button` 
height:40px ;
width:6.5% ;
background-color: #43F6AB;
border-radius: 12px;
cursor: pointer;
font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
`
export const VotesContainer = styled.div` 
width:6% ;
justify-content:center ;
align-items:center ;
display:flex ;

margin-right:3% ;
`
export const FilterContainer = styled.div`
display:flex;
flex:1;
align-items:flex-end;
flex-direction:column;
justify-content:center;
`
export const FilterBox = styled.div`
border:1px solid rgb(19, 47, 76);
width:12%;
height:fit-content;
border-radius:5px;
flex-direction:row;
align-items:center;
display:flex;
padding:5px;
justify-content:space-evenly;
align-items:center;
margin-bottom:15px;
&:hover {
  background-color: ${props => props.theme.secondary};
}
`
export const FilterName = styled.h5`
color: ${props => props.theme.main};
position:relative;
top:3px;
`

