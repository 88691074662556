import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { Spin } from 'antd';
import 'antd/es/spin/style/css';
import { useAppContext } from '../../../../context/AppContext';
import StarIcon from '@mui/icons-material/Star';
import HandshakeIcon from '@mui/icons-material/Handshake';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PersonIcon from '@mui/icons-material/Person';
import { ApprovedImage, BlockMerge, BoxDisplay, BoxHeader, ButtonContainer, ButtonflexRow, ButtonInnerContainer, ButtonRated, ButtonVote, ButtonVote1, CardContainer1, CardContainerText, CardContainerText1, CardContainerText2, CardFooterContainer, CardFooterContiner, CardInnerContainer1, CardProjectDisplay, ContainerText, ContainerText1, DiscardButton, DiscardButton2, DiscardButton3, DisplayingFlex, EthText, ExploreButton, ExploreButton2, FooterText, HeaderBlock, InnerCardContainer, InnerCardFullContain, OpenseaButton, OpenseaButton2, PriceText, ProjectBodyContainer, ProjectBodyContainer2, ProjectBox, ProjectCard, ProjectCardImage, ProjectContainer, ProjectContainer2, ProjectFooterContainer, ProjectFooterInner, ProjectHeaderContain, ProjectHeadImage, ProjectInnerContainer, ProjectTitle, ProjectTitle1, RateColor, RateColor2, RightCombine, RightContent, TextContainer, TextInnerContainer, TwitterButton, TwitterButton2, TwitterButton3, TwitterRate, TwitterText, TwitterText1, TwitterText2, TwitterText3, TwitterText4, TwitterText5, VoteRateButton, VoteRateButton1, VoteSearch, VoteText, VoteText1, VoteText2, } from '../ProjectPage/index.styled'
import ProjectHeadImg from '../../../../Static/Image/ProjectHead.jpg'
import ApprovedImg from '../../../../Static/Image/approved.png'
import BirdImg from '../../../../Static/Image/bird1.svg'
import { HeartFilled, InfoOutlined, LeftOutlined } from '@ant-design/icons';
import Buttom2Img from '../../../../Static/Image/button2.svg'
import Buttom3Img from '../../../../Static/Image/button3.svg'
import OpenSeaImage from '../../../../Static/Image/openSea.svg'
import ProjectData from '../../../../utils/ProjectData'
import ProjectCard1 from '../../../../Static/Image/ProjectCard1.gif'
import SalesImg from '../../../../Static/Image/SalesCardDown.svg'
import CardProjectData from '../../../../utils/CardProjectData.json'
import CardProjectData1 from '../../../../utils/CardProjectData1.json'
import card3 from '../../../../Static/Image/card3.jpg'
import card4 from '../../../../Static/Image/card4.jpg'
import card5 from '../../../../Static/Image/card5.jpg'
import card6 from '../../../../Static/Image/card6.jpg'
import card7 from '../../../../Static/Image/card7.jpg'
import card8 from '../../../../Static/Image/card8.jpg'
import ViewMoreImg from '../../../../Static/Image/viewMore.svg'
import { BodyFooterContainer, ViewButton, ViewButtonText, SpinContainer, BackButton, BackButtonContainer } from '../.././LandingPage/index.styled'
import TwitterIcon from '@mui/icons-material/Twitter';
import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import LanguageIcon from '@mui/icons-material/Language';
import PublicIcon from '@mui/icons-material/Public';
import RankingData from "../../../../utils/RankingData.json"
import { Footer } from 'antd/lib/layout/layout'
import SearchProject from '../ProjectPage/SearchProject';
import makeGetRequest from '../../../../api/GetApi';

function ProjectPage1() {

    const param = useParams()
    const navigate = useNavigate()
    const [cardIncrement, setCardIncrement] = useState(5)
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState()
    const [collectionButton, setCollectionButton] = useState(true)
    const [ploading, setpLoading] = useState(true)
    const { State, dispatch } = useAppContext()
    const { fan } = State
    const [collection, setCollection] = useState([])
    const [amount, setAmount] = useState([])
    const [tip, setTip] = useState([])
    console.log(param)




    const getFanApi = async () => {
        const fans = await makeGetRequest(`/get/fanDetails/${param.id}`)
        console.log(fans.fan)
        dispatch({ type: "INITIAL_FANWALLETID", initialWalletId: fans.fan })
        setpLoading(false)
        let answer = []
        fans.fan.map((item) => {
            let objIndex = answer.findIndex((obj => obj.asset_id == item.asset_id));
            console.log("objIndex", objIndex)

            if (objIndex >= 0) {
                answer[objIndex].amount = answer[objIndex].amount + item.amount
                answer[objIndex].Tips = answer[objIndex].Tips + 1
            } else {
                let temp = {
                    asset_id: item.asset_id,
                    Tips: 1,
                    amount: item.amount,
                    wallet_id: item.wallet_id,
                    name: item.asset.name,
                    updated_at: item.updated_at,
                    image_url: item.asset.image_url
                }
                answer.push(temp)
            }

        })
        console.log(answer)
        dispatch({ type: "INITIAL_FANARR", payload: answer })
        var letters = fans.fan.filter((arr, index, self) =>

            index === self.findIndex((t) => (t.asset_id === arr.asset_id)))
        var Collection = fans.fan.filter((arr, index, self) =>

            index === self.findIndex((t) => (t.project_id === arr.project_id)))
        setCollection(Collection)
        setAmount(fans.fan)
        // const letters = new Set(fans.fan)
        console.log(letters)
        dispatch({ type: "INITIAL_FANWALLETID", initialWalletId: letters })

    }



    const ViewMore = () => {
        setLoading(true)

        setTimeout(() => {
            setLoading(false)
            setCardIncrement(cardIncrement + 6)
            window.scrollBy(0, -350);
            getFanApi()

        }, 1000)
    }


    const [projectItem, setProjectItem] = useState({

        "Serialno": "1",
        "img": "card1Img.jpg",
        "name": "Property Heist  ",
        "Volume": "-",
        "Floor": "-",
        "Size": "8.89k",
        "personimg": "person2.jpeg",
        "Twitter": "4.43k",
        "Discard": "6.18k",
        "votes": "AD",
        "TwitterRate": "11,242",
        "DiscardRate": "6,180",
        projectData: [
            {
                "ETH": "0.002ETH",
                "price": "$575",
                "name": "Floor Price"
            },
            {
                "ETH": "0.0023 ETH",
                "price": "$6.61",
                "name": "Average price"
            },
            {
                "ETH": "0.229 ETH",
                "price": " $658.72",
                "name": " Trading volume"
            },
            {
                "ETH": "354",
                "price": false,
                "name": "Owners"
            },
            {
                "ETH": "35",
                "price": false,
                "name": "Sales (7 days)"
            },
            {
                "ETH": "2,358",
                "price": false,
                "name": "Collection size"
            }
        ],
        "text": "Property Heist Ape Club is a 2,358 piece NFT collection stored on the Ethereum blockchain. The current floor price for a Property Heist Ape Club NFT is 0.002 ETH - $5.75. The total sales volume for Property Heist Ape Club is 0.229 ETH - $658.72 with 354 owners.",
        "text2": "We’ve seen a lot of apes join the ‘metaverse’ but let’s be honest most are without any real form or function, nor do the community benefit holding. Our apes are built different, period!All"

    })



    useEffect(() => {
        window.scrollTo(0, 0)

        // let filteredItem = RankingData.filter((item) => {
        //     return item.Serialno == param.id
        // })
        // setProjectItem(filteredItem[0])
        getFanApi()

    }, [])
    const handleRoute = () => {
        window.scrollTo(0, 0)
    }
    const TippedValue = (value) => {
        let b = value?.reduce((a, b) => {
            return a + b.amount
        }, 0)
        return b

    }
    return (
        <ProjectContainer>
            <ProjectContainer2>
                <ProjectInnerContainer>


                    <ProjectHeaderContain>
                        <ProjectHeadImage src={require(`../../../../Static/Image/${projectItem.personimg}`)} />
                        <HeaderBlock>
                            <div >
                                {console.log(fan.fanArr)}
                                <ProjectTitle1>
                                    {fan?.fanArr && fan?.fanArr[0]?.wallet_id?.slice(0, 17) + "..."}
                                    <ApprovedImage src={ApprovedImg} style={{ position: "relative", top: "0px" }} />
                                    {/* <BackButtonContainer>
                                        <BackButton onClick={() => navigate(-1)}>
                                         <LeftOutlined style={{ fontSize: "18px", color: "white" }} />
                                        </BackButton>
                                    </BackButtonContainer> */}
                                </ProjectTitle1>
                            </div>
                            <ContainerText>
                                {fan?.fanArr && fan?.fanArr[0]?.asset.asset_contract.name}

                            </ContainerText>

                            <ButtonflexRow>
                                <TwitterButton3>



                                    <TwitterText>Collections</TwitterText>
                                    <DisplayingFlex>
                                        <StarIcon style={{ color: "#009BEF", marginRight: "6px" }} />


                                        <TwitterText>{collection.length}</TwitterText>

                                    </DisplayingFlex>
                                </TwitterButton3>

                                <DiscardButton3>

                                    <TwitterText3>NFTs</TwitterText3>
                                    <DisplayingFlex>

                                        <PersonIcon style={{ color: "rgb(88,102,242)", marginRight: "6px" }} />

                                        <TwitterText3>{fan?.fanArr && fan?.fanArr?.length}</TwitterText3>

                                    </DisplayingFlex>
                                </DiscardButton3>

                                <OpenseaButton2>

                                    <TwitterText4>Tip Total Value</TwitterText4>
                                    <DisplayingFlex>

                                        <HandshakeIcon style={{ color: "rgb(32,129,226)", marginRight: "6px" }} />

                                        <TwitterText4>{TippedValue(amount)}</TwitterText4>


                                    </DisplayingFlex>


                                </OpenseaButton2>
                                <ExploreButton2>

                                    <TwitterText5>Tips Count</TwitterText5>
                                    <DisplayingFlex>

                                        <AttachMoneyIcon style={{ color: " rgb(83,184,117)", }} />

                                        <TwitterText5>{amount.length}</TwitterText5>


                                    </DisplayingFlex>


                                </ExploreButton2>
                            </ButtonflexRow>

                        </HeaderBlock>
                    </ProjectHeaderContain>
                </ProjectInnerContainer>
            </ProjectContainer2>
            {/* <ProjectBodyContainer>
                <ProjectBodyContainer2>
                    <BoxDisplay>
                        {projectItem.projectData.map((item) => {
                            return (


                                <ProjectBox>
                                    <BoxHeader>
                                        <EthText>{item.ETH}</EthText>
                                        {item.price && <PriceText>{item.price}</PriceText>}
                                        <PriceText>{item.name}</PriceText>

                                    </BoxHeader>
                                </ProjectBox>
                            )
                        })}
                    </BoxDisplay>

                </ProjectBodyContainer2>
            </ProjectBodyContainer> */}
            <TextContainer>



                <TextInnerContainer>
                    <ContainerText1>
                        {fan?.fanArr && fan?.fanArr[0]?.asset.asset_contract.description}

                    </ContainerText1>
                    {/* <ContainerText1></ContainerText1> */}
                    <VoteRateButton1>
                        <TwitterIcon sx={{
                            "&:hover": {
                                color: "rgb(62, 158, 214) ! important",
                                cursor: "pointer"
                            }
                        }} onClick={() => { return handleRoute() }} style={{ marginLeft: "25px", color: "white", verticalAlign: "middle", }} ></TwitterIcon>
                        <CloudCircleIcon sx={{
                            "&:hover": {
                                color: "rgb(62, 158, 214) ! important",
                                cursor: "pointer"
                            }
                        }} onClick={() => { return handleRoute() }} style={{ marginLeft: "25px", color: "white", verticalAlign: "middle", }} />
                        <PublicIcon sx={{
                            "&:hover": {
                                color: "rgb(62, 158, 214)! important",
                                cursor: "pointer"
                            }
                        }} onClick={() => { return handleRoute() }} style={{ marginLeft: "25px", color: "white", verticalAlign: "middle", }} />
                        <LanguageIcon sx={{
                            "&:hover": {
                                color: "rgb(62, 158, 214) ! important",
                                cursor: "pointer"
                            }
                        }} onClick={() => { return handleRoute() }} style={{ marginLeft: "25px", color: "white", verticalAlign: "middle", }} />


                    </VoteRateButton1>
                </TextInnerContainer>

            </TextContainer>
            <ButtonContainer>
                <ButtonInnerContainer>
                    <BlockMerge>
                        <VoteRateButton>
                            <VoteText2>Donated Nft</VoteText2>
                        </VoteRateButton>
                        <VoteText>
                            Fans Donation History
                        </VoteText>
                    </BlockMerge>
                    <SearchProject projectList={fan.fanIni} dispatch={dispatch} from="fanproject" />
                </ButtonInnerContainer>

            </ButtonContainer>
            <CardContainer1>
                <CardInnerContainer1>
                    {ploading ?
                        <div style={{ display: 'flex', width: "100%", justifyContent: "center", marginTop: "20px" }}>
                            <Spin />
                        </div> :
                        <CardProjectDisplay>

                            {

                                fan?.fanListArr && fan?.fanListArr.map((item, i) => {
                                    return (






                                        <ProjectCard key={i + 1}>
                                            <ProjectCardImage onClick={(e) => { navigate(`#`) }} src={item.image_url} />
                                            <InnerCardFullContain>
                                                <InnerCardContainer>
                                                    <CardContainerText>
                                                        {item.name}
                                                    </CardContainerText>
                                                </InnerCardContainer>
                                            </InnerCardFullContain>
                                            <CardFooterContainer>

                                                <RightCombine>
                                                    <FooterText>Wallet_id</FooterText>
                                                    <CardContainerText1>{item.wallet_id?.slice(0, 14) + "..."}</CardContainerText1>

                                                </RightCombine>
                                                <RightCombine>
                                                    <FooterText>Transaction</FooterText>
                                                    <CardContainerText1>{`$${item.amount}`}</CardContainerText1>

                                                </RightCombine>
                                                <RightCombine>
                                                    <FooterText>Tips</FooterText>
                                                    <CardContainerText1>{item.Tips}</CardContainerText1>

                                                </RightCombine>
                                                <RightCombine>
                                                    <FooterText>Date</FooterText>
                                                    <CardContainerText1>{item.updated_at.slice(0, 10)}</CardContainerText1>

                                                </RightCombine>
                                              

                                            </CardFooterContainer>
                                        </ProjectCard>
                                    )
                                }
                                )}


                        </CardProjectDisplay>
                    }
                    <SpinContainer>
                        {loading && <Spin />}
                    </SpinContainer>
                </CardInnerContainer1>

            </CardContainer1>
            {/* <ProjectFooterContainer>
                <ProjectFooterInner>
                    <CardProjectDisplay>
                        {CardProjectData.map((item) => {
                            return (


                                <ProjectCard>
                                    <ProjectCardImage src={ProjectCard1} />
                                    <InnerCardFullContain>
                                        <InnerCardContainer>
                                            <CardContainerText>
                                                Property Heist Ape Club
                                            </CardContainerText>
                                        </InnerCardContainer>
                                    </InnerCardFullContain>
                                    <CardFooterContiner>
                                        <FooterText>Price</FooterText>
                                        <RightContent>
                                            <RightCombine>
                                                <img style={{ width: "25.8px", height: "20.08px", marginRight: "5px", verticalAlign: "middle" }} src={SalesImg} />
                                                <CardContainerText1>{item.rating}</CardContainerText1>
                                            </RightCombine>
                                            <CardContainerText2>{item.rate}</CardContainerText2>
                                        </RightContent>
                                    </CardFooterContiner>
                                </ProjectCard>
                            )
                        })}
                    </CardProjectDisplay>
                </ProjectFooterInner>
            </ProjectFooterContainer> */}
            <ProjectFooterContainer>
                <ProjectFooterInner>



                    <ViewButton>
                        <ViewButtonText onClick={ViewMore}
                        >View more</ViewButtonText>
                        <img src={ViewMoreImg} />
                    </ViewButton>

                </ProjectFooterInner>
            </ProjectFooterContainer>
        </ProjectContainer>
    )
}


export default ProjectPage1