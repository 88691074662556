import styled from "styled-components";

export const ImageContainer = styled.div` 
width:100% ;
height:auto ;
display:flex ;
background-color: rgb(10, 25, 41) ;
justify-content:center ;
padding-top:75px ;
`
export const ImageInnerContainer = styled.div` 
width:90% ;
height:auto ;
display:flex ;
padding-left:5.4% ;
`
export const ContainerImage = styled.img` 
width:50.8% ;
height:767px ;
border-radius: 16px;
vertical-align:middle ;
margin-right:30px ;
//margin-left:20px ;
margin-top:40px ;
margin-bottom:50px ;
position: relative;
`
export const RightContainer = styled.div` 
width:60% ;
height:auto ;
margin-top:40px ;
margin-bottom:50px ;
border:none ;
padding-left:5% ;
`
export const AmazingText = styled.button` 
  //margin-bottom: 8px;
  font-size: 40px;
  line-height: 1.2;
  border:none;
  outline:none ;
background-color: rgb(10, 25, 41) ;
color:white ;
  cursor: pointer;
  letter-spacing: -.01em;
  font-family: 'DM Sans', Sofia Pro;
  font-weight: 700;
`
export const ContainerButton = styled.div` 
display:flex;
align-items:center ;
width:100% ;
margin-bottom: 25px;
margin-top:8px ;
`
export const EthButton = styled.div` 
margin-right: 3%;
border-color:rgb(66 107 80) ;
  color: rgb(66 107 80);
  background-color:rgb(0,30,60) ;
  border:1.5px solid ;
  height:35px ;
  border-style:dashed ;
align-items:center ;
  display: flex;
  padding: 0 8px;
  width:30% ;
  border-radius: 8px;
  font-family: 'DM Sans', Sofia Pro;
  font-size: 16px;
  line-height: 30px;
`
export const EthButton1 = styled.button` 
margin-right: 3%;
  color: rgb(51,153,255);
  background-color:rgb(0,30,60) ;
  display: flex;
  padding: 0 8px;
  width:30% ;
  height:35px ;
  align-items:center ;
  border:1.5px solid ;
border-color:rgb(32,129,226) ;
  border-radius:8px;
  font-family: 'DM Sans', Sofia Pro;
  font-size: 16px;
  border-style:dashed ;
  line-height: 30px;
`
export const DollerButton = styled.button` 
border:none ;
font-weight: 500;
  letter-spacing: -.01em;
  color: rgb(88, 102, 242);
  font-family: 'DM Sans', Sofia Pro;
  font-size: 16px;
  background-color:rgb(0,30,60) ;
  margin-right: 3%;
  width:25% ;
align-items:center ;
border-color:rgb(88, 102, 242) ;
  height:35px ;
  line-height: 30px;
  display: flex;
  padding: 0 8px;
  border-radius: 8px;
  border:1.5px solid ;
 border-style:dashed ;
`
export const StockText = styled.div`  
font-family: 'DM Sans', Sofia Pro;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  color: #777E90;
  vertical-align: middle;
`
export const NftCardText1 = styled.button` 
  font-size: 17px;
  line-height: 1.5;
  font-weight:400 ;
  border:none ;
  background-color: rgb(10, 25, 41) ;

  outline:none ;
  cursor: pointer;
  font-family: Poppins, Sofia Pro;
  color: #777E90;
  margin-right:29.3% ;
  &:hover{
    color: ${props => props.theme.mi_primary};
  }
`
export const NftCardText = styled.div` 
  font-size: 17px;
  line-height: 1.5;
  font-weight:400 ;
  font-family: Poppins, Sofia Pro;
  color: #777E90;
  margin-right:29.3% ;
`
export const UrlText = styled.nav` 
text-decoration: underline;
  font-weight: 500;
  font-size: 17px;
cursor: pointer;
  color: white;
margin-bottom: 40px;
:hover{
  color: rgb(62, 158, 214) ;

}
`
export const ButtonBox = styled.div` 
  display: flex;
  margin-bottom: 20px;
  padding: 9px;
  border-radius: 25px;
  border-color:#009BEF ;
 // box-shadow: inset 0 0 0 2px #009BEF ;
  border-style:dashed ;
;
  height:50px ;
  display:flex ;
  align-items:center ;
  box-sizing:border-box ;
  padding:6px ;
  width:100% ;
`
export const InfoButton = styled.button` 
  background: ${props => props.theme.secondary};
  color: #FCFCFD;
  //margin-right: 8px;
  padding: 6px 12px;
  width:27% ;
  border-top-color:#009BEF ;
  border-bottom-color:#009BEF ;
border-left-color: #009BEF ;
border-right-color: #009BEF ;
  height:fit-content ;
  outline:0px ;
  border-radius: 28px;
  border-width:thin ;
  font-family: 'DM Sans', Sofia Pro;
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
  transition: all .2s;
`
export const PersonImage = styled.img` 
width:45px ;
height:45px ;
margin-top:2px ;
`
export const OwnerButton = styled.button` 
padding: 6px 12px;
  border-radius: 28px;
  background: none;
  width:27% ;
  font-family: 'DM Sans', Sofia Pro;
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
  color: #777E90;
  transition: all .2s;
  border:none;
`
export const OwnerImgContainer1 = styled.div` 
width:100% ;
height:auto ;
display:flex ;
align-items:center ;
padding-top:10px ;
border-radius:15px ;
background-color: rgb(19, 47, 76) ;
justify-content:space-between ;
padding-bottom:10px ;
border: 1px solid rgb(80, 144, 211);
margin-bottom:20px ;
padding-left:15px ;
padding-right:15px ;
`
export const OwnerImgContainer2 = styled.div` 
width:100% ;
height:150px;
display:flex ;
align-items:center ;
justify-content:center ;
padding-bottom:16px ;
border-bottom: 1px solid ;
margin-bottom:20px ;
`
export const OwnerImgContainer3 = styled.div` 
width:100% ;
height:150px ;
display:flex ;
align-items:center ;
justify-content:center ;
padding-bottom:16px ;
margin-bottom:20px ;
margin-top:40px ;

`
export const OwnerImgContainer = styled.div` 
width:100% ;
height:auto ;
display:flex ;
align-items:center ;
padding-bottom:16px ;
border-bottom: 1px solid #E6E8EC;
margin-bottom:20px ;
`
export const OwnerImage = styled.img` 
width:48px ;
height:48px ;
background-color: rgb(10, 25, 41) ;

vertical-align: middle;
position: relative;
`
export const RewardImg = styled.img` 
width:25px;
height:28px ;
position:absolute ;
vertical-align: middle;
margin-left:25px ;
margin-top:25px ;
`
export const OwnerBlock = styled.div` 
display:block ;
margin-left:10px;
`
export const OwnerText = styled.div` 
  color: white;
  font-size:15px ;
  font-family:Sofia Pro ;
  margin-bottom:5px ;
`
export const OwnerDownText = styled.div` 
font-family: 'Poppins', Sofia Pro;
font-size:15px ;
  line-height: 1.71429;
  color: #777E90;

  font-weight: 600;
`
export const OwnerDownText2 = styled.div` 
font-family: 'Poppins', Sofia Pro;
font-size:17px ;
  line-height: 1.71429;
  color: #777E90;
  text-align:center;
  font-weight: 600;
`
export const OverFlowContain = styled.div` 
height:140px;
-ms-overflow-style: none;
scrollbar-width: none;
overflow-y: scroll;
display:flex ;
align-items:center ;
flex-direction:column ;


`
export const OwnerLastText = styled.div` 
font-family: 'Poppins', Sofia Pro;
  font-size: 14px;
  line-height: 1.71429;
  color: ${props => props.theme.mi_primary};
display:flex ;
margin-top:25px ;
  font-weight: 600;
`
export const OwnerContent = styled.div` 
display:flex ;
`
export const ImageFooterBox = styled.div` 
margin-top: 20px;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0px 64px 64px -24px rgba(31, 47, 70, 0.12);
  border: 2px solid rgb(30, 73, 118);
  border-style:dashed ;
  height:fit-content ;
  width:100%;
  box-sizing:border-box ;
`
export const FooterBoxHeader = styled.div` 
display:flex ;
margin-bottom:25px ;
justify-content:center ;
`
export const FooterBoxBlock = styled.div`
display:flex;
flex:1;
flex-direction:column;
//margin-left:10px ;
`
export const FooterFlex = styled.div` 
display:flex ;
flex:1;

width:100%;
`
export const FooterHeadText = styled.div` 
  font-size: 17px;
  line-height: 1.5;
  font-weight: 500;
  color: #777E90;
`
export const FooterHeadText2 = styled.div` 
  font-size: 17px;
  line-height: 1.5;
  font-weight: 500;
  color: #23262F;
  margin-left:5px ;
`
export const FooterBottomText = styled.div` 
  font-size: 25px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -.01em;
  margin-right: 12px;
  color: white;
`
export const FooterBottomText2 = styled.div` 
 font-size: 25px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -.01em;
  margin-right: 12px;
  color: #777E90;
 
`
export const FooterBoxMiddle = styled.button` 
margin: 0 4px;
  padding: 0 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  margin-top:5px ;
  background: #3772FF;
  border-radius: 24px;
  font-family: 'DM Sans', Sofia Pro;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  color: #FCFCFD;
  transition: all .2s;
  border:none ;
  width:50% ;
`
export const PlaceButton = styled.button` 
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  border-radius: 24px;
  font-family: 'DM Sans', Sofia Pro;
  font-size: 16px;
  font-weight: 700;
  margin-top:5px ;

  line-height: 1;
  text-align: center;
  transition: all .2s;
  border:none ;
  width:50% ;
`
export const BoxBottom = styled.div` 
font-weight: 400;
  color: #777E90;
  font-size:14px ;
  font-family:Sofia Pro ;
  letter-spacing:0.9px ;
  margin-right:10px ;
`
export const BoxBottomText = styled.div` 
  color: #23262F;
  font-weight:400 ;
  margin-right:10px ;
`
export const MainImgBox = styled.div` 
display: flex;
  padding: 8px;
  border-radius: 32px;
  background: #FCFCFD;
  position:absolute ;
`
export const InputBox = styled.input` 
width: 100%;
height:54px ;
border-radius:30px ;
margin-right:10px ;
background-color: rgb(0,30,60) ;
color:white;
outline:none ;
justify-content:flex-start;
//border:1px solid #353945;
border:none ;
padding-left:15px;
`

export const Selectable = styled.div`
width: 100%;
height:54px ;
border-radius:30px ;
margin-right:10px ;
background-color: rgb(0,30,60) ;
color:white;
margin-bottom:15px;
display:flex;
justify-content:center;
align-items:center;
cursor:pointer;
`

export const BoxInput = styled.div` 
height:54px ;
width:42% ;
border-radius:5px ;
background: #353945;
color:white ;
`
export const H1Tag = styled.div` 
color:white;
display:flex ;
margin-left:28% ;
font-size:20px ;
margin-top:7% ;
`