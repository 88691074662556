import React from 'react'
import { Modal, ModalBody } from "react-bootstrap"
import { HeaderFlex, HeaderText, HeaderText1, ModalFooter, TipButton, TipButton1 } from './Modal/index.styled'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import VerifiedIcon from '@mui/icons-material/Verified';


function ModelBox({ show, handleClosed, handleFunction,modalbody }) {
    const handleChange = () => {
        handleClosed()
    }
  
    const HandleButton = () => {
        handleFunction()
        handleClosed()
    }

    return (
        <Modal show={show} onHide={handleChange} centered  >
            <HeaderFlex>
                <HeaderText>{modalbody?.title}</HeaderText>
                {modalbody?.Icon}
            </HeaderFlex>
            <ModalBody>
                <HeaderText1>{modalbody?.content}</HeaderText1>
            </ModalBody>
            <ModalFooter>
                <TipButton variant="secondary" onClick={handleChange}>
                    Close
                </TipButton>
                <TipButton1 variant="primary" onClick={HandleButton}>
                   {modalbody?.footerbtnText}
                </TipButton1>
            </ModalFooter>
        </Modal>
    )
}

export default ModelBox
