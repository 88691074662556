import { UserOutlined } from '@ant-design/icons'
import React, { useEffect } from 'react'
import { CardImageName, CardNameText } from '../RankingPage/index.styled'
import { CardImageName1, CardProjectDisplay1, CardText1, CardText2, CardText3, CollectionTextBox, ResponsiveContainer, TableCard, TableFlex, TableFlex1 } from './index.styled'
import BlueVerified from '../../../Static/Image/BlueVerified.png'
import RankingData from '../../../utils/RankingData.json'

function ResponsivePage() {
    const [projectList, setProjectList] = React.useState([])

    useEffect(() => {
        if (RankingData.length > 0) {
            return (
                setProjectList(RankingData)
            )
        }
        else {
            return setProjectList([])
        }
    }, [RankingData])
    return (
        <CardProjectDisplay1>
            {RankingData.map((item, i) => {
                return (
                    <ResponsiveContainer key={i + 1}>
                        <TableCard>
                            <TableFlex>
                                <UserOutlined style={{ fontSize: "24px", marginRight: "4%", marginLeft: "3%", marginTop: "1%", color: "white" }} />
                                <CardText1>{item.name}</CardText1>
                                <CardImageName1 src={BlueVerified} />

                            </TableFlex>
                            <TableFlex1>
                                <CollectionTextBox>
                                    <CardText2>Collections</CardText2>
                                    <CardText3>{item.Floor}</CardText3>

                                </CollectionTextBox>
                                <CollectionTextBox>
                                    <CardText2>TipsCount</CardText2>
                                    <CardText3>{item.Nftcount}</CardText3>

                                </CollectionTextBox>
                            </TableFlex1>
                            <TableFlex1>
                                <CollectionTextBox>
                                    <CardText2>NFTs</CardText2>
                                    <CardText3>{item.FansCount}</CardText3>
                                </CollectionTextBox>
                                <CollectionTextBox>
                                    <CardText2>Tips Total Value</CardText2>
                                    <CardText3>{item.fan}</CardText3>
                                </CollectionTextBox>
                            </TableFlex1>
                        </TableCard>
                    </ResponsiveContainer>
                )
            })}
        </CardProjectDisplay1>
    )
}

export default ResponsivePage