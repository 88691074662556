import React, { useState, useEffect } from 'react'
import { Spin } from 'antd';
import 'antd/es/spin/style/css';
import { BodyButton, BodyButtonText, BodyButtonText2, BodyContainer, BodyFooterContainer, BodyFooterText, BodyHeader, BodyText, Box, ButtonContainer, ButtonFlex, ButtonMergeContainer, CalenderImage, Card, CardDisplay, CardImage, CardMiddle, CardMiddleText, CardtextHead, CombineContent, CombineFlex, MiddleCardContainer, MiddleCardContainer2, MiddleContainer, MiddleInnerContain, MiddleInnerContain2, Rating, Text, TwitterButton, ViewButton, ViewButtonText, ViewsButton, WebsiteButton, WebsiteText, NameText1, TextHead1, TextHeadFloor1, CardIcon1, CardName1, CardButtonLaunched1, VolumeContainer1, FloorContainer1, FanText, SpinContainer, BackButton, BodyHeader1, NameText2, FanText2, SerialNo1, BodyFooterContainer1, Nametext, NameText4, FanText4, HashText2, Nametext1, Nametext3, Fantext3, TextHeadFloor2, Nametext2, CardName2, CardIcon2, CardButtonLaunched3, Nametext5, Nametext6, CardName4 } from '../LandingPage/index.styled'
import { useNavigate } from 'react-router-dom'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import makeGetRequest from '../../../api/GetApi';
import { useAppContext } from '../../../context/AppContext';
import CardImg from '../../../Static/Image/card1.jpg'
import CalenderImg from '../../../Static/Image/calender.svg'
import BirdImg from '../../../Static/Image/bird1.svg'
import { HeartFilled, LeftOutlined } from '@ant-design/icons';
import Buttom2Img from '../../../Static/Image/button2.svg'
import Buttom3Img from '../../../Static/Image/button3.svg'
import UpcomingData from '../../../utils/UpcomingData.json'
import ViewMoreImg from '../../../Static/Image/viewMore.svg'
import UpcomingTomorrowData from '../../../utils/UpcomingTomorrowData.json'
import SearchBar from "../LandingPage/Search";
import { DataGridComponent } from '../LandingPage/DataGrid'
import { BoxMiddle, CardContainer, DropDown, HashText, HeaderMiddleText, InnerTableHead, NameText, RankingBodyButton, RankingBodyButtonText, RankingBodyButtonText2, RankingContainer, RankingHeader, RankingImage, RankingMiddleContainer, SerialNo, TableContent, TableHead, TextHead, TextHeadFloor, TextHeadVotes, CardIcon, CardName, CardNameText, CardImageName, CardButtonLaunched, VolumeContainer, VolumeContent, FloorContainer, TwitterContainer, DisacardContainer, DiscardContainer, VoteButton, VotesContainer, TableContainer, HashText1, } from '../RankingPage/index.styled'
import BlueVerified from '../../../Static/Image/BlueVerified.png'
import RankingData1 from '../../../utils/RankingData1.json'
import RedDropDown from '../../../Static/Image/DownDropdown.svg'
import { Tab } from '@material-ui/core'
import { FilterComponent } from "../FilterComponent/index"
import { TopEarnFilter } from '../../../utils/FilterData';
import SearchBar1 from './Search1';

function TopEarnedPage() {
  const [cardIncrement, setCardIncrement] = useState(9)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState()
  const [searchList, setSearchList] = useState(false)
  const [projectList, setProjectList] = React.useState([])
  const [ploading, setpLoading] = useState(false)
  const [checkButton, setCheckButton] = useState('monday')
  const { State, dispatch } = useAppContext()

  const { assets } = State
  const { tip } = State
  const navigate = useNavigate()

  const ViewMore = () => {
    setLoading(true)

    setTimeout(() => {
      setLoading(false)
      setCardIncrement(cardIncrement + 10)
      window.scrollBy(0, -350);


    }, 1000)
  }
  const projectCalculation = async (project) => {
    const Tips = await makeGetRequest('get/tip')
    let TipList = []
    // let tipsIds = Tips.tip.map((item) => {
    //   return item.id
    // })


    // const filteredArray = project.filter(value => {

    //   if (tipsIds.includes(value.id)) {
    //     return value
    //   }

    // })
    Tips.tip.map(async (item) => {
      const ProjectAssets = await makeGetRequest(`get/assets/${item.project_id}`)
      let particulartips = ProjectAssets?.assets?.find((items) => { return items.id === item.asset_id })
      let a = {}
      a = { ...item }
      console.log(particulartips)
      a["projectname"] = particulartips.name
      a["id"] = particulartips.id
      a["asset_contract"] = particulartips.asset_contract
      a["image_url"] = particulartips.image_url
      a["descripition"] = particulartips.descripition
      a["url"] = particulartips.url
      TipList.push(a)
      setpLoading(false)
      console.log(TipList, "gfgfgnfvgnfnfjhnfgjhgf")
      dispatch({ type: "INITIAL_TIP", payload: TipList })
    })
    return TipList
  }
  const getApi = async () => {
    setpLoading(true)
    const Tips = await makeGetRequest('get/tip')

    // const result = await makeGetRequest('get/project')
    console.log(Tips)
    if (Tips.tip.length > 0) {
      dispatch({ type: "INITIAL_TIP", payload: Tips.tip })
      setpLoading(false)
    }
    else {
      setpLoading(false)
    }
    // if (result.project.length > 0) {
    //   await projectCalculation(result.project)
    // }
  }
  useEffect(() => {

    getApi()
  }, [])

  const handleAscendingOrder = (values) => {
    if (values === "Assets") {
      setpLoading(true)
      if (assets.assetsArr.length > 0) {
        let totalProject = assets.assetsArr.sort((a, b) => {
          const name1 = a.name.toUpperCase();
          const name2 = b.name.toUpperCase();
          let comparison = 0;
          if (name1 > name2) {
            comparison = 1;
          } else if (name1 < name2) {
            comparison = -1;
          }
          return comparison;
        })
        setTimeout(() => {
          setpLoading(false)
        }, 500)
        dispatch({ type: "CHANGE_ASSETS", payload: totalProject })

      }
    }
    else if (values === "Amount") {
      setpLoading(true)
      if (assets.assetsArr.length > 0) {
        let TotalNft = assets.assetsArr.sort((a, b) => {
          return a.Nftcount - b.Nftcount
        })
        setTimeout(() => {
          setpLoading(false)
        }, 500)
        dispatch({ type: "CHANGE_ASSETS", payload: TotalNft })


      }
    }
    else if (values === "Value") {
      setpLoading(true)
      if (assets.assetsArr.length > 0) {
        let TotalFan = assets.assetsArr.sort((a, b) => {
          return a.FansCount - b.FansCount
        })
        setTimeout(() => {
          setpLoading(false)
        }, 500)
        dispatch({ type: "CHANGE_ASSETS", payload: TotalFan })


      }
    }
    else if (values === "Fans") {
      setpLoading(true)
      if (assets.assetsArr.length > 0) {
        let TotalDonation = assets.assetsArr.sort((a, b) => {
          return a.number - b.number
        })
        setTimeout(() => {
          setpLoading(false)
        }, 500)
        dispatch({ type: "CHANGE_ASSETS", payload: TotalDonation })


      }
    }
    else if (values === "NFTs") {
      setpLoading(true)
      if (assets.assetsArr.length > 0) {
        let TotalTippedNfts = assets.assetsArr.sort((a, b) => {
          return a.number - b.number
        })
        setTimeout(() => {
          setpLoading(false)
        }, 500)
        dispatch({ type: "CHANGE_ASSETS", payload: TotalTippedNfts })


      }
    }
    else if (values === "Collections") {
      setpLoading(true)
      if (assets.assetsArr.length > 0) {
        let TotalTippedNfts = assets.assetsArr.sort((a, b) => {
          return a.number - b.number
        })
        setTimeout(() => {
          setpLoading(false)
        }, 500)
        dispatch({ type: "CHANGE_ASSETS", payload: TotalTippedNfts })


      }
    }
    else if (values === "Fees") {
      setpLoading(true)
      if (assets.assetsArr.length > 0) {
        let TotalTippedNfts = assets.assetsArr.sort((a, b) => {
          return Number((a.Floor).replace(/[^0-9.-]+/g, "")) - Number((b.Floor).replace(/[^0-9.-]+/g, ""))

        })
        setTimeout(() => {
          setpLoading(false)
        }, 500)
        dispatch({ type: "CHANGE_ASSETS", payload: TotalTippedNfts })

      }
    }

  }

  const handleDesendingOrder = (values) => {
    if (values === "Assets") {
      if (values === "Assets") {
        setpLoading(true)
        if (assets.assetsArr.length > 0) {
          let totalProject = assets.assetsArr.sort((a, b) => {
            if (a.name > b.name) {
              return -1
            }
            if (b.name < a.name) {
              return 1
            }
            return 0
          })
          setTimeout(() => {
            setpLoading(false)
          }, 500)
          dispatch({ type: "CHANGE_ASSETS", payload: totalProject })

        }
      }
    }
    else if (values === "Amount") {
      setpLoading(true)
      if (assets.assetsArr.length > 0) {
        let TotalNft = assets.assetsArr.sort((a, b) => {
          return b.Nftcount - a.Nftcount
        })
        setTimeout(() => {
          setpLoading(false)
        }, 500)
        dispatch({ type: "CHANGE_ASSETS", payload: TotalNft })

      }
    }
    else if (values === "Value") {
      setpLoading(true)
      if (assets.assetsArr.length > 0) {
        let TotalFan = assets.assetsArr.sort((a, b) => {
          return b.FansCount - a.FansCount
        })
        setTimeout(() => {
          setpLoading(false)
        }, 500)
        dispatch({ type: "CHANGE_ASSETS", payload: TotalFan })


      }
    }
    else if (values === "Fans") {
      setpLoading(true)
      if (assets.assetsArr.length > 0) {
        let TotalDonation = assets.assetsArr.sort((a, b) => {
          return b.number - a.number
        })
        setTimeout(() => {
          setpLoading(false)
        }, 500)
        dispatch({ type: "CHANGE_ASSETS", payload: TotalDonation })


      }
    }
    else if (values === "NFTs") {
      setpLoading(true)
      if (assets.assetsArr.length > 0) {
        let TotalTippedNfts = assets.assetsArr.sort((a, b) => {
          return b.number - a.number
        })
        setTimeout(() => {
          setpLoading(false)
        }, 500)
        dispatch({ type: "CHANGE_ASSETS", payload: TotalTippedNfts })


      }
    }
    else if (values === "Collections") {
      setpLoading(true)
      if (assets.assetsArr.length > 0) {
        let TotalTippedNfts = assets.assetsArr.sort((a, b) => {
          return b.number - a.number
        })
        setTimeout(() => {
          setpLoading(false)
        }, 500)
        dispatch({ type: "CHANGE_ASSETS", payload: TotalTippedNfts })


      }
    }
    else if (values === "Fees") {
      setpLoading(true)
      if (assets.assetsArr.length > 0) {
        let TotalTippedNfts = assets.assetsArr.sort((a, b) => {
          return Number((b.Floor).replace(/[^0-9.-]+/g, "")) - Number((a.Floor).replace(/[^0-9.-]+/g, ""))

        })
        setTimeout(() => {
          setpLoading(false)
        }, 500)
        dispatch({ type: "CHANGE_ASSETS", payload: TotalTippedNfts })


      }
    }
  }

  const handleAscChange = (e) => {
    handleAscendingOrder(e)

  }
  const handleDescChange = (e) => {
    handleDesendingOrder(e)
  }
  const handleRoute = (value) => {
    navigate(`/assets/${value.id}`, { state: value })
  }
  return (
    <BodyContainer>
      <MiddleContainer>

        <BodyHeader1>Tips</BodyHeader1>
        <BodyText>
          Discover the hottest nft projects right now!
        </BodyText>
        <SearchBar1 setSearchList={setSearchList} setProjectList={setProjectList} setpLoading={setpLoading} />
        {/* <Box>
          <BodyButton>
            <BodyButtonText onClick={() => setCheckButton('monday')}>Today</BodyButtonText>
            <BodyButtonText2 onClick={() => setCheckButton('tomorrow')}>Tomorrow</BodyButtonText2>
            <BodyButtonText2>7 Days</BodyButtonText2>
            <BodyButtonText2>30 Days</BodyButtonText2>
          </BodyButton>
        </Box> */}
        {/* <DataGridComponent /> */}
        <br />
        <TableContainer>
          <TableHead>
            <InnerTableHead>
              <HashText2>#</HashText2>
              <NameText1>Assets <ArrowUpwardIcon style={{ color: "#94A1B7", fontSize: "15px", top: "4px", left: "5px", position: "relative", cursor: "pointer", right: "5px" }} onClick={(e) => { return handleAscChange("Assets") }} /> <ArrowDownwardIcon style={{ color: "#94A1B7", top: "4px", fontSize: "15px", position: "relative", left: "5px", cursor: "pointer", right: "5px" }} onClick={() => { return handleDescChange('Assets') }} /> </NameText1>
              <Nametext2>Amount <ArrowUpwardIcon style={{ color: "#94A1B7", fontSize: "15px", top: "4px", left: "5px", position: "relative", cursor: "pointer", right: "5px" }} onClick={(e) => { return handleAscChange("Amount") }} /> <ArrowDownwardIcon style={{ color: "#94A1B7", top: "4px", fontSize: "15px", position: "relative", left: "5px", cursor: "pointer", right: "5px" }} onClick={() => { return handleDescChange('Amount') }} /></Nametext2>

              <Nametext2>Value<ArrowUpwardIcon style={{ color: "#94A1B7", fontSize: "15px", top: "4px", left: "5px", position: "relative", cursor: "pointer", right: "5px" }} onClick={(e) => { return handleAscChange("Value") }} /><ArrowDownwardIcon style={{ color: "#94A1B7", top: "4px", fontSize: "15px", position: "relative", left: "5px", cursor: "pointer", right: "5px" }} onClick={() => { return handleDescChange('Value') }} /></Nametext2>
              <Nametext2> Fans <ArrowUpwardIcon style={{ color: "#94A1B7", fontSize: "15px", top: "4px", left: "5px", position: "relative", cursor: "pointer", right: "5px" }} onClick={(e) => { return handleAscChange("Fans") }} /><ArrowDownwardIcon style={{ color: "#94A1B7", top: "4px", fontSize: "15px", position: "relative", left: "5px", cursor: "pointer", right: "5px" }} onClick={() => { return handleDescChange('Fans') }} /></Nametext2>
              <Nametext5>NFTs <ArrowUpwardIcon style={{ color: "#94A1B7", fontSize: "15px", top: "4px", left: "5px", position: "relative", cursor: "pointer", right: "5px" }} onClick={(e) => { return handleAscChange("NFTs") }} /><ArrowDownwardIcon style={{ color: "#94A1B7", top: "4px", fontSize: "15px", position: "relative", left: "5px", cursor: "pointer", right: "5px" }} onClick={() => { return handleDescChange('NFTs') }} /></Nametext5>
              <Nametext6>Collections <ArrowUpwardIcon style={{ color: "#94A1B7", fontSize: "15px", top: "4px", left: "5px", position: "relative", cursor: "pointer", right: "5px" }} onClick={(e) => { return handleAscChange("Collections") }} /><ArrowDownwardIcon style={{ color: "#94A1B7", top: "4px", fontSize: "15px", position: "relative", left: "5px", cursor: "pointer", right: "5px" }} onClick={() => { return handleDescChange('Collections') }} /></Nametext6>
              <Nametext2>Fees <ArrowUpwardIcon style={{ color: "#94A1B7", fontSize: "15px", top: "4px", left: "5px", position: "relative", cursor: "pointer", right: "5px" }} onClick={(e) => { return handleAscChange("Fees") }} /><ArrowDownwardIcon style={{ color: "#94A1B7", fontSize: "15px", top: "4px", position: "relative", left: "5px", cursor: "pointer", right: "5px" }} onClick={() => { return handleDescChange('Fees') }} /></Nametext2>

              {/* <TextHead1>Size</TextHead1> */}
              {/* <TextHead>Twitter</TextHead>
            <TextHead>Discard</TextHead>
            <TextHeadVotes>Votes</TextHeadVotes>
            <TextHead>Vote</TextHead> */}
            </InnerTableHead>
          </TableHead>
          {console.log(tip.TipArr)}
          {ploading ?
            <div style={{ display: 'flex', flex: 1, justifyContent: "center", alignItems: "center", padding: "20px" }}>
              <Spin />

            </div> : tip.TipArr ? tip.TipArr.map((item, i) => {
              return (
                <TableContent key={i + 1} onClick={() => { return handleRoute(item) }}>
                  <SerialNo1>{i + 1}</SerialNo1>
                  {/* <DropDown src={RedDropDown} /> */}
                  <CardIcon2 src={item.asset.image_url} />
                  <CardName4>
                    <CardNameText >{item.asset.name}</CardNameText>
                    <CardImageName src={BlueVerified} />
                  </CardName4>
                  <CardButtonLaunched3>
                    {/* {item.Nftcount} */}
                  </CardButtonLaunched3>
                  <CardButtonLaunched3>
                    <VolumeContent>
                      {/* {item.FansCount} */}
                    </VolumeContent>
                  </CardButtonLaunched3>
                  <CardButtonLaunched3>
                    <VolumeContent>
                      {/* {item.number} */}
                    </VolumeContent>
                  </CardButtonLaunched3>
                  <CardButtonLaunched3>
                    <VolumeContent>
                      {/* {item.number} */}
                    </VolumeContent>
                  </CardButtonLaunched3>
                  <CardButtonLaunched3>
                    <VolumeContent>
                      {/* {item.number} */}
                    </VolumeContent>
                  </CardButtonLaunched3>
                  <CardButtonLaunched3>
                    <VolumeContent>
                      {/* {item.Floor} */}
                    </VolumeContent>
                  </CardButtonLaunched3>
                </TableContent>
              )
            }) :
              projectList !== undefined && projectList.length > 0 ? projectList.map((item, i) => {
                if (i <= cardIncrement) {
                  return (
                    <TableContent key={i + 1} href={"#"}>
                      <SerialNo1>{i + 1}</SerialNo1>
                      {/* <DropDown src={RedDropDown} /> */}
                      <CardIcon2 src={require(`../../../Static/Image/${item.img}`)} />
                      <CardName2>
                        <CardNameText >{item.name}</CardNameText>
                        <CardImageName src={BlueVerified} />
                      </CardName2>
                      <CardButtonLaunched3>
                        <VolumeContent>{item.Nftcount}</VolumeContent>
                      </CardButtonLaunched3>
                      <CardButtonLaunched3>
                        <VolumeContent>{item.FansCount}</VolumeContent>
                      </CardButtonLaunched3>
                      <CardButtonLaunched3>
                        <VolumeContent>{item.number}</VolumeContent>
                      </CardButtonLaunched3>
                      <CardButtonLaunched3>
                        <VolumeContent>{item.number}</VolumeContent>
                      </CardButtonLaunched3>
                      <CardButtonLaunched3>
                        <VolumeContent>{item.number}</VolumeContent>
                      </CardButtonLaunched3>
                      <CardButtonLaunched3>
                        <VolumeContent>{item.Floor}</VolumeContent>
                      </CardButtonLaunched3>
                    </TableContent>
                  )
                }
              })
                : null}
        </TableContainer>
        <SpinContainer>
          {loading && <Spin />}
        </SpinContainer>
        {/* <CardDisplay>
          {checkButton == 'monday' &&
            UpcomingData.map((item, i) => {
              if (i <= cardIncrement) {


                return (

                  <Card>
                    <CardImage src={require(`../../../Static/Image/${item.img}`)} />
                    <MiddleCardContainer>
                      <MiddleInnerContain>
                        <CardtextHead>{item.title}</CardtextHead>
                        <CardMiddle>
                          <CalenderImage src={CalenderImg} />
                          <CardMiddleText>Add to Calendar</CardMiddleText>
                        </CardMiddle>
                      </MiddleInnerContain>
                    </MiddleCardContainer>
                    <MiddleCardContainer2>
                      <MiddleInnerContain2>
                        <CombineFlex>
                          <CombineContent>
                            <Text>Mint price</Text>
                            <Rating>{item.price}</Rating>
                          </CombineContent>
                          <CombineContent>
                            <Text>Collection size</Text>
                            <Rating>{item.size}</Rating>
                          </CombineContent>
                          <CombineContent>
                            <Text>Launch</Text>
                            <Rating>Mar 10, 2022</Rating>
                          </CombineContent>
                        </CombineFlex>

                      </MiddleInnerContain2>
                    </MiddleCardContainer2>

                    <ButtonMergeContainer>
                      <ButtonFlex>
                        <ButtonContainer>
                          <TwitterButton color='#009BEF'>
                            <img src={BirdImg} style={{ verticalAlign: "middle" }} />
                          </TwitterButton >
                          <ViewsButton background='rgb(30,38,52)' color='#009BEF'>
                            {item.button1}
                          </ViewsButton>
                        </ButtonContainer>

                        <ButtonContainer>
                          <TwitterButton color='#5865F2'>
                            <img src={Buttom2Img} style={{ verticalAlign: "middle" }} />
                          </TwitterButton >
                          <ViewsButton background='rgb(30,38,52)' color='#5865F2'>
                            {item.button2}

                          </ViewsButton>
                        </ButtonContainer>
                      </ButtonFlex>

                      <ButtonFlex>
                        <WebsiteButton>
                          <img src={Buttom3Img} style={{ verticalAlign: "middle" }} />

                          <WebsiteText>
                            Website
                          </WebsiteText>

                        </WebsiteButton>

                        <ButtonContainer>
                          <TwitterButton color='#49B875'>
                            <HeartFilled style={{ color: 'white', height: '10px', width: '50px' }} />

                          </TwitterButton>
                          <ViewsButton background='rgb(30,38,52)' color='#49B875'>
                            {item.button3}

                          </ViewsButton>
                        </ButtonContainer>
                      </ButtonFlex>
                    </ButtonMergeContainer>
                  </Card>
                )
              }
            })}

        </CardDisplay> */}
        {tip.TipArr.length == 0 && !ploading ?
          <BodyFooterContainer1>
            <BodyFooterText>No Projects</BodyFooterText>
          </BodyFooterContainer1>
          :
          <BodyFooterContainer>

            <BodyFooterText>
              You're seeing {cardIncrement >= tip.TipArr.length ? tip.TipArr.length : cardIncrement + 1} of {tip.TipArr.length} assets

            </BodyFooterText>
            <ViewButton>
              <ViewButtonText
                onClick={ViewMore}>View more</ViewButtonText>
              <img src={ViewMoreImg} />
            </ViewButton>
          </BodyFooterContainer>


        }
      </MiddleContainer>
    </BodyContainer >
  )
}

export default TopEarnedPage