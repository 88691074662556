import React, { useState, useEffect } from 'react'
import { Spin } from 'antd';
import 'antd/es/spin/style/css';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import makeGetRequest from '../../../api/GetApi';
import { useAppContext } from '../../../context/AppContext';
import PersonImg from '../../../Static/Image/person2.jpeg'

import { BodyButton, BodyButtonText, BodyButtonText2, BodyContainer, BodyFooterContainer, BodyFooterText, BodyHeader, BodyText, Box, ButtonContainer, ButtonFlex, ButtonMergeContainer, CalenderImage, Card, CardDisplay, CardImage, CardMiddle, CardMiddleText, CardtextHead, CombineContent, CombineFlex, MiddleCardContainer, MiddleCardContainer2, MiddleContainer, MiddleInnerContain, MiddleInnerContain2, Rating, Text, TwitterButton, ViewButton, ViewButtonText, ViewsButton, WebsiteButton, WebsiteText, NameText1, TextHead1, TextHeadFloor1, CardIcon1, CardName1, CardButtonLaunched1, VolumeContainer1, FloorContainer1, FanText, SpinContainer, NameText2, FanText2, BackButton, BodyHeader1, SerialNo1, BodyFooterContainer1, HashText2, Nametext, NameText3, FanText3, TextHeadFloor2, CardName2, CardButtonLaunched2, VolumeContainer2, VolumeContainer3, FloorContainer2, Nametext1, Nametext3, Fantext3, NameText5, NameText6, CardName3, CardButtonLaunched4, VolumeContainer4, PersonIcon, Nametext2, Nametext5, Nametext6, CardIcon2, CardButtonLaunched3, CardName4 } from '../LandingPage/index.styled'
import { useNavigate } from 'react-router-dom'
import CardImg from '../../../Static/Image/card1.jpg'
import CalenderImg from '../../../Static/Image/calender.svg'
import BirdImg from '../../../Static/Image/bird1.svg'
import { HeartFilled } from '@ant-design/icons';
import Buttom2Img from '../../../Static/Image/button2.svg'
import Buttom3Img from '../../../Static/Image/button3.svg'
import UpcomingData from '../../../utils/UpcomingData.json'
import ViewMoreImg from '../../../Static/Image/viewMore.svg'
import UpcomingTomorrowData from '../../../utils/UpcomingTomorrowData.json'
import SearchBar1 from './Search1';

import { DataGridComponent } from '../LandingPage/DataGrid'
import { BoxMiddle, CardContainer, DropDown, HashText, HeaderMiddleText, InnerTableHead, NameText, RankingBodyButton, RankingBodyButtonText, RankingBodyButtonText2, RankingContainer, RankingHeader, RankingImage, RankingMiddleContainer, SerialNo, TableContent, TableHead, TextHead, TextHeadFloor, TextHeadVotes, CardIcon, CardName, CardNameText, CardImageName, CardButtonLaunched, VolumeContainer, VolumeContent, FloorContainer, TwitterContainer, DisacardContainer, DiscardContainer, VoteButton, VotesContainer, TableContainer, HashText1 } from '../RankingPage/index.styled'
import BlueVerified from '../../../Static/Image/BlueVerified.png'
import RankingData from '../../../utils/RankingData.json'
import RedDropDown from '../../../Static/Image/DownDropdown.svg'
import { InfoOutlined, EyeOutlined, UserOutlined, LeftOutlined } from '@ant-design/icons';
import { FilterComponent } from '../FilterComponent';
import { FanFilter } from '../../../utils/FilterData';
import ResponsivePage from '../LandingPage/ResponsivePage';
import { useMoralis } from 'react-moralis';

function TipsGivenPage() {
    const [cardIncrement, setCardIncrement] = useState(9)
    const [checkButton, setCheckButton] = useState('monday')
    const [loading, setLoading] = useState(false)
    const [searchList, setSearchList] = useState(false)
    const [projectList, setProjectList] = React.useState([])
    const [ploading, setpLoading] = useState(false)
    const [numbers, setNumbers] = useState([])
    const [search, setSearch] = useState()
    const { State, dispatch } = useAppContext()
    const [Amount, setAmount] = useState([])
    const { fan } = State
    const { isAuthenticated, user } = useMoralis()

    const navigate = useNavigate()
    const { assets } = State
    const { tip } = State
    const ViewMore = () => {
        setLoading(true)

        setTimeout(() => {
            setLoading(false)
            setCardIncrement(cardIncrement + 10)
            window.scrollBy(0, -350);


        }, 1000)
    }

    const getApi = async () => {
        setpLoading(true)
        const Tips = await makeGetRequest('get/tip')

        // const result = await makeGetRequest('get/project')
        console.log(Tips)
        let wallet_id = user?.attributes?.ethAddress
        const TipWallet = Tips.tip.filter((item) => {
            return wallet_id === item.wallet_id
        })
        console.log(TipWallet, "hiii")
        var letters = TipWallet.filter((arr, index, self) =>

            index === self.findIndex((t) => (t.asset_id === arr.asset_id)))
        if (Tips.tip.length > 0) {
            dispatch({ type: "INITIAL_TIP", payload: letters })
            setpLoading(false)
        }
        else {
            setpLoading(false)
        }
        // if (result.project.length > 0) {
        //   await projectCalculation(result.project)
        // }
    }
    useEffect(() => {

        getApi()
    }, [])






    const handleAscendingOrder = (values) => {
        if (values === "Fan") {
            setpLoading(true)
            if (fan.fanInitialArr.length > 0) {
                let totalProject = fan.fanInitialArr.sort((a, b) => {
                    const name1 = a.name.toUpperCase();
                    const name2 = b.name.toUpperCase();
                    let comparison = 0;
                    if (name1 > name2) {
                        comparison = 1;
                    } else if (name1 < name2) {
                        comparison = -1;
                    }
                    return comparison;
                })
                setTimeout(() => {
                    setpLoading(false)
                }, 500)
                dispatch({ type: "CHANGE_ASSETS", payload: totalProject })

            }
        }
        else if (values === "Collections") {
            setpLoading(true)
            if (fan.fanInitialArr.length > 0) {
                let TotalNft = fan.fanInitialArr.sort((a, b) => {
                    return a.Nftcount - b.Nftcount
                })
                setTimeout(() => {
                    setpLoading(false)
                }, 500)
                dispatch({ type: "CHANGE_ASSETS", payload: TotalNft })


            }
        }
        else if (values === "NFTs") {
            setpLoading(true)
            if (fan.fanInitialArr.length > 0) {
                let TotalFan = fan.fanInitialArr.sort((a, b) => {
                    return a.FansCount - b.FansCount
                })
                setTimeout(() => {
                    setpLoading(false)
                }, 500)
                dispatch({ type: "CHANGE_ASSETS", payload: TotalFan })


            }
        }
        else if (values === "Tips Total Value") {
            setpLoading(true)
            if (fan.fanInitialArr.length > 0) {
                let TotalDonation = fan.fanInitialArr.sort((a, b) => {
                    return a.fan - b.fan
                })
                setTimeout(() => {
                    setpLoading(false)
                }, 500)
                dispatch({ type: "CHANGE_ASSETS", payload: TotalDonation })


            }
        }
        else if (values === "Tips Count") {
            setpLoading(true)
            if (fan.fanInitialArr.length > 0) {
                let TotalTippedNfts = fan.fanInitialArr.sort((a, b) => {
                    return a.Floor - b.Floor
                })
                setTimeout(() => {
                    setpLoading(false)
                }, 500)
                dispatch({ type: "CHANGE_ASSETS", payload: TotalTippedNfts })


            }
        }

    }
    const handleDesendingOrder = (values) => {
        if (values === "Fan") {
            if (values === "Fan") {
                setpLoading(true)
                if (fan.fanInitialArr.length > 0) {
                    let totalProject = fan.fanInitialArr.sort((a, b) => {
                        if (a.name > b.name) {
                            return -1
                        }
                        if (b.name < a.name) {
                            return 1
                        }
                        return 0
                    })
                    setTimeout(() => {
                        setpLoading(false)
                    }, 500)
                    dispatch({ type: "CHANGE_ASSETS", payload: totalProject })

                }
            }
        }
        else if (values === "Collections") {
            setpLoading(true)
            if (fan.fanInitialArr.length > 0) {
                let TotalNft = fan.fanInitialArr.sort((a, b) => {
                    return b.Nftcount - a.Nftcount
                })
                setTimeout(() => {
                    setpLoading(false)
                }, 500)
                dispatch({ type: "CHANGE_ASSETS", payload: TotalNft })


            }
        }
        else if (values === "NFTs") {
            setpLoading(true)
            if (fan.fanInitialArr.length > 0) {
                let TotalFan = fan.fanInitialArr.sort((a, b) => {
                    return b.FansCount - a.FansCount
                })
                setTimeout(() => {
                    setpLoading(false)
                }, 500)
                dispatch({ type: "CHANGE_ASSETS", payload: TotalFan })


            }
        }
        else if (values === "Tips Total Value") {
            setpLoading(true)
            if (fan.fanInitialArr.length > 0) {
                let TotalDonation = fan.fanInitialArr.sort((a, b) => {
                    return b.fan - a.fan
                })
                setTimeout(() => {
                    setpLoading(false)
                }, 500)
                dispatch({ type: "CHANGE_ASSETS", payload: TotalDonation })


            }
        }
        else if (values === "Tips Count") {
            setpLoading(true)
            if (fan.fanInitialArr.length > 0) {
                let TotalTippedNfts = fan.fanInitialArr.sort((a, b) => {
                    return b.Floor - a.Floor
                })
                setTimeout(() => {
                    setpLoading(false)
                }, 500)
                dispatch({ type: "CHANGE_ASSETS", payload: TotalTippedNfts })


            }
        }
    }

    const handleAscChange = (e) => {
        handleAscendingOrder(e)
    }
    const handleDescChange = (e) => {
        handleDesendingOrder(e)
    }
    const handleRoute = (value) => {
        navigate(`/projectfans/${value.wallet_id}`)
    }


    return (
        <BodyContainer>
            <MiddleContainer>

                <BodyHeader1>Tips Given</BodyHeader1>
                <BodyText>
                    Discover the hottest nft projects right now!
                </BodyText>
                <SearchBar1 setSearchList={setSearchList} projectList={fan.fanInitialArr} setpLoading={setpLoading} dispatch={dispatch} setSearch={setSearch} />

                <br />
                <TableContainer>

                    <TableHead>
                        <InnerTableHead>
                            <HashText2>#</HashText2>
                            <NameText1>Assets <ArrowUpwardIcon style={{ color: "#94A1B7", fontSize: "15px", top: "4px", left: "5px", position: "relative", cursor: "pointer", right: "5px" }} onClick={(e) => { return handleAscChange("Assets") }} /> <ArrowDownwardIcon style={{ color: "#94A1B7", top: "4px", fontSize: "15px", position: "relative", left: "5px", cursor: "pointer", right: "5px" }} onClick={() => { return handleDescChange('Assets') }} /> </NameText1>
                            <Nametext2>Amount <ArrowUpwardIcon style={{ color: "#94A1B7", fontSize: "15px", top: "4px", left: "5px", position: "relative", cursor: "pointer", right: "5px" }} onClick={(e) => { return handleAscChange("Amount") }} /> <ArrowDownwardIcon style={{ color: "#94A1B7", top: "4px", fontSize: "15px", position: "relative", left: "5px", cursor: "pointer", right: "5px" }} onClick={() => { return handleDescChange('Amount') }} /></Nametext2>

                            <Nametext2>Value<ArrowUpwardIcon style={{ color: "#94A1B7", fontSize: "15px", top: "4px", left: "5px", position: "relative", cursor: "pointer", right: "5px" }} onClick={(e) => { return handleAscChange("Value") }} /><ArrowDownwardIcon style={{ color: "#94A1B7", top: "4px", fontSize: "15px", position: "relative", left: "5px", cursor: "pointer", right: "5px" }} onClick={() => { return handleDescChange('Value') }} /></Nametext2>
                            <Nametext2> Fans <ArrowUpwardIcon style={{ color: "#94A1B7", fontSize: "15px", top: "4px", left: "5px", position: "relative", cursor: "pointer", right: "5px" }} onClick={(e) => { return handleAscChange("Fans") }} /><ArrowDownwardIcon style={{ color: "#94A1B7", top: "4px", fontSize: "15px", position: "relative", left: "5px", cursor: "pointer", right: "5px" }} onClick={() => { return handleDescChange('Fans') }} /></Nametext2>
                            <Nametext5>NFTs <ArrowUpwardIcon style={{ color: "#94A1B7", fontSize: "15px", top: "4px", left: "5px", position: "relative", cursor: "pointer", right: "5px" }} onClick={(e) => { return handleAscChange("NFTs") }} /><ArrowDownwardIcon style={{ color: "#94A1B7", top: "4px", fontSize: "15px", position: "relative", left: "5px", cursor: "pointer", right: "5px" }} onClick={() => { return handleDescChange('NFTs') }} /></Nametext5>
                            <Nametext6>Collections <ArrowUpwardIcon style={{ color: "#94A1B7", fontSize: "15px", top: "4px", left: "5px", position: "relative", cursor: "pointer", right: "5px" }} onClick={(e) => { return handleAscChange("Collections") }} /><ArrowDownwardIcon style={{ color: "#94A1B7", top: "4px", fontSize: "15px", position: "relative", left: "5px", cursor: "pointer", right: "5px" }} onClick={() => { return handleDescChange('Collections') }} /></Nametext6>
                            <Nametext2>Fees <ArrowUpwardIcon style={{ color: "#94A1B7", fontSize: "15px", top: "4px", left: "5px", position: "relative", cursor: "pointer", right: "5px" }} onClick={(e) => { return handleAscChange("Fees") }} /><ArrowDownwardIcon style={{ color: "#94A1B7", fontSize: "15px", top: "4px", position: "relative", left: "5px", cursor: "pointer", right: "5px" }} onClick={() => { return handleDescChange('Fees') }} /></Nametext2>

                            {/* <TextHead1>Size</TextHead1> */}
                            {/* <TextHead>Twitter</TextHead>
            <TextHead>Discard</TextHead>
            <TextHeadVotes>Votes</TextHeadVotes>
            <TextHead>Vote</TextHead> */}
                        </InnerTableHead>
                    </TableHead>
                    {console.log(tip.TipArr)}
                    {ploading ?
                        <div style={{ display: 'flex', flex: 1, justifyContent: "center", alignItems: "center", padding: "20px" }}>
                            <Spin />

                        </div> : tip.TipArr && tip.TipArr.map((item, i) => {
                            return (
                                <TableContent key={i + 1} onClick={() => { return handleRoute(item) }}>
                                    <SerialNo1>{i + 1}</SerialNo1>
                                    {/* <DropDown src={RedDropDown} /> */}
                                    <CardIcon2 src={item.asset.image_url} />
                                    <CardName4>
                                        <CardNameText >{item.asset.name}</CardNameText>
                                        <CardImageName src={BlueVerified} />
                                    </CardName4>
                                    <CardButtonLaunched3>
                                        -
                                    </CardButtonLaunched3>
                                    <CardButtonLaunched3>
                                        <VolumeContent>
                                            -
                                        </VolumeContent>
                                    </CardButtonLaunched3>
                                    <CardButtonLaunched3>
                                        <VolumeContent>
                                            -
                                        </VolumeContent>
                                    </CardButtonLaunched3>
                                    <CardButtonLaunched3>
                                        <VolumeContent>
                                            -
                                        </VolumeContent>
                                    </CardButtonLaunched3>
                                    <CardButtonLaunched3>
                                        <VolumeContent>
                                            -
                                        </VolumeContent>
                                    </CardButtonLaunched3>
                                    <CardButtonLaunched3>
                                        <VolumeContent>
                                            -
                                        </VolumeContent>
                                    </CardButtonLaunched3>
                                </TableContent>
                            )
                        })}

                </TableContainer>
                <ResponsivePage />


                <SpinContainer>
                    {loading && <Spin />}
                </SpinContainer>
                {!tip.TipArr && tip.TipArr.length == 0 && !ploading ?
                    <BodyFooterContainer1>
                        <BodyFooterText>No Projects</BodyFooterText>
                    </BodyFooterContainer1>
                    :
                    <BodyFooterContainer>

                        <BodyFooterText>
                            You're seeing {cardIncrement >= tip.TipArr.length ? tip.TipArr.length : cardIncrement + 1} of {tip.TipArr.length} Tips given
                        </BodyFooterText>
                        <ViewButton>
                            <ViewButtonText
                                onClick={ViewMore}>View more</ViewButtonText>
                            <img src={ViewMoreImg} />
                        </ViewButton>
                    </BodyFooterContainer>


                }
            </MiddleContainer>
        </BodyContainer >
    )
}

export default TipsGivenPage