import React, { useState } from "react";
import InputBase from "@material-ui/core/InputBase";
import { alpha, makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import RankingData from "../../../utils/RankingData.json"
import { ProjectTitle } from "../RankingPage/ProjectPage/index.styled";
import { useLocation } from 'react-router-dom'
import { useSearchParams } from "react-router-dom";
import useSearchHooks from "../../helper/useSearch";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
    search: {
        position: "relative",
        border: "1px solid rgb(19, 47, 76)",
        borderRadius: "10px",
        marginLeft: 0,
        width: "100%",
        height: "60px",
        '&:hover': {
            background: "rgb(19, 47, 76)"
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "rgb(102, 178, 255)",
    },
    inputRoot: {
        color: "#f5f5f5",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        height: "44px",
        fontFamily: "Sofia Pro",

        [theme.breakpoints.up("sm")]: {
            width: "12ch",
            "&:focus": {
                width: "20ch",
            },
        },
    },

}));


export default function SearchBar({ setSearchList, setProjectList, projectList, setSearch, dispatch,queryname,setQuery }) {
    const classes = useStyles();
    const [SearchItem, setSearchItem] = useState()
    const location = useLocation()
    let history = useSearchParams();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const result =  useSearchHooks()
    const onChange = async(event) => {
        const {name, value} = event?.target;
        let search = (await result).UseSearchApi(name,value)
        setQuery(queryname)
        // console.log(name,"name");
        // setSearchParams({[name]: value})   
        // handleChange(event.target.value)

        // dispatch({ type: "SET_TEXT", text: event.target.value.toLowerCase() })

        // dispatch({ type: "SEARCH_ASSETS", projectList, searchText: event.target.value.toLowerCase() })
      
      
    };

    return (
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                placeholder="Search…"
                name={queryname}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                style={{ width: "100%" }}
                inputProps={{ "aria-label": "search" }}
                onChange={onChange}
            />
        </div>
    );
}