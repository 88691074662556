import React from "react"



export const Section3 =()=>{
    return (
        <React.Fragment>
            <section className="section3">
         <div className="section_container">
               <div>
                   <h2 className="section_3_title">On the mission to bring <br/> NFTS to the  mainstream</h2>
               </div>
               <div>
                   <p className="section_3_subtitle">Airnfts is the NFT marketplace built on BSC, Fantom and Polygon focusing <br/>highly on user experience and ease of use to create, buy, sell and trade NFTs.</p>
               </div>
               <div className="container">
                        <div className="section3_card_container">
                            <div className="section3_card_box">
                                <div>
                                    <img src="https://uploads-ssl.webflow.com/60098c5b239e4890fa5ee1aa/6125021e672e7211192c1aca_image-1.png" height="130"  alt="feature-3" className="cards-image" />
                                </div>
                                <div>
                                    <h5 className="card_title">Create NFT with ease</h5>
                                </div>
                                <div className="card_subtitle_width">
                                    <p className="card_subtitle">One click and your NFT is created. Set a price or use Auctions and start earning crypto today</p>
                                </div>
                                <div>
                                    <a href="#" className="card_link">HoW TO CREATE NFT</a>
                                </div>
                            </div>

                            <div className="section3_card_box">
                                <div>
                                <img src="https://uploads-ssl.webflow.com/60098c5b239e4890fa5ee1aa/6125021f07c1783b4532e92d_image-2.png" height="130"  alt="feature-3" className="cards-image" />                                </div>
                                <div>
                                    <h5 className="card_title">Create NFT with ease</h5>
                                </div>
                                <div className="card_subtitle_width">
                                    <p className="card_subtitle">One click and your NFT is created. Set a price or use Auctions and start earning crypto today</p>
                                </div>
                                <div>
                                    <a href="#" className="card_link">HoW TO CREATE NFT</a>
                                </div>
                            </div>

                           <div className="section3_card_box">
                            <div>
                                    <img src="	https://uploads-ssl.webflow.com/60098c5b239e4890fa5ee1aa/61c70247d1ee523444bcb5bc_image-3.png" height="130"  alt="feature-3" className="cards-image" />
                            </div>
                            <div>
                                <h5 className="card_title">Create NFT with ease</h5>
                            </div>
                            <div className="card_subtitle_width">
                                <p className="card_subtitle">One click and your NFT is created. Set a price or use Auctions and start earning crypto today</p>
                            </div>
                            <div>
                                <a href="#" className="card_link">HoW TO CREATE NFT</a>
                            </div>
                          </div>
                    </div>
               </div>
         </div>
    </section>
        </React.Fragment>
    )
}