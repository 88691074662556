import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { Spin } from 'antd';
import 'antd/es/spin/style/css';
import { ApprovedImage, BlockMerge, BoxDisplay, BoxHeader, ButtonContainer, ButtonflexRow, ButtonInnerContainer, ButtonRated, ButtonVote, ButtonVote1, CardContainer1, CardContainerText, CardContainerText1, CardContainerText2, CardFooterContiner, CardInnerContainer1, CardProjectDisplay, ContainerText1, DiscardButton, DiscardButton2, EthText, ExploreButton, FooterText, HeaderBlock, InnerCardContainer, InnerCardFullContain, OpenseaButton, PriceText, ProjectBodyContainer, ProjectBodyContainer2, ProjectBox, ProjectCard, ProjectCardImage, ProjectContainer, ProjectContainer2, ProjectFooterContainer, ProjectFooterInner, ProjectHeaderContain, ProjectHeadImage, ProjectInnerContainer, ProjectTitle, RateColor, RateColor2, RightCombine, RightContent, TextContainer, TextInnerContainer, TwitterButton, TwitterButton2, TwitterRate, TwitterText, TwitterText1, TwitterText2, VoteRateButton, VoteRateButton1, VoteSearch, VoteText, VoteText1, } from '../RankingPage/ProjectPage/index.styled'
import ProjectHeadImg from '../../../Static/Image/ProjectHead.jpg'
import ApprovedImg from '../../../Static/Image/approved.png'
import BirdImg from '../../../Static/Image/bird1.svg'
import { HeartFilled, InfoOutlined, LeftOutlined } from '@ant-design/icons';
import Buttom2Img from '../../../Static/Image/button2.svg'
import Buttom3Img from '../../../Static/Image/button3.svg'
import OpenSeaImage from '../../../Static/Image/openSea.svg'
import ProjectData from '../../../utils/ProjectData'
import ProjectCard1 from '../../../Static/Image/ProjectCard1.gif'
import SalesImg from '../../../Static/Image/SalesCardDown.svg'
import CardProjectData from '../../../utils/CardProjectData.json'
import card3 from '../../../Static/Image/card3.jpg'
import card4 from '../../../Static/Image/card4.jpg'
import card5 from '../../../Static/Image/card5.jpg'
import card6 from '../../../Static/Image/card6.jpg'
import card7 from '../../../Static/Image/card7.jpg'
import card8 from '../../../Static/Image/card8.jpg'
import ViewMoreImg from '../../../Static/Image/viewMore.svg'
import { BodyFooterContainer, ViewButton, ViewButtonText, SpinContainer, BackButton, BackButtonContaine } from '../LandingPage/index.styled'
import TwitterIcon from '@mui/icons-material/Twitter';
import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import LanguageIcon from '@mui/icons-material/Language';
import PublicIcon from '@mui/icons-material/Public';
import RankingData1 from "../../../utils/RankingData1.json"
import { Footer } from 'antd/lib/layout/layout'
import SearchProject from '../RankingPage/ProjectPage/SearchProject';

function

    TopEarnProjectPage() {

    const param = useParams()
    const navigate = useNavigate()
    const [cardIncrement, setCardIncrement] = useState(5)
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState()



    const ViewMore = () => {
        setLoading(true)

        setTimeout(() => {
            setLoading(false)
            setCardIncrement(cardIncrement + 6)
            window.scrollBy(0, -350);


        }, 1000)
    }


    const [projectItem, setProjectItem] = useState({

        "Serialno": "1",
        "img": "card1Img.jpg",
        "name": "Property Heist Ape Club",
        "Volume": "-",
        "Floor": "-",
        "Size": "8.89k",
        "personimg": "person2.jpeg",
        "Twitter": "4.43k",
        "Discard": "6.18k",
        "votes": "AD",
        "TwitterRate": "11,242",
        "DiscardRate": "6,180",
        projectData: [
            {
                "ETH": "0.002ETH",
                "price": "$575",
                "name": "Floor Price"
            },
            {
                "ETH": "0.0023 ETH",
                "price": "$6.61",
                "name": "Average price"
            },
            {
                "ETH": "0.229 ETH",
                "price": " $658.72",
                "name": " Trading volume"
            },
            {
                "ETH": "354",
                "price": false,
                "name": "Owners"
            },
            {
                "ETH": "35",
                "price": false,
                "name": "Sales (7 days)"
            },
            {
                "ETH": "2,358",
                "price": false,
                "name": "Collection size"
            }
        ],
        "text": "Property Heist Ape Club is a 2,358 piece NFT collection stored on the Ethereum blockchain. The current floor price for a Property Heist Ape Club NFT is 0.002 ETH - $5.75. The total sales volume for Property Heist Ape Club is 0.229 ETH - $658.72 with 354 owners.",
        "text2": "We’ve seen a lot of apes join the ‘metaverse’ but let’s be honest most are without any real form or function, nor do the community benefit holding. Our apes are built different, period!All"

    })

    useEffect(() => {
        window.scrollTo(0, 0)
        let filteredItem = RankingData1.filter((item) => {
            return item.Serialno == param.id
        })
        setProjectItem(filteredItem[0])

    }, [])


    return (
        <ProjectContainer>
            <ProjectContainer2>
                <ProjectInnerContainer>

                    <ProjectHeaderContain>
                        <ProjectHeadImage src={require(`../../../Static/Image/${projectItem.img}`)} />
                        <HeaderBlock>
                            <div style={{ width: "1160px" }}>
                                <ProjectTitle>
                                    {projectItem.name}
                                    <ApprovedImage src={ApprovedImg} style={{ position: "relative", top: "12px" }} />
                                    {/* <BackButtonContainer>
                                            <BackButton onClick={() => navigate(-1)}>
                                            <LeftOutlined style={{ fontSize: "18px", color: "white" }} />
                                            </BackButton>
                                        </BackButtonContainer> */}
                                </ProjectTitle>
                            </div>
                            <ButtonflexRow>
                                <TwitterButton2>
                                    <TwitterText2>Collections</TwitterText2>
                                </TwitterButton2>

                                <DiscardButton2>
                                    <TwitterText2>NFTs</TwitterText2>
                                </DiscardButton2>

                                <OpenseaButton>
                                    <TwitterText2>Tips Total Value</TwitterText2>

                                </OpenseaButton>
                                <ExploreButton>
                                    <TwitterText2>Tips Count</TwitterText2>

                                </ExploreButton>
                            </ButtonflexRow>
                        </HeaderBlock>
                    </ProjectHeaderContain>
                </ProjectInnerContainer>
            </ProjectContainer2>
            <ProjectBodyContainer>
                <ProjectBodyContainer2>
                    {/* <BoxDisplay>
                        {projectItem.projectData.map((item) => {
                            return (


                                <ProjectBox>
                                    <BoxHeader>
                                        <EthText>{item.ETH}</EthText>
                                        {item.price && <PriceText>{item.price}</PriceText>}
                                        <PriceText>{item.name}</PriceText>

                                    </BoxHeader>
                                </ProjectBox>
                            )
                        })}
                    </BoxDisplay> */}

                </ProjectBodyContainer2>
            </ProjectBodyContainer>
            <TextContainer>



                <TextInnerContainer>
                    <ContainerText1>
                        {projectItem.text}
                    </ContainerText1>
                    <ContainerText1>{projectItem.text2}</ContainerText1>
                    <VoteRateButton1>
                        <TwitterIcon sx={{
                            "&:hover": {
                                color: "rgb(62, 158, 214) ! important",
                                cursor: "default"
                            }
                        }} style={{ marginLeft: "25px", color: "white", verticalAlign: "middle", }} ></TwitterIcon>
                        <CloudCircleIcon sx={{
                            "&:hover": {
                                color: "rgb(62, 158, 214) ! important",
                                cursor: "default"
                            }
                        }} style={{ marginLeft: "25px", color: "white", verticalAlign: "middle", }} />
                        <PublicIcon sx={{
                            "&:hover": {
                                color: "rgb(62, 158, 214)! important",
                                cursor: "default"
                            }
                        }} style={{ marginLeft: "25px", color: "white", verticalAlign: "middle", }} />
                        <LanguageIcon sx={{
                            "&:hover": {
                                color: "rgb(62, 158, 214) ! important",
                                cursor: "default"
                            }
                        }} style={{ marginLeft: "25px", color: "white", verticalAlign: "middle", }} />


                    </VoteRateButton1>
                </TextInnerContainer>

            </TextContainer>
            <ButtonContainer>
                <ButtonInnerContainer>
                    <BlockMerge>
                        <VoteRateButton>
                            <ButtonVote1>Top Earner</ButtonVote1>
                        </VoteRateButton>
                        <VoteText>
                            Recent Property Heist Ape Club Sales
                        </VoteText>
                    </BlockMerge>
                    <SearchProject setSearch={setSearch} />
                </ButtonInnerContainer>

            </ButtonContainer>
            <CardContainer1>
                <CardInnerContainer1>
                    <CardProjectDisplay>
                        {

                            search ?

                                search.map((item, i) => {
                                    return (


                                        <ProjectCard>
                                            <ProjectCardImage onClick={(e) => { navigate(`/image/${item.Serialno}`) }} src={require(`../../../Static/Image/${item.img}`)} />
                                            <InnerCardFullContain>
                                                <InnerCardContainer>
                                                    <CardContainerText>
                                                        {item.title}
                                                    </CardContainerText>
                                                </InnerCardContainer>
                                            </InnerCardFullContain>
                                            <CardFooterContiner>

                                                <RightCombine>
                                                    <FooterText>Nft Price</FooterText>
                                                    <CardContainerText1>$0.004</CardContainerText1>
                                                </RightCombine>
                                                <RightCombine>
                                                    <FooterText>No of Fans</FooterText>
                                                    <CardContainerText1>2,447</CardContainerText1>
                                                </RightCombine>
                                                <RightCombine>
                                                    <FooterText>Donation Received</FooterText>
                                                    <CardContainerText1>$0.034</CardContainerText1>
                                                </RightCombine>
                                            </CardFooterContiner>
                                        </ProjectCard>
                                    )
                                }
                                ) :


                                CardProjectData.map((item, i) => {
                                    if (i <= cardIncrement) {
                                        return (


                                            <ProjectCard>
                                                <ProjectCardImage onClick={(e) => { navigate(`/image/${item.Serialno}`) }} src={require(`../../../Static/Image/${item.img}`)} />
                                                <InnerCardFullContain>
                                                    <InnerCardContainer>
                                                        <CardContainerText>
                                                            {item.title}
                                                        </CardContainerText>
                                                    </InnerCardContainer>
                                                </InnerCardFullContain>
                                                <CardFooterContiner>

                                                    <RightCombine>
                                                        <FooterText>Nft price</FooterText>
                                                        <CardContainerText1>$0.004</CardContainerText1>
                                                    </RightCombine>
                                                    <RightCombine>
                                                        <FooterText>No of Fans</FooterText>
                                                        <CardContainerText1>2,447</CardContainerText1>
                                                    </RightCombine>
                                                    <RightCombine>
                                                        <FooterText>Donation Received</FooterText>
                                                        <CardContainerText1>$0.034</CardContainerText1>
                                                    </RightCombine>
                                                </CardFooterContiner>
                                            </ProjectCard>
                                        )
                                    }
                                })}

                    </CardProjectDisplay>
                    <SpinContainer>
                        {loading && <Spin />}
                    </SpinContainer>
                </CardInnerContainer1>

            </CardContainer1>
            {/* <ProjectFooterContainer>
                <ProjectFooterInner>
                    <CardProjectDisplay>
                        {CardProjectData.map((item) => {
                            return (


                                <ProjectCard>
                                    <ProjectCardImage src={ProjectCard1} />
                                    <InnerCardFullContain>
                                        <InnerCardContainer>
                                            <CardContainerText>
                                                Property Heist Ape Club
                                            </CardContainerText>
                                        </InnerCardContainer>
                                    </InnerCardFullContain>
                                    <CardFooterContiner>
                                        <FooterText>Price</FooterText>
                                        <RightContent>
                                            <RightCombine>
                                                <img style={{ width: "25.8px", height: "20.08px", marginRight: "5px", verticalAlign: "middle" }} src={SalesImg} />
                                                <CardContainerText1>{item.rating}</CardContainerText1>
                                            </RightCombine>
                                            <CardContainerText2>{item.rate}</CardContainerText2>
                                        </RightContent>
                                    </CardFooterContiner>
                                </ProjectCard>
                            )
                        })}
                    </CardProjectDisplay>
                </ProjectFooterInner>
            </ProjectFooterContainer> */}
            <ProjectFooterContainer>
                <ProjectFooterInner>



                    <ViewButton>
                        <ViewButtonText onClick={ViewMore}
                        >View more</ViewButtonText>
                        <img src={ViewMoreImg} />
                    </ViewButton>

                </ProjectFooterInner>
            </ProjectFooterContainer>
        </ProjectContainer>
    )
}

export default TopEarnProjectPage