import React from 'react'
import { CardFooterText, SalesBottomText, SalesBoxMiddle, SalesCard, SalesCardCost, SalesCardDisplay, SalesCardFooter, SalesCardImage, SalesCardMiddleContainer, SalesCardText, SalesContainer, SalesInnerConainer, SalesMiddleCombine, SalesMiddleImage, SalesTable } from './index.styled'
import { BoxMiddle, CardContainer, HashText, HeaderMiddleText, RankingBodyButton, RankingBodyButtonText, RankingBodyButtonText2, RankingHeader, RankingImage } from '../RankingPage/index.styled'
import SalesCardImg1 from '../../../Static/Image/SalesCard1.png'
import DiamondBalck from '../../../Static/Image/SalesCardDown.svg'
import SalesData from '../../../utils/SalesData.json'
import { CollectionCardContainer } from '../CollectionSalesPage/index.styled'
import RankingCardImg from '../../../Static/Image/RankingCardImage.jpg_large'

function SalesPage() {
    return (
        <SalesContainer>
            <SalesInnerConainer>
                <RankingHeader>
                    Top NFT Sales
                </RankingHeader>
                <HeaderMiddleText>
                    The top selling NFTs of the last 24 hours. Never miss a record NFT sale again.
                </HeaderMiddleText>
                <SalesCardDisplay>
                    {SalesData.map((item) => {
                        return (
                            <SalesCard>
                                <SalesCardImage src={require(`../../../Static/Image/${item.img}`)} />
                                <SalesCardMiddleContainer>
                                    <SalesCardText>{item.text1}</SalesCardText>
                                    <SalesMiddleCombine>
                                        <SalesMiddleImage src={DiamondBalck} />
                                        <SalesCardCost>{item.cost}</SalesCardCost>
                                    </SalesMiddleCombine>
                                </SalesCardMiddleContainer>
                                <SalesCardFooter>
                                    <CardFooterText>
                                        Bored Ape Yacht Club
                                    </CardFooterText>
                                    <SalesCardText>{item.text}</SalesCardText>
                                </SalesCardFooter>
                            </SalesCard>
                        )
                    })}
                </SalesCardDisplay>
                <SalesBoxMiddle>
                    <RankingBodyButton>
                        <RankingBodyButtonText>15 minutes</RankingBodyButtonText>
                        <RankingBodyButtonText2>30 minutes</RankingBodyButtonText2>
                        <RankingBodyButtonText2>1 hour</RankingBodyButtonText2>
                        <RankingBodyButtonText2>24 hours</RankingBodyButtonText2>
                        <RankingBodyButtonText2>7 days</RankingBodyButtonText2>

                    </RankingBodyButton>
                </SalesBoxMiddle>
                {/* <SalesTable>
                    <HashText>#</HashText>
                </SalesTable> */}
                <SalesBottomText>
                    You're seeing 1 of 1 projects
                </SalesBottomText>
                <CollectionCardContainer>
                    <RankingImage src={RankingCardImg} />
                    <RankingImage src={RankingCardImg} />
                    <RankingImage src={RankingCardImg} />
                </CollectionCardContainer>
            </SalesInnerConainer>
        </SalesContainer>
    )
}

export default SalesPage