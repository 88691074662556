import CollectionSales from "../UI/Components/CollectionSalesPage";
import FansPage from "../UI/Components/FansPage";
import { HomeComponent } from "../UI/Components/Home";
import LandingPage from "../UI/Components/LandingPage";
import ProfileComponent from "../UI/Components/profile";
import RankingPage from "../UI/Components/RankingPage";
import ProjectPage from "../UI/Components/RankingPage/ProjectPage";
import ImagePage from "../UI/Components/RankingPage/ProjectPage/ImagePage";
import ProjectPage1 from "../UI/Components/RankingPage/ProjectPage2";
import SalesPage from "../UI/Components/SalesPage";
import TopEarnedPage from "../UI/Components/TopEarnerPage";
import TipsGivenPage from "../UI/Components/TopEarnerPage/TipsGiven";
import TipsReceivedPage from "../UI/Components/TopEarnerPage/TipsReceived";
import TopEarnProjectPage from "../UI/Components/TopEarnerPage/TopEarnProject";
import TrendingPage from "../UI/Components/TrendingPage";


export const routes = [

    {
        path: "/",
        component: <HomeComponent />
    },
    {
        path: "/project",
        component: <LandingPage />
    },
    {
        path: "/Ranking",
        component: <RankingPage />
    },
    {
        path: "/trending",
        component: <TrendingPage />
    },
    {
        path: "/collection",
        component: <CollectionSales />
    },
    {
        path: "/sales",
        component: <SalesPage />
    },
    {
        path: "/project/:id",
        component: <ProjectPage />
    },
    {
        path: "/projectfans/:id",
        component: <ProjectPage1 />
    },
    {
        path: "/topEarn",
        component: <TopEarnedPage />
    },
    {
        path: "/assets/:id",
        component: <ImagePage />
    },
    {
        path: "/earnProject/:id",
        component: <TopEarnProjectPage />
    },
    {
        path: "/fan",
        component: <FansPage />
    },
    {
        path: "/profile",
        component: <ProfileComponent />
    },
    {
        path: "/tipReceived",
        component: <TipsReceivedPage />
    },
    {
        path: "/tipGiven",
        component: <TipsGivenPage />
    }


]