import React from "react"



export const Section5 =()=>{
    return (
        <React.Fragment>
             <section className="section5">
            <div className="section_5_title_container">
                <h2 className="section_5_title">How to create an NFT on BSC, <br/> <h2  className="section_3_title" style={{textAlign: 'center'}}>Fantom and Polygon</h2></h2>
            </div>
            <div class="responsive_section_5_title_container">
                <h2 class="section_5_title">How to create an NFT on BSC,Fantom and Polygon</h2>
            </div>
            <div>
                <p className="section_3_subtitle">Follow these 3 simple steps</p>
            </div>
            <div className="container">
                <div className="section5_container">
                     <div className="section_5_card_box">
                           <div>
                               <img src="https://uploads-ssl.webflow.com/60098c5b239e4890fa5ee1aa/622a2860c4d1ea63a8beb4c1_connect-wallet.png" width="207" sizes="(max-width: 479px) 61vw, 207px" srcset="https://uploads-ssl.webflow.com/60098c5b239e4890fa5ee1aa/622a2860c4d1ea63a8beb4c1_connect-wallet-p-500.png 500w, https://uploads-ssl.webflow.com/60098c5b239e4890fa5ee1aa/622a2860c4d1ea63a8beb4c1_connect-wallet-p-800.png 800w, https://uploads-ssl.webflow.com/60098c5b239e4890fa5ee1aa/622a2860c4d1ea63a8beb4c1_connect-wallet-p-1080.png 1080w, https://uploads-ssl.webflow.com/60098c5b239e4890fa5ee1aa/622a2860c4d1ea63a8beb4c1_connect-wallet.png 1673w" alt="" className="step-image"/>
                           </div>
                           <div>
                               <h5 className="section_5_card_title">1. Connect your wallet</h5>
                           </div>
                           <div>
                               <p className="section_5_subtitle">Use Trust Wallet or Metamask to <br/><p className="section_5_subtitle"> connect to the app</p></p>
                           </div>
                     </div>
                            <div className="line-column">
                                <div className="line">---------</div>
                            </div>
                    <div className="section_5_card_box">
                        <div>
                            <img src="https://uploads-ssl.webflow.com/60098c5b239e4890fa5ee1aa/61d4beb466e3f2794da16f3a_create-nft.png" width="207" alt="" className="step-image"/>
                        </div>
                        <div>
                            <h5 className="section_5_card_title">2. Create your NFT</h5>
                        </div>
                        <div>
                            <p className="section_5_subtitle">Use Trust Wallet or Metamask to <br/><p className="section_5_subtitle"> connect to the app</p></p>
                        </div>
                   </div>
                   
                                <div className="line-column">
                                    <div className="line">---------</div>
                                </div>

                   <div className="section_5_card_box">
                    <div>
                        <img src="https://uploads-ssl.webflow.com/60098c5b239e4890fa5ee1aa/61d4be5cc7131b51bf824f86_Earn.png" width="217" sizes="(max-width: 479px) 61vw, 207px" alt="" className="step-image"/>
                    </div>
                    <div>
                        <h5 className="section_5_card_title">3.Start earning crypto</h5>
                    </div>
                    <div>
                        <p className="section_5_subtitle">Use Trust Wallet or Metamask to <br/><p className="section_5_subtitle"> connect to the app</p></p>
                    </div>
               </div>
                </div>
            </div>
    </section>
        </React.Fragment>
    )
}