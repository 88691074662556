import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom'
import { Empty, Spin } from 'antd';
import 'antd/es/spin/style/css';
import { ApprovedImage, BlockMerge, BoxDisplay, BoxHeader, BoxTwitterImg, ButtonContainer, ButtonflexRow, ButtonInnerContainer, ButtonRated, ButtonRated1, ButtonVote, ButtonVoted, CardContainer1, CardContainerText, CardContainerText1, CardContainerText2, CardFooterContainer, CardFooterContiner, CardInnerContainer1, CardProjectDisplay, ContainerText1, DiscardButton, DiscardButton2, DiscardButton3, DisplayingFlex, DisplayingNone, EthText, ExploreButton, ExploreButton2, FooterText, HeaderBlock, InnerCardContainer, InnerCardFullContain, OpenseaButton, OpenseaButton2, PriceText, ProjectBodyContainer, ProjectBodyContainer2, ProjectBox, ProjectCard, ProjectCardImage, ProjectContainer, ProjectContainer2, ProjectFooterContainer, ProjectFooterInner, ProjectHeaderContain, ProjectHeadImage, ProjectInnerContainer, ProjectTitle, RateColor, RateColor2, RightCombine, RightContent, TextContainer, TextInnerContainer, TwitterButton, TwitterButton2, TwitterButton3, TwitterRate, TwitterText, TwitterText1, TwitterText3, TwitterText4, TwitterText5, VoteRateButton, VoteRateButton1, VoteRateButton2, VoteSearch, VoteText, VoteText1 } from './index.styled'
import ProjectHeadImg from '../../../../Static/Image/ProjectHead.jpg'
import ApprovedImg from '../../../../Static/Image/approved.png'
import BirdImg from '../../../../Static/Image/bird1.svg'
import { HeartFilled } from '@ant-design/icons';
import { InfoOutlined, EyeOutlined, UserOutlined, LeftOutlined } from '@ant-design/icons';
import StarIcon from '@mui/icons-material/Star';
import HandshakeIcon from '@mui/icons-material/Handshake';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PersonIcon from '@mui/icons-material/Person';
import Buttom2Img from '../../../../Static/Image/button2.svg'
import Buttom3Img from '../../../../Static/Image/button3.svg'
import OpenSeaImage from '../../../../Static/Image/openSea.svg'
import ProjectData from '../../../../utils/ProjectData'
import ProjectCard1 from '../../../../Static/Image/ProjectCard1.gif'
import SalesImg from '../../../../Static/Image/SalesCardDown.svg'
import CardProjectData from '../../../../utils/CardProjectData.json'
import CardProjectData1 from '../../../../utils/CardProjectData1.json'
import card3 from '../../../../Static/Image/card3.jpg'
import card4 from '../../../../Static/Image/card4.jpg'
import card5 from '../../../../Static/Image/card5.jpg'
import card6 from '../../../../Static/Image/card6.jpg'
import card7 from '../../../../Static/Image/card7.jpg'
import card8 from '../../../../Static/Image/card8.jpg'
import BlueVerified from '../../../../Static/Image/BlueVerified.png'

import { BodyFooterContainer, ViewButton, ViewButtonText, SpinContainer, BackButton, BodyHeader1, BackButtonContainer, } from '../../LandingPage/index.styled'
import RankingData from "../../../../utils/RankingData.json"
import { Footer } from 'antd/lib/layout/layout'
import ViewMoreImg from '../../../../Static/Image/viewMore.svg'
import SearchProject from './SearchProject';
import Search from 'antd/lib/transfer/search';
import { useAppContext } from '../../../../context/AppContext';
import { BACKEND_URL } from '../../../../utils/backend';
import TwitterIcon from '@mui/icons-material/Twitter';
import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import LanguageIcon from '@mui/icons-material/Language';
import PublicIcon from '@mui/icons-material/Public';
import makeGetRequest from '../../../../api/GetApi';
import Skeleton from '@mui/material/Skeleton';

import { SpinContainer1 } from './ImagePage/Modal/index.styled';
import { display } from '@mui/system';
import { object } from 'yup';


function ProjectPage() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [cardIncrement, setCardIncrement] = useState(7)
    const [loading, setLoading] = useState(false)
    const [collectionButton, setCollectionButton] = useState(true)
    const [earnedButton, setEarnedButton] = useState(false)
    const [ploading, setpLoading] = useState(true)
    const [headerInfo, setHeaderInfo] = React.useState([])
    const { State, dispatch } = useAppContext()
    const { assets } = State
    const [starItemAssets, setStarItemAssets] = useState([])



    const location = useLocation()
    const ViewMore = () => {
        setLoading(true)

        setTimeout(() => {
            setLoading(false)
            setCardIncrement(cardIncrement + 4)
            window.scrollBy(0, -350);


        }, 1000)
    }

    const ButtonCollection = () => {
        setCollectionButton(true)
        setEarnedButton(false)
        getStarItemsCalculation(assets.DetailAsset)
    }

    const EarnedButton = () => {
        setEarnedButton(true)
        setCollectionButton(false)
        AllItemsCalculation(assets.DetailAsset)

    }




    const assetsCalculation = async (asset, tip, fan) => {
        let arr2 = []
        let TotalFan = []
        asset?.map((item) => {

            if (item?.tips?.length > 0) {
                if (item?.fans.length <= 0) {
                    return TotalFan
                }
                else {
                    item?.fans?.map((node_fan) => {
                        if (TotalFan.some((value) => { return value.wallet_id == node_fan?.wallet_id })) {
                            return TotalFan
                        }
                        else {
                            return TotalFan.push(node_fan)
                        }
                    })
                }
                let fans = TotalFan
                TotalFan= []
                let Transactions = item?.tips?.reduce((a, b) => {
                    return a + b.amount
                }, 0)
                let a = {}
                a = { ...item }
                a["tip_received"] = `$${Transactions}`
                a["transaction"] = item?.tips?.length
                a["fan_count"] = fans?.length
                a["tokens"] = item?.tips?.length
                return arr2.push(a)
            }
            else {
                let a = {}
                a = { ...item }
                a["tip_received"] = "-"
                a["transaction"] = "-"
                a["fan_count"] = "-"
                a["tokens"] = "-"

                return arr2.push(a)
            }
        })
        return arr2
    }




    const getStarItem = async (assets, tip, fan) => {
        let starItems = []
        let countFlag = 1
        let TotalFan = []
        if (tip?.length > 0) {
            tip?.map((tips) => {
                let tip_received = tips?.asset?.tips?.reduce((a, b) => {
                    return a + b.amount
                }, 0)

               tips?.asset?.fans?.map((item) => {
                    if (TotalFan?.some((value) => { return value.wallet_id == item?.wallet_id }) && TotalFan?.some((value)=>{return value.asset_id === item.asset_id})) {
                        return TotalFan
                    }
                    else {
                        return TotalFan.push(item)
                    }
                })
                let fans = TotalFan
                TotalFan= []
                if (starItems.some((value) => { return tips.asset_id == value.asset_id })) {
                    return starItems
                }
                else {
                    let a = {}
                    a["total_transaction"] = tips?.asset?.tips?.length
                    a["total_fans"] = fans?.length
                    a["tip_received"] = tip_received
                    a["token"] = tips?.asset?.tips?.length
                    let newValue = { ...tips, ...a }
                   
                    return starItems.push(newValue)
                }

            })
        }
        else {
            return starItems
        }
        return starItems


    }

    const getStarItemsCalculation = async (project) => {
        setpLoading(true)


        let starItem = await getStarItem(project[0]?.assets, project[0]?.tips, project[0]?.fans)
        setpLoading(false)
        return setStarItemAssets(starItem)

    }

    const AllItemsCalculation = async (project) => {
        setpLoading(true)

        let TotalAsset = await assetsCalculation(project[0]?.assets, project[0]?.tips, project[0].fans)

        dispatch({ type: "INITIAL_ASSETS", payload: TotalAsset })
        setpLoading(false)

    }

    useEffect(() => {
        let Empty = []

        window.scrollTo(0, 0)
        const getApi = async () => {
            let TotalFans = []


            const result = await makeGetRequest(`get/projectdetails/${id}`)
            let AmountData = result?.project?.[0]?.tips
            let b = AmountData?.reduce((a, b) => {
                return a + b.amount
            }, 0)
            result?.project?.[0]?.fans.map((fan) => {
                if (TotalFans.some((value) => { return value.wallet_id == fan.wallet_id })) {
                    return TotalFans
                }
                else {
                    TotalFans.push(fan)
                }
            })
            let a = {}
            a["tipTotal"] = result?.project?.[0]?.tips?.length
            a["totalFan"] = TotalFans?.length
            a["transaction"] = b
            getStarItemsCalculation(result.project)
            // Header Calculation

            if (result.project.length > 0) {
                dispatch({ type: "DETAIL_ASSETS", payload: result.project })
                dispatch({ type: "HEAD_ASSETS", payload: a })
            }

            else {
                setpLoading(false)
            }

        }
        getApi()

        return () => dispatch({ type: "DETAIL_ASSETS", payload: Empty })



    }, [])
    const handleRoute1 = () => {
        window.scrollTo(0, 0)
    }
    const handleRoute = (value) => {

        navigate(`/assets/${value.asset?.id}`, { state: value })
    }
    const handleAllRoute = (value) => {

        navigate(`/assets/${value?.id}`, { state: value })
    }


    return (
        <ProjectContainer>

            <ProjectContainer2>

                <ProjectInnerContainer>
                    {/* <BackButton onClick={() => navigate(-1)}>
                        <LeftOutlined style={{ fontSize: "18px", color: "white" }} />
                    </BackButton> */}
                    <ProjectHeaderContain>
                        {assets?.DetailAsset?.length > 0 ? <ProjectHeadImage src={assets?.DetailAsset[0]?.banner_image_url} /> : <Skeleton variant="rectangular" width={160} height={140} style={{ background: "rgb(19, 47, 76)", borderRadius: "5px", marginTop: "10px" }} />}

                        <HeaderBlock>
                            <div >
                                {assets?.DetailAsset?.length > 0 ?
                                    <ProjectTitle>
                                        {assets?.DetailAsset[0]?.name}
                                        <ApprovedImage src={BlueVerified} />
                                    </ProjectTitle> : <Skeleton variant="text" width={140} style={{ background: "rgb(19, 47, 76)", borderRadius: "5px", marginBottom: "30px", paddingBottom: "20px" }} />}
                            </div>
                            <ButtonflexRow>
                                <TwitterButton3>



                                    <TwitterText>Star Items</TwitterText>
                                    <DisplayingFlex>
                                        <StarIcon style={{ color: "#009BEF", marginRight: "6px" }} />


                                        <TwitterText>{assets.HeadAsset.tipTotal}</TwitterText>

                                    </DisplayingFlex>
                                </TwitterButton3>

                                <DiscardButton3>

                                    <TwitterText3>Fans</TwitterText3>
                                    <DisplayingFlex>

                                        <PersonIcon style={{ color: "rgb(88,102,242)", marginRight: "6px" }} />

                                        <TwitterText3>{assets.HeadAsset.totalFan}</TwitterText3>

                                    </DisplayingFlex>
                                </DiscardButton3>

                                <OpenseaButton2>

                                    <TwitterText4>Tipped</TwitterText4>
                                    <DisplayingFlex>

                                        <HandshakeIcon style={{ color: "rgb(32,129,226)", marginRight: "6px" }} />

                                        <TwitterText4>{assets.HeadAsset.tipTotal}</TwitterText4>


                                    </DisplayingFlex>


                                </OpenseaButton2>
                                <ExploreButton2>

                                    <TwitterText5>Transactions</TwitterText5>
                                    <DisplayingFlex>

                                        <AttachMoneyIcon style={{ color: " rgb(83,184,117)", }} />

                                        <TwitterText5>{assets.HeadAsset.transaction}</TwitterText5>


                                    </DisplayingFlex>


                                </ExploreButton2>
                            </ButtonflexRow>
                        </HeaderBlock>
                    </ProjectHeaderContain>
                </ProjectInnerContainer>
            </ProjectContainer2>
            {/* <ProjectBodyContainer>
                <ProjectBodyContainer2>
                    <BoxDisplay>
                        {projectItem.projectData.map((item) => {
                            return (


                                <ProjectBox>
                                    <BoxHeader>
                                        <EthText>{item.ETH}</EthText>
                                        {item.price && <PriceText>{item.price}</PriceText>}
                                        <PriceText>{item.name}</PriceText>

                                    </BoxHeader>
                                </ProjectBox>
                            )
                        })}
                    </BoxDisplay>

                </ProjectBodyContainer2>
            </ProjectBodyContainer> */}
            <TextContainer>



                <TextInnerContainer>
                    <ContainerText1>
                        {location.state.description}
                    </ContainerText1>
                    {/* <ContainerText1>{projectItem.text2}</ContainerText1>  */}
                    <VoteRateButton1>
                        <TwitterIcon sx={{
                            "&:hover": {
                                color: "rgb(62, 158, 214) ! important",
                                cursor: "pointer"
                            }
                        }} onClick={() => { return handleRoute1() }} style={{ marginLeft: "25px", color: "white", verticalAlign: "middle", }} ></TwitterIcon>
                        <CloudCircleIcon sx={{
                            "&:hover": {
                                color: "rgb(62, 158, 214) ! important",
                                cursor: "pointer"
                            }
                        }} onClick={() => { return handleRoute1() }} style={{ marginLeft: "25px", color: "white", verticalAlign: "middle", }} />
                        <PublicIcon sx={{
                            "&:hover": {
                                color: "rgb(62, 158, 214)! important",
                                cursor: "pointer"
                            }
                        }} onClick={() => { return handleRoute1() }} style={{ marginLeft: "25px", color: "white", verticalAlign: "middle", }} />
                        <LanguageIcon sx={{
                            "&:hover": {
                                color: "rgb(62, 158, 214) ! important",
                                cursor: "pointer"
                            }
                        }} onClick={() => { return handleRoute1() }} style={{ marginLeft: "25px", color: "white", verticalAlign: "middle", }} />


                    </VoteRateButton1>
                </TextInnerContainer>

            </TextContainer>
            <ButtonContainer>
                <ButtonInnerContainer>
                    <BlockMerge>
                        <VoteRateButton2>
                            {collectionButton ? <ButtonVote onClick={ButtonCollection}>Star Items</ButtonVote> : <ButtonVoted onClick={ButtonCollection}>Star Items</ButtonVoted>}
                            {earnedButton ? <ButtonRated1 onClick={EarnedButton}>All Items</ButtonRated1> : <ButtonRated onClick={EarnedButton}>All Items</ButtonRated>}
                        </VoteRateButton2>
                        <div style={{ display: "flex" }}>
                            <VoteText>
                                Recent
                            </VoteText>
                            <div style={{ marginLeft: "4px", fontSize: "1.4rem ", fontFamily: "Sofia Pro", marginTop: "37px ", color: "rgb(102, 178, 255)" }}>{location.state.name}</div>
                        </div>
                    </BlockMerge>
                    <SearchProject projectList={assets.assetsArr} dispatch={dispatch} state={State} />
                </ButtonInnerContainer>

            </ButtonContainer>
            <CardContainer1>
                <CardInnerContainer1>
                    {ploading ?
                        <div style={{ display: 'flex', width: "100%", justifyContent: "center", marginTop: "40px" }}>
                            <Spin />
                        </div> : <div>
                            {collectionButton && starItemAssets?.length == 0 && <DisplayingNone>No Star Items </DisplayingNone>}
                            <CardProjectDisplay>
                                {
                                    collectionButton ?
                                        starItemAssets?.map((item, i) => {
                                            if (i <= cardIncrement) {
                                                return (


                                                    <ProjectCard key={i + 1}>
                                                        <ProjectCardImage onClick={() => { return handleRoute(item) }} src={item?.asset?.image_url} />
                                                        <InnerCardFullContain>
                                                            <InnerCardContainer>
                                                                <CardContainerText>
                                                                    {item?.asset?.name}
                                                                </CardContainerText>
                                                            </InnerCardContainer>
                                                        </InnerCardFullContain>
                                                        <CardFooterContiner>
                                                            <RightCombine>
                                                                <FooterText>Transactions</FooterText>
                                                                <CardContainerText1>{`${item?.total_transaction}`}</CardContainerText1>
                                                            </RightCombine>
                                                            <RightCombine>
                                                                <FooterText>Fans</FooterText>
                                                                <CardContainerText1>{item?.total_fans}</CardContainerText1>
                                                            </RightCombine>
                                                            <RightCombine>
                                                                <FooterText>Tips Received</FooterText>
                                                                <CardContainerText1>{item?.tip_received ? `$${item?.tip_received}` : "-"}</CardContainerText1>
                                                            </RightCombine>
                                                            <RightCombine>
                                                                <FooterText>Tokens</FooterText>
                                                                <CardContainerText1>{item.token}</CardContainerText1>
                                                            </RightCombine>
                                                        </CardFooterContiner>
                                                    </ProjectCard>
                                                )
                                            }
                                        })



                                        : assets.assetsArr.map((item, i) => {
                                            if (i <= cardIncrement) {
                                                return (


                                                    <ProjectCard key={i + 1}>
                                                        <ProjectCardImage onClick={() => { return handleAllRoute(item) }} src={item.image_url} />
                                                        <InnerCardFullContain>
                                                            <InnerCardContainer>
                                                                <CardContainerText>
                                                                    {item.name}
                                                                </CardContainerText>
                                                            </InnerCardContainer>
                                                        </InnerCardFullContain>
                                                        <CardFooterContiner>
                                                            <RightCombine>
                                                                <FooterText>Transactions</FooterText>
                                                                <CardContainerText1>{`${item.transaction}`}</CardContainerText1>
                                                            </RightCombine>
                                                            <RightCombine>
                                                                <FooterText>Fans</FooterText>
                                                                <CardContainerText1>{item?.fan_count}</CardContainerText1>
                                                            </RightCombine>
                                                            <RightCombine>
                                                                <FooterText>Tips Received</FooterText>
                                                                <CardContainerText1>{item.tip_received}</CardContainerText1>
                                                            </RightCombine>
                                                            <RightCombine>
                                                                <FooterText>Tokens</FooterText>
                                                                <CardContainerText1>{item.tokens}</CardContainerText1>
                                                            </RightCombine>
                                                        </CardFooterContiner>
                                                    </ProjectCard>
                                                )
                                            }
                                        })


                                }
                            </CardProjectDisplay>
                        </div>}
                    <SpinContainer>
                        {loading && <Spin />}
                    </SpinContainer>

                </CardInnerContainer1>


            </CardContainer1>
            <ProjectFooterContainer>

                <ProjectFooterInner>



                    <ViewButton>
                        <ViewButtonText onClick={ViewMore}
                        >View more</ViewButtonText>
                        <img src={ViewMoreImg} />
                    </ViewButton>

                </ProjectFooterInner>
            </ProjectFooterContainer>
        </ProjectContainer >
    )
}

export default ProjectPage