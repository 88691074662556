export default {
    bpxy: "360px",
    bpxz: "480px",
    bpxs: "645px", // <=
    bpsm: "768px", // <=
    bpmd: "1024px", // <=
    bplg: "1200px", // >=
    bpxlg: "1366px", // >=
    bpxxl: "1920px", // >=
    primary: "rgba(10, 25, 41, 0.7)",
    secondary: "rgb(19, 47, 76)",
    main: "rgb(102, 178, 255)",
    mi_primary: ' rgb(102, 178, 255)',
    mi_secondary: 'rgb(19, 47, 76)',
    logo_color: "#f33535",
    mi_primary1: "#f33535",
    mi_secondary2: "#fff"

}
export const color = {

}
