export const FanInitialState = {
    fanInitialArr: [],
    fanLoading: false,
    fanArray: [],
    filteredFanArray: [],
    fanListArr: []

};
export const FanReducer = (state = FanInitialState, action) => {
    switch (action.type) {
        case 'INITIAL_FANLOADING':
            return {
                fanLoading: action.fanLoading
            }

        case 'INITIAL_FAN':

            return {
                ...state,
                fanInitialArr: action.payload,
                filteredFanArray: action.payload

            };
        case 'INITIAL_FANARR':

            return {
                ...state,
                fanListArr: action.payload

            };

        case 'INITIAL_FANWALLET':
            return {
                ...state,
                fanArray: action.WalletId

            }
        case 'INITIAL_FANWALLETID':
            return {
                ...state,
                fanArr: action.initialWalletId

            }
        case 'SEARCH_FANASSETS': {
            let filteredItem = state.filteredFanArray.filter((item) => {
                return item.name.toLowerCase().includes(action.searchText)
            })
            // if (filteredItem.length == 0) {
            //     setSearchList(true)
            //     setSearch(filteredItem)
            // }
            // else {
            //     setSearch(filteredItem)
            //     setSearchList(false)
            // }

            return {
                ...state,
                fanInitialArr: filteredItem
            }
        };
        default:
            return state
    }
}