import React from "react"
import { BodyContainer, BodyContainer2, MiddleContainer, MiddleContainer2 } from "../LandingPage/index.styled"
import { FooterFirst, FooterFirst1, FooterFlexCombine, FooterIconBox, FooterMiddleContain, FooterSubTitle, FooterTitle, FooterTitle1, MarketplaceBtn, MarketPlaceContainer } from "./index.styled"
import CommentIcon from '@mui/icons-material/Comment';
import logo from '../../../Static/Image/lohomain.svg'

import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
const FooterComponent = () => {
     return (
          <BodyContainer2>
               <FooterMiddleContain>
                    <FooterFirst>
                         <img style={{ width: "130px", height: "60px" }} src={logo} />
                    </FooterFirst>
                    <FooterFirst1>
                         <FooterTitle1>
                              Plans
                         </FooterTitle1>
                         <FooterSubTitle>
                              All Fans
                         </FooterSubTitle>
                         <FooterSubTitle>
                              Tokenomics
                         </FooterSubTitle>
                         <FooterSubTitle>
                              NFT Airdrop Tool
                         </FooterSubTitle>
                         <FooterSubTitle>
                              Reachout NFT Fans
                         </FooterSubTitle>
                    </FooterFirst1>
                    <FooterFirst>
                         <FooterTitle1>
                              How it Works
                         </FooterTitle1>
                         <FooterSubTitle>
                              For NFT Fans
                         </FooterSubTitle>
                         <FooterSubTitle>
                              For NFT Owners
                         </FooterSubTitle>
                         <FooterSubTitle>
                              For NFT Projects
                         </FooterSubTitle>
                         <FooterSubTitle>
                              Whitepaper
                         </FooterSubTitle>
                    </FooterFirst>
                    <FooterFirst>
                         <FooterTitle1>
                              About
                         </FooterTitle1>
                         <FooterSubTitle>
                              Team
                         </FooterSubTitle>
                         <FooterSubTitle>
                              Fundraising
                         </FooterSubTitle>
                         <FooterSubTitle>
                              Investors
                         </FooterSubTitle>
                         <FooterSubTitle>
                              Partners
                         </FooterSubTitle>
                    </FooterFirst>
                    <FooterFirst>
                         <FooterTitle1>
                              Dev
                         </FooterTitle1>
                         <FooterSubTitle>
                              API
                         </FooterSubTitle>
                         <FooterSubTitle>
                              Docs
                         </FooterSubTitle>
                         <FooterSubTitle>
                              Support
                         </FooterSubTitle>
                         <FooterSubTitle>
                              FAQ
                         </FooterSubTitle>
                    </FooterFirst>
                    <FooterFirst>
                         <FooterTitle1>
                              Coingecho
                         </FooterTitle1>
                         <FooterSubTitle>
                              Coinmarketcap
                         </FooterSubTitle>
                         <FooterSubTitle>
                              Chart
                         </FooterSubTitle>
                         <FooterSubTitle>
                              Audit Report
                         </FooterSubTitle>
                         <FooterSubTitle>
                              Contracts
                         </FooterSubTitle>
                         <FooterSubTitle>
                              NFN Bridges

                         </FooterSubTitle>
                         <FooterSubTitle>
                              Matric NETWORK
                         </FooterSubTitle>
                         <FooterSubTitle>
                              BSC
                         </FooterSubTitle>
                         <FooterSubTitle>
                              Fantom
                         </FooterSubTitle>
                         <FooterSubTitle>
                              Uniswap
                         </FooterSubTitle>
                    </FooterFirst>
                    {/*           <div className="row Footer_Container">
          //                <div className="col-4">
          //                     <FooterTitle>Font Community</FooterTitle>
          //                     <div>
          //                          <FooterSubTitle>Font Community is a decentralized marketplace where fonts are NFTS,Governed by DAO </FooterSubTitle>
          //                     </div>
          //                </div>
          //                <div className="col-2">
          //                     <div>
          //                          <FooterTitle>About</FooterTitle>
          //                     </div>
          //                     <div>
          //                          <FooterSubTitle>Team</FooterSubTitle>
          //                     </div>
          //                     <div>
          //                          <FooterSubTitle>Assets</FooterSubTitle>
          //                     </div>
          //                     <div>
          //                          <FooterSubTitle>Supported Token</FooterSubTitle>
          //                     </div>
          //                     <div>
          //                          <FooterSubTitle>White paper</FooterSubTitle>
          //                     </div>
          //                     <div>
          //                          <FooterSubTitle>How it work</FooterSubTitle>
          //                     </div>
          //                </div>
          //                <div className="col-2">
          //                     <div>
          //                          <FooterTitle>Token</FooterTitle>
          //                     </div>
          //                     <div>
          //                          <FooterSubTitle>BSC Font Contract</FooterSubTitle>
          //                     </div>
          //                     <div>
          //                          <FooterSubTitle>ETH/BSC Bridge</FooterSubTitle>
          //                     </div>
          //                     <div>
          //                          <FooterSubTitle>Pancake Swap</FooterSubTitle>
          //                     </div>
          //                     <div>
          //                          <FooterSubTitle>UniSwap</FooterSubTitle>
          //                     </div>
          //                     <div>
          //                          <FooterSubTitle>BSC LP Staking</FooterSubTitle>
          //                     </div>
          //                     <div>
          //                          <FooterSubTitle>Contract</FooterSubTitle>
          //                     </div>
          //                     <div>
          //                          <FooterSubTitle>CoinGecko</FooterSubTitle>
          //                     </div>
          //                     <div>
          //                          <FooterSubTitle>CoinMarketCap</FooterSubTitle>
          //                     </div>
          //                     <div>
          //                          <FooterSubTitle>Chart</FooterSubTitle>
          //                     </div>
          //                     <div>
          //                          <FooterSubTitle>Security</FooterSubTitle>
          //                     </div>
          //                </div>
          //                <div className="col-2">
          //                     <div>
          //                          <FooterTitle>Support</FooterTitle>
          //                     </div>
          //                     <div>
          //                          <FooterSubTitle>API</FooterSubTitle>
          //                     </div>
          //                     <div>
          //                          <FooterSubTitle>FAQ</FooterSubTitle>
          //                     </div>
          //                     <div>
          //                          <FooterSubTitle>Support</FooterSubTitle>
          //                     </div>
          //                     <div>
          //                          <FooterSubTitle>Github</FooterSubTitle>
          //                     </div>
          //                </div>
          //                <div className="col-2">
          //                     <MarketPlaceContainer>
          //                          <MarketplaceBtn>
          //                               <FooterTitle style={{ position: "relative", top: "8px" }}>MARKET PLACE</FooterTitle>
          //                          </MarketplaceBtn>
          //                          <FooterFlexCombine>
          //                               <FooterIconBox>
          //                                    <YouTubeIcon style={{ color: "white" }} />
          //                               </FooterIconBox>
          //                               <FooterIconBox>
          //                                    <TwitterIcon style={{ color: "white" }} />
          //                               </FooterIconBox>
          //                               <FooterIconBox>
          //                                    <TelegramIcon style={{ color: "white" }} />
          //                               </FooterIconBox>
          //                               <FooterIconBox>
          //                                    <LinkedInIcon style={{ color: "white" }} />
          //                               </FooterIconBox>
          //                          </FooterFlexCombine>
          //                          <FooterFlexCombine>
          //                               <FooterIconBox>
          //                                    <WhatsAppIcon style={{ color: "white" }} />
          //                               </FooterIconBox>
          //                               <FooterIconBox>
          //                                    <FacebookIcon style={{ color: "white" }} />
          //                               </FooterIconBox>
          //                               <FooterIconBox>
          //                                    <CommentIcon style={{ color: "white" }} />
          //                               </FooterIconBox>
          //                               <FooterIconBox>
          //                                    <InstagramIcon style={{ color: "white" }} />
          //                               </FooterIconBox>
          //                          </FooterFlexCombine>
          //                     </MarketPlaceContainer>
          //                </div>

          //           </div>*/}

               </FooterMiddleContain>
          </BodyContainer2 >
     )
}

export default FooterComponent