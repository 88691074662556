import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './context/AppContext';
import { MoralisProvider } from 'react-moralis';

const serverUrl = "https://edrpyrgiqr2a.usemoralis.com:2053/server";
const appId = "R607aEJi0FNisAEYp70FYiF9UPSBH7136EFy4vvb";

ReactDOM.render(
  <MoralisProvider  appId={appId} serverUrl={serverUrl}>
    <AppProvider>
      <App />
    </AppProvider>
  </MoralisProvider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
