
import { makeStyles } from "@mui/styles"
import React from "react"
import { Modal } from "react-bootstrap"
import { TokenData } from "../../../../../../utils/Token"
import SearchBar from "../../../../LandingPage/Search"
import { HorizontalLine, InnerRootContainer, Name, RightContainer, RootContainer, SubTitle, Transaction } from "./index.styled"


const useStyle = makeStyles({
    root: {
        background: "rgb(10, 25, 41)",
    },
    header: {
        display: "flex",
        flex: 1,
        width: "100%"
    }
})

export const ModalComponent = ({ open, handleClose, setTokenValue, setTokenList, from, setTokenItem }) => {

    const classes = useStyle()
    const [search, setSearch] = React.useState('')

    const handleSelect = (value, token_id, image) => {
        // if (from == "token") {
        //     let TokenDetails = TokenData.filter((item) => {
        //         return item.token_id == token_id
        //     })
        //     setTokenValue(TokenDetails)
        // } 

        setTokenValue(value)
        handleClose()
        setTokenList(token_id)
        setTokenItem(image)

    }

    return (
        <React.Fragment>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={open}
                onHide={handleClose}
                backdrop={true}

            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div >
                            <SearchBar setSearch={setSearch} />
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body onScroll={true}>
                    {TokenData.map((node, i) => {
                        return (
                            <RootContainer key={i + 1} onClick={() => { return handleSelect(node.name, node.token_id, node.image) }}>
                                <InnerRootContainer>
                                    <div style={{ width: "50%", }} >
                                        <div className="row">
                                            <div style={{ display: 'flex', flexDirection: "row" }}>
                                                <Name>{node.name}</Name>
                                            </div>
                                            <div>
                                                <SubTitle>{node.transaction}</SubTitle>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: "22%", }} >
                                        <div className="row">
                                            <div style={{ display: 'flex', flexDirection: "row" }}>
                                                <Name>{node.payment}</Name>
                                            </div>
                                            <div>
                                                <Transaction>{node.id}</Transaction>
                                            </div>
                                        </div>
                                    </div>
                                </InnerRootContainer>
                                {/* <HorizontalLine/> */}
                            </RootContainer>
                        )
                    })}
                </Modal.Body>
                {/* <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer> */}
            </Modal>
        </React.Fragment>
    )
}