import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Link, useNavigate } from "react-router-dom";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from '@material-ui/core';
import ethereumImg from '../../../Static/Image/ethereum.svg'
import { useMoralis } from "react-moralis";
import { useAppContext } from "../../../context/AppContext";


const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: "50px",
        color: 'rgb(62, 158, 214)',

        backgroundColor: "rgb(25,31,40)",
        // theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],

        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: "14px",
                fontFamily: "Sofia Pro",
                marginTop: "5px",
                color: `${props => props.theme.primary}`,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: "rgb(25,31,40)",


            },
        },
    },
}));

const useStyles = makeStyles((theme) => ({
    ButtonDrop: {
        width: "130px",
        backgroundColor: "#263143",
        border: "none",
        fontSize: "16px",
        fontFamily: "Sofia Pro",

        outline: "none",
        color: "#94A1B7",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center"
    }
}))

export default function DropDownRight({ setDropDownItem, setDropDownItem1, setDropDownList, wallet_id }) {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate()
    const { logout } = useMoralis()
    const { dispatch } = useAppContext()
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)

    };
    const handleDisconnect = async () => {
        await logout()
        return dispatch({
            type: "AUTH_LOGOUT"
        })
    }
    const handleClose = () => {
        setAnchorEl(null);
        setDropDownItem(false)
        setDropDownList(true)
    };
    const handleNavigate = () => {

        setAnchorEl(null);
        setDropDownItem(false)
        setDropDownList(true)
        navigate(`/profile`)
    }

    return (

        <div>

            <button className={classes.ButtonDrop}
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {wallet_id.slice(0, 6) + "..."}
                <img src={ethereumImg} />

            </button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            > <MenuItem onClick={handleNavigate} >

                    Profile
                </MenuItem>
                <MenuItem onClick={handleDisconnect} >

                    Disconnect
                </MenuItem>


            </StyledMenu>
        </div>
    );
}