import React, { useState, useEffect, useCallback } from 'react'
import { useLocation, useParams, } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { Spin } from 'antd';
import 'antd/es/spin/style/css';
import { AmazingText, BoxBottom, BoxBottomText, BoxInput, ButtonBox, ContainerButton, ContainerImage, DollerButton, EthButton, EthButton1, FooterBottomText, FooterBottomText2, FooterBoxBlock, FooterBoxHeader, FooterBoxMiddle, FooterFlex, FooterHeadText, FooterHeadText2, H1Tag, ImageContainer, ImageFooterBox, ImageInnerContainer, InfoButton, InputBox, MainImgBox, NftCardText, NftCardText1, OverFlowContain, OwnerBlock, OwnerButton, OwnerContent, OwnerDownText, OwnerDownText2, OwnerImage, OwnerImgContainer, OwnerImgContainer1, OwnerImgContainer2, OwnerImgContainer3, OwnerLastText, OwnerText, PersonImage, PlaceButton, RewardImg, RightContainer, Selectable, StockText, UrlText } from './index.styled'
import Skeleton from '@mui/material/Skeleton';
import PersonImg from '../../../../../Static/Image/person2.jpeg'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { ModalComponent } from "./Modal/index"
import { Formik } from 'formik';
import dateFormat from "dateformat"
import makePostRequest from '../../../../../api/PostApi';
import makeGetRequest from '../../../../../api/GetApi';
import ModelBox from './ModelBox';
import { SpinContainer1 } from './Modal/index.styled';
import { useAppContext } from '../../../../../context/AppContext';
import { TokenData } from '../../../../../utils/Token';
import VerifiedIcon from '@mui/icons-material/Verified';

import toast, { Toaster } from "react-hot-toast";
import { useMoralis } from 'react-moralis';
import { useConnect } from '../../../../helper/useConnect';



function ImagePage() {
    const { state } = useLocation()
    const { id } = useParams()
    const navigate = useNavigate()
    const [tips, setTips] = useState(true)
    const [fans, setFans] = useState(false)
    const [tokens, setTokens] = useState(false)
    const [claim, setClaim] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [tokenValue, setTokenValue] = React.useState("")
    const [amount, setAmount] = React.useState("")
    const { isAuthenticated, user } = useMoralis()
    const [show, setShow] = useState(false);
    const [fanCount, setFancount] = useState()
    const [tokenItem, setTokenItem] = useState([])
    const [ploading, setpLoading] = useState(false)
    const UseConnectFunction = useConnect()
    const [tokenList, setTokenList] = useState([])
    const { State, dispatch } = useAppContext()
    const { assets } = State
    const { token } = State
    // const [TotalFan,setTotalFan] = useState([])

    const [modelState, setModelState] = useState({
        model: {
            title: "",
            Icon: null,
            content: "",
            open: false,
            footerbtnText: ""
        },
        transaction: ""
    })
    let now = new Date()
    let date = dateFormat(now, "yyyy-MM-dd HH:mm:ssSZZ")

    const handleClosed = () => {
        setModelState({
            model: {
                ...state,
                open: false
            }
        })
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(!open);
    };
    const TipsFun = () => {
        setTips(true)
        setFans(false)
        setTokens(false)
        setClaim(false)
    }
    const FansFun = () => {
        setTips(false)
        setFans(true)
        setTokens(false)
        setClaim(false)
    }
    const TokensFun = () => {
        setTips(false)
        setFans(false)
        setTokens(true)
        setClaim(false)
    }
    const ClaimFun = () => {
        setTips(false)
        setFans(false)
        setTokens(false)
        setClaim(true)
    }
    const handleShow = (e) => {
        e.preventDefault()
        console.log(tokenList)
        if (tokenList.length == 0 && amount.length == 0) {
            console.log("Yesss")
            toast.error("Token and Amount is required", {
                style:
                {
                    border: "1px solid rgb(19, 47, 76)",
                    position: "relative",
                    top: "80px",
                    background: "rgb(19, 47, 76)",
                    color: "rgb(102, 178, 255)"
                }
            })

            setShow(false)

        }
        else if (tokenList.length == 0) {
            console.log("Yesss")
            toast.error("Token is required", {
                style:
                {
                    border: "1px solid rgb(19, 47, 76)",
                    position: "relative",
                    top: "80px",
                    background: "rgb(19, 47, 76)",
                    color: "rgb(102, 178, 255)"
                }
            })

            setShow(false)

        }
        else if (amount.length == 0) {
            toast.error("Amount is requied", {
                style:
                {
                    border: "1px solid rgb(19, 47, 76)",
                    position: "relative",
                    top: "80px",
                    background: "rgb(19, 47, 76)",
                    color: "rgb(102, 178, 255)"
                }
            })

            setShow(false)
        } else {
            let tokens = TokenData.find((item) => {
                return item.token_id === tokenList

            })
            let TokenDetails = TokenData.filter((item) => {
                return item.token_id === tokenList
            })
            dispatch({ type: 'INITIAL_TOKENLOADING', TokenLoading: true })
            console.log("theree")
            dispatch({
                type: 'INITIAL_TOKEN', payload: tokens,
            })
            console.log(token.TokenArr)

            setModelState({
                model: {
                    ...state,
                    open: true,
                    title: "Confirmation",
                    Icon: <VerifiedIcon style={{ color: "rgb(0,30,60)" }} />,
                    content: "Are you surley want to tip this assets",
                    footerbtnText: "Tip Now"
                }
            })
        }
    }





    const getTipsNetwork = useCallback(async () => {
        let TotalFan = []
        await dispatch({ type: 'INITIAL_LOADING', ploading: true })
        let tipss = await makeGetRequest(`get/assetsDetails/${id}`)
        console.log(tipss)
        let FanCount = tipss?.assets[0]?.fans?.map((node_items) => {
            if (TotalFan?.some((value) => { return value.wallet_id == node_items.wallet_id })) {

                return TotalFan
            }
            else {
                return TotalFan.push(node_items)
            }
        })
        setFancount(TotalFan)

        console.log(TotalFan)

        dispatch({ type: 'INITIAL_TIPARRAY', payload: tipss.assets, ploading: false })
        if (tipss?.assets?.[0].fans?.length > 0) {
            let transaction = tipss?.assets?.[0].fans?.reduce((a, b) => {
                return a + b.amount
            }, 0)
            setModelState({
                ...state,
                transaction: transaction
            })
        }
    }, [])


    useEffect(async () => {
        window.scrollTo(0, 0)
        await getTipsNetwork()
    }, [])

    let handleNavigate = () => {
        navigate(`/project/${state.project_id}`)
    }

    let handleTip = async () => {
        await dispatch({ type: 'INITIAL_LOADING', ploading: true })

        let obj = { tokenValue, amount }
        let Tipdata = { asset_id: assets?.TipAssetArr[0]?.id, project_id: assets?.TipAssetArr[0]?.project_id, created_at: now, updated_at: now, wallet_id: user.attributes.ethAddress }
        let tip = Object.assign(obj, Tipdata)
        let obj1 = { tokenValue, amount }
        let fanData = { asset_id: assets?.TipAssetArr[0]?.id, project_id: assets?.TipAssetArr[0]?.project_id, created_at: date, updated_at: date, wallet_id: user.attributes.ethAddress }
        let fan = Object.assign(obj1, fanData)


        let tipResponse = await makePostRequest("post/tip", tip)
        let fanResponse = await makePostRequest("post/fan", fan)

        await getTipsNetwork()


    }

    let handleAmount = (e) => {
        let amount = e.target.value
        if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {

            setAmount(amount)
        }
    }

    const AuthenticateModal = () => {
        return setModelState({
            model: {
                ...state,
                open: true,
                title: "Warning",
                Icon: <VerifiedIcon style={{ color: "rgb(0,30,60)" }} />,
                content: "Are you surley want to tip this assets , you should connect",
                footerbtnText: "Connect"
            }
        })
    }

    const handleConnect = () => {
        UseConnectFunction()
        setModelState({
            model: {
                ...state,
                open: false
            }
        })
    }



    return (
        <>
            <ImageContainer>
                {/* <BackButton onClick={() => navigate(-1)}>
                <LeftOutlined style={{ fontSize: "18px", color: "white" }} />
            </BackButton> */}
                <Toaster position="top-center" />
                <ImageInnerContainer>
                    {console.log(assets.TipAssetArr)}
                    {assets.TipAssetArr.length > 0 ?
                        <ContainerImage src={assets?.TipAssetArr[0]?.image_url} >

                        </ContainerImage> :
                        <Skeleton variant="rectangular" style={{
                            width: "50.8%",
                            height: "767px",
                            borderRadius: "16px",
                            verticalAlign: "middle ",
                            marginRight: "30px ",
                            background: "rgb(19, 47, 76)",
                            marginTop: "40px ",
                            marginBottom: "50px ",
                            position: "relative"
                        }} />
                    }
                    <RightContainer>
                        {assets.TipAssetArr.length > 0 ? <AmazingText>{assets.TipAssetArr[0]?.asset_contract.address.substring(0, 16) + "..."}</AmazingText> :
                            <Skeleton variant="text" width={420} height={60} style={{ background: "rgb(19, 47, 76)", borderRadius: "5px", }} />}
                        {assets.TipAssetArr.length > 0 ? <NftCardText1 onClick={handleNavigate}>{assets.TipAssetArr[0]?.name}</NftCardText1> :
                            <Skeleton variant="text" width={140} style={{ background: "rgb(19, 47, 76)", borderRadius: "5px", }} />}
                        <ContainerButton>
                            <EthButton>
                                {`${fanCount && fanCount.length} Fans`}
                            </EthButton>
                            <DollerButton>{modelState.transaction ? `$${modelState.transaction}` : null}</DollerButton>
                            <EthButton1>{assets.TipAssetArr[0]?.fans ? `${assets.TipAssetArr[0].fans?.length} Transaction` : '-'}</EthButton1>
                        </ContainerButton>
                        {assets.TipAssetArr[0]?.descripition &&
                            <NftCardText>
                                {assets.TipAssetArr[0]?.description}
                            </NftCardText>}
                        <UrlText>
                            https://ui8.net
                        </UrlText>
                        <ButtonBox>
                            {tips ? <InfoButton onClick={TipsFun}>Tips</InfoButton> : <OwnerButton onClick={TipsFun}>Tips</OwnerButton>}
                            {fans ? <InfoButton onClick={FansFun}>Fans</InfoButton> : <OwnerButton onClick={FansFun}>Fans</OwnerButton>}
                            {tokens ? <InfoButton onClick={TokensFun}>Tokens</InfoButton> : <OwnerButton onClick={TokensFun}>Tokens</OwnerButton>}
                            {claim ? <InfoButton onClick={ClaimFun}>Claim</InfoButton> : <OwnerButton onClick={ClaimFun}>Claim</OwnerButton>}
                        </ButtonBox>
                        {assets.TipAssetloading && <SpinContainer1><Spin /></SpinContainer1>}



                        {tips && !assets?.TipAssetloading && <OverFlowContain className='scrollhost'>
                            {!assets?.TipAssetloading && assets?.TipAssetArr?.[0]?.tips == 0 && tips &&
                                <OwnerImgContainer3>
                                    <OwnerDownText2> no tips </OwnerDownText2>
                                </OwnerImgContainer3>}

                            {assets.TipAssetArr.length > 0 && assets?.TipAssetArr[0]?.tips.map((item, i) => {
                                return (

                                    <OwnerImgContainer1 key={i + 1}>
                                        <OwnerContent>
                                            {/* <AccountCircleIcon style={{ fontSize: "50px", color: "white" }} /> */}
                                            <PersonImage src={PersonImg} />
                                            <OwnerBlock>
                                                <OwnerText>{item.wallet_id.slice(0, 21)}</OwnerText>
                                                <OwnerDownText>{`$${item.amount}`}</OwnerDownText>
                                            </OwnerBlock>
                                        </OwnerContent>

                                        <OwnerLastText>{item.updated_at.slice(0, 10)}</OwnerLastText>
                                    </OwnerImgContainer1>

                                )
                            })}</OverFlowContain>}
                        {fans && !assets.TipAssetloading && <OverFlowContain className='scrollhost'>
                            {!assets.TipAssetloading && assets?.TipAssetArr[0].fans == 0 && fans &&
                                <OwnerImgContainer3>
                                    <OwnerDownText2> no fans </OwnerDownText2>
                                </OwnerImgContainer3>}
                            {assets.TipAssetArr && assets?.TipAssetArr[0]?.fans.map((item, i) => {

                                return (
                                    <OwnerImgContainer1 key={i + 1}>
                                        <OwnerContent>
                                            {/* <AccountCircleIcon style={{ fontSize: "50px", color: "white" }} /> */}
                                            <PersonImage src={PersonImg} />
                                            <OwnerBlock>
                                                <OwnerText>{item.token}</OwnerText>
                                                <OwnerDownText>{item.wallet_id.slice(0, 21)}</OwnerDownText>
                                            </OwnerBlock>
                                        </OwnerContent>
                                        <OwnerLastText>{item.updated_at.slice(0, 10)}</OwnerLastText>
                                    </OwnerImgContainer1>

                                )
                            })}
                        </OverFlowContain>
                        }
                        {tokens && !assets.TipAssetloading && <OverFlowContain className='scrollhost'>
                            {!assets.TipAssetloading && token?.TokenArr == 0 && fans &&
                                <OwnerImgContainer3>
                                    <OwnerDownText2> no tokens </OwnerDownText2>
                                </OwnerImgContainer3>}
                            {token.TokenArr?.length > 0 && token.TokenArr?.map((item, i) => {
                                return (
                                    <OwnerImgContainer1 key={i + 1}>
                                        <OwnerContent>
                                            {/* <AccountCircleIcon style={{ fontSize: "50px", color: "white" }} /> */}
                                            <PersonImage src={item.image} />
                                            <OwnerBlock>
                                                <OwnerText>{item.name}</OwnerText>
                                                <OwnerDownText>{item.payment}</OwnerDownText>
                                            </OwnerBlock>
                                        </OwnerContent>
                                        <OwnerBlock>
                                            <OwnerText>{item.transaction}</OwnerText>
                                            <OwnerDownText>{item.id}</OwnerDownText>
                                        </OwnerBlock>
                                    </OwnerImgContainer1>
                                )
                            })}
                        </OverFlowContain>
                        }
                        {claim &&
                            <OverFlowContain className='scrollhost'>
                                <OwnerImgContainer2>
                                    <OwnerDownText>Coming Soon</OwnerDownText>
                                </OwnerImgContainer2>
                            </OverFlowContain>}
                        <Formik initialValues={{ token: "", amount: "" }} onSubmit={(values) => { console.log(values); }}>
                            {(handleChange, handleSubmit) => {
                                return (
                                    <ImageFooterBox>
                                        <form>
                                            <FooterBoxHeader>
                                                <FooterBoxBlock>

                                                    <FooterFlex>

                                                        <div style={{ width: "100%" }}>
                                                            <div>
                                                                <Selectable onClick={handleOpen} handleClose={handleClose} from="tip">
                                                                    <OwnerText>{tokenValue ? tokenValue : ' Select Token'}</OwnerText>
                                                                </Selectable>
                                                            </div>
                                                            <div>
                                                                <InputBox placeholder='Enter amount...' value={amount} onChange={(e) => { return handleAmount(e) }}></InputBox>
                                                            </div>

                                                        </div>


                                                    </FooterFlex>
                                                </FooterBoxBlock>
                                            </FooterBoxHeader>
                                            <FooterBoxHeader>
                                                <FooterBoxMiddle onClick={(e) => {
                                                    return isAuthenticated ? handleShow(e) : AuthenticateModal()
                                                }} type="button">Tip Now</FooterBoxMiddle>

                                            </FooterBoxHeader>
                                        </form>
                                        <FooterFlex>
                                            <BoxBottom>Tip this Nft with ETH or any ERC20 Tokens</BoxBottom>


                                        </FooterFlex>
                                    </ImageFooterBox>
                                )
                            }}
                        </Formik>
                    </RightContainer>
                </ImageInnerContainer>

            </ImageContainer >
            <ModalComponent open={open} handleClose={handleClose} setTokenValue={setTokenValue} setTokenList={setTokenList} from="token" setTokenItem={setTokenItem} />

            <ModelBox show={modelState?.model?.open} modalbody={modelState?.model} handleClosed={handleClosed} handleFunction={isAuthenticated ? handleTip : handleConnect} />
        </>
    )
}

export default ImagePage