import React from 'react'
import { BoxMiddle, CardContainer, DropDown, HashText, HeaderMiddleText, InnerTableHead, NameText, RankingBodyButton, RankingBodyButtonText, RankingBodyButtonText2, RankingContainer, RankingHeader, RankingImage, RankingMiddleContainer, SerialNo, TableContent, TableHead, TextHead, TextHeadFloor, TextHeadVotes, CardIcon, CardName, CardNameText, CardImageName, CardButtonLaunched, VolumeContainer, VolumeContent, FloorContainer, TwitterContainer, DisacardContainer, DiscardContainer, VoteButton, VotesContainer, } from './index.styled'
import RankingCardImg from '../../../Static/Image/RankingCardImage.jpg_large'
import RedDropDown from '../../../Static/Image/DownDropdown.svg'
import Card6Image from '../../../Static/Image/card6Img.jpg'
import BlueVerified from '../../../Static/Image/BlueVerified.png'
import RankingData from '../../../utils/RankingData.json'
import ViewMoreImg from '../../../Static/Image/viewMore.svg'
import { BodyFooterContainer, BodyFooterText, ViewButton, ViewButtonText, } from '../LandingPage/index.styled'


function RankingPage() {
    return (
        <RankingContainer>
            <RankingMiddleContainer>
                <RankingHeader>
                    Discover trending NFT projects
                </RankingHeader>
                <HeaderMiddleText>
                    Be the first to find new, trending & upcoming NFTs projects!
                </HeaderMiddleText>
                <CardContainer>
                    <RankingImage src={RankingCardImg} />
                    <RankingImage src={RankingCardImg} />
                    <RankingImage src={RankingCardImg} />
                </CardContainer>
                <BoxMiddle>
                    <RankingBodyButton>
                        <RankingBodyButtonText>Today</RankingBodyButtonText>
                        <RankingBodyButtonText2>Tomorrow</RankingBodyButtonText2>
                        <RankingBodyButtonText2>7 Days</RankingBodyButtonText2>
                        <RankingBodyButtonText2>30 Days</RankingBodyButtonText2>
                    </RankingBodyButton>
                </BoxMiddle>
                <TableHead>
                    <InnerTableHead>
                        <HashText>#</HashText>
                        <NameText>Name</NameText>
                        <TextHead>Status</TextHead>
                        <TextHead>Volume</TextHead>
                        <TextHeadFloor>Floor</TextHeadFloor>
                        <TextHead>Size</TextHead>
                        <TextHead>Twitter</TextHead>
                        <TextHead>Discard</TextHead>
                        <TextHeadVotes>Votes</TextHeadVotes>
                        <TextHead>Vote</TextHead>
                    </InnerTableHead>
                </TableHead>
                {RankingData.map((item) => {
                    return (
                        <TableContent>
                            <SerialNo>{item.Serialno}</SerialNo>
                            <DropDown src={RedDropDown} />
                            <CardIcon src={require(`../../../Static/Image/${item.img}`)} />
                            <CardName>
                                <CardNameText>{item.name}</CardNameText>
                                <CardImageName src={BlueVerified} />
                            </CardName>
                            <CardButtonLaunched>
                                Launched
                            </CardButtonLaunched>
                            <VolumeContainer>
                                <VolumeContent>{item.Volume}</VolumeContent>
                            </VolumeContainer>
                            <FloorContainer>
                                <VolumeContent>{item.Floor}</VolumeContent>
                            </FloorContainer>
                            <TwitterContainer>
                                <VolumeContent>{item.Size}</VolumeContent>
                            </TwitterContainer>
                            <DiscardContainer>
                                <VolumeContent>{item.Twitter}</VolumeContent>
                            </DiscardContainer>
                            <FloorContainer>
                                <VolumeContent>{item.Discard}</VolumeContent>
                            </FloorContainer>
                            <VotesContainer>
                                <VolumeContent>{item.votes}</VolumeContent>
                            </VotesContainer>
                            <VoteButton>Vote</VoteButton>
                        </TableContent>
                    )
                })}
                <BodyFooterContainer>
                    <BodyFooterText>
                        You're seeing 12 of 29 projects
                    </BodyFooterText>
                    <ViewButton>
                        <ViewButtonText>View more</ViewButtonText>
                        <img src={ViewMoreImg} />
                    </ViewButton>
                </BodyFooterContainer>
            </RankingMiddleContainer>
        </RankingContainer>
    )
}

export default RankingPage