import React, { useEffect, useState } from 'react'
import { Spin } from 'antd';
import 'antd/es/spin/style/css';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { BodyContainer, BodyFooterContainer, BodyFooterText, BodyHeader, BodyText, Box, ButtonContainer, ButtonFlex, ButtonMergeContainer, CalenderImage, Card, CardDisplay, CardImage, CardMiddle, CardMiddleText, CardtextHead, CombineContent, CombineFlex, MiddleCardContainer, MiddleCardContainer2, MiddleContainer, MiddleInnerContain, MiddleInnerContain2, Rating, Text, TwitterButton, ViewButton, ViewButtonText, ViewsButton, WebsiteButton, WebsiteText, NameText1, TextHead1, TextHeadFloor1, CardIcon1, CardName1, CardButtonLaunched1, VolumeContainer1, FloorContainer1, FanText, SpinContainer, TextHead2, FanText1, SerialNo1, CardIcon2, CardName2, CardButtonLaunched2, VolumeContainer2, FloorContainer2, NameText3, FanText3, TextHeadFloor2, VolumeContainer3, Nametext, BodyFooterContainer1, HashText2, BackButton, BodyHeader1, NameText5, ResponsiveContainer } from './index.styled'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import ViewMoreImg from '../../../Static/Image/viewMore.svg'
import SearchBar from "../LandingPage/Search";
import { BoxMiddle, CardContainer, DropDown, HashText, FilterBox, HeaderMiddleText, InnerTableHead, NameText, RankingBodyButton, RankingBodyButtonText, RankingBodyButtonText2, RankingContainer, RankingHeader, RankingImage, RankingMiddleContainer, SerialNo, TableContent, TableHead, TextHead, TextHeadFloor, TextHeadVotes, CardIcon, CardName, CardNameText, CardImageName, CardButtonLaunched, VolumeContainer, VolumeContent, FloorContainer, TwitterContainer, DisacardContainer, DiscardContainer, VoteButton, VotesContainer, TableContainer, FilterContainer, FilterName, CardNameText1 } from '../RankingPage/index.styled'
import BlueVerified from '../../../Static/Image/BlueVerified.png'

import { useAppContext } from '../../../context/AppContext';
import { Toaster } from 'react-hot-toast';
import makeGetRequest from '../../../api/GetApi';
import ResponsivePage from './ResponsivePage';

function LandingPage() {
  const [cardIncrement, setCardIncrement] = useState(9)
  const [loading, setLoading] = useState(false)

  const [ploading, setpLoading] = useState(false)
  const [querys, setQuery] = useState()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  let query = searchParams.get(querys)
  const searchQuery = location.search.slice(1).split("=")[1]
  const [projectList, setProjectList] = React.useState([])


  const { State, dispatch } = useAppContext()

  const { assets, project } = State


  const ViewMore = () => {
    setLoading(true)

    setTimeout(() => {
      setLoading(false)
      setCardIncrement(cardIncrement + 10)
      window.scrollBy(0, -350);


    }, 1000)
  }


  const projectCalculation = async (project) => {
    //TableContent
    const Fans = await makeGetRequest('get/fan')
    let projects = []
    project.map((item) => {
      let particularFans = Fans?.fan?.filter((items) => { return items.project_id === item.id })
      if (particularFans.length > 0) {
        let tippedValue = particularFans?.reduce((tipA, tipB) => {
          return tipA + tipB.amount
        }, 0)
        let a = {}
        a = { ...item }
        a["no_fans"] = particularFans.length
        a["tippedValue"] = `$${tippedValue}`
        return projects.push(a)
      }
      else {
        let a = {}
        a = { ...item }
        a["no_fans"] = particularFans.length
        a["tippedValue"] = "-"
        return projects.push(a)
      }

    })
    return projects

  }
  const getApi = async () => {
    setpLoading(true)
    // Project Full Table Content
    const result = await makeGetRequest('get/project')
    if (result.project.length > 0) {
      let projectArray = await projectCalculation(result.project)
      setpLoading(false)
      dispatch({ type: "INITIAL_PROJECT", payload: projectArray })
    }
    else {
      setpLoading(false)
      return setProjectList([])
    }
  }

  const getSearch = async (value) => {
    dispatch({ type: "INITIAL_PROJECT", payload: true })
    const result = await makeGetRequest('get/project')
    const projectsearchData = result?.project?.filter((item) => {
      return item.name.toLowerCase().includes(value)

    })
    let FilteredProjects = await projectCalculation(projectsearchData)
    console.log(FilteredProjects, "ghhjgjhgjhg")
    return dispatch({ type: "INITIAL_PROJECT", payload: FilteredProjects })
  }

  useEffect(() => {


    if (searchQuery !== null && searchQuery !== "" && searchQuery) {
      return getSearch(searchQuery)
    }
    else {
      return getApi()
    }
  }, [searchQuery])




  const handleAscendingOrder = (values) => {  //To align in \Ascending order
    if (values === "Project") {  //Based on Project Name
      setpLoading(true)
      if (project.projectArr.length > 0) {


        let totalProject = project.projectArr.sort(function (a, b) {
          const name1 = a.name.toLowerCase();
          const name2 = b.name.toLowerCase();
          let comparison = 0;
          if (name1 < name2) {
            comparison = -1;
          } else if (name1 > name2) {
            comparison = 1;
          }
          return comparison;
        })
        setTimeout(() => {
          setpLoading(false)
        }, 500)
        dispatch({ type: "CHANGE_ASSETS", payload: totalProject })

      }
    }
    else if (values === "Total Supply") {  //Based on Total Supply
      setpLoading(true)
      if (project.projectArr.length > 0) {
        let TotalNft = project.projectArr.sort((a, b) => {
          return a.Nftcount - b.Nftcount
        })
        setTimeout(() => {
          setpLoading(false)
        }, 500)
        dispatch({ type: "CHANGE_ASSETS", payload: TotalNft })


      }
    }
    else if (values === "Fans") {      //Based on Fans  
      setpLoading(true)
      if (project.projectArr.length > 0) {
        let TotalFan = project.projectArr.sort((a, b) => {
          return a.no_fans - b.no_fans
        })
        setTimeout(() => {
          setpLoading(false)
        }, 500)
        dispatch({ type: "CHANGE_ASSETS", payload: TotalFan })


      }
    }
    else if (values === "Tipped Value") {  //Based on Tipped Value
      setpLoading(true)
      if (project.projectArr.length > 0) {
        let TotalDonation = project.projectArr.sort((a, b) => {
          return a.Floor - b.Floor
        })
        setTimeout(() => {
          setpLoading(false)
        }, 500)
        dispatch({ type: "CHANGE_ASSETS", payload: TotalDonation })

      }
    }
    else if (values === "Tipped NFTs") {   //Based on NFTs
      setpLoading(true)
      if (project.projectArr.length > 0) {
        let TotalTippedNfts = project.projectArr.sort((a, b) => {
          return a.no_fans - b.no_fans
        })
        setTimeout(() => {
          setpLoading(false)
        }, 500)
        dispatch({ type: "CHANGE_ASSETS", payload: TotalTippedNfts })


      }
    }

  }

  const handleDesendingOrder = (values) => {  //To align in Descending Order
    if (values === "Project") {   //Based on Project Name
      if (values === "Project") {
        setpLoading(true)
        if (project.projectArr.length > 0) {
          let totalProject = project.projectArr.sort((a, b) => {
            const name1 = a.name.toLowerCase();
            const name2 = b.name.toLowerCase();
            let comparison = 0;
            if (name1 < name2) {
              comparison = 1;
            } else if (name1 > name2) {
              comparison = -1;
            }
            return comparison;
          })
          setTimeout(() => {
            setpLoading(false)
          }, 500)
          dispatch({ type: "CHANGE_ASSETS", payload: totalProject })

        }
      }
    }
    else if (values === "Total Supply") {  //Based on Total Supply
      setpLoading(true)

      if (project.projectArr.length > 0) {
        let TotalNft = project.projectArr.sort((a, b) => {
          return b.Nftcount - a.Nftcount
        })
        setTimeout(() => {
          setpLoading(false)
          dispatch({ type: "CHANGE_ASSETS", payload: TotalNft })


        }, 500)

      }
    }
    else if (values === "Fans") {     //Based on Fans
      setpLoading(true)
      if (project.projectArr.length > 0) {
        let TotalFan = project.projectArr.sort((a, b) => {
          return b.no_fans - a.no_fans
        })
        setTimeout(() => {
          setpLoading(false)
        }, 500)
        dispatch({ type: "CHANGE_ASSETS", payload: TotalFan })


      }
    }
    else if (values === "Tipped Value") {    //Based on Tipped Value
      setpLoading(true)
      if (project.projectArr.length > 0) {
        let TotalDonation = project.projectArr.sort((a, b) => {
          return b.Floor - a.Floor
        })
        setTimeout(() => {
          setpLoading(false)
        }, 500)
        dispatch({ type: "CHANGE_ASSETS", payload: TotalDonation })


      }
    }
    else if (values === "Tipped NFTs") {   //Based on Tipped Nfts
      setpLoading(true)
      if (project.projectArr.length > 0) {
        let TotalTippedNfts = project.projectArr.sort((a, b) => {
          return b.no_fans - a.no_fans
        })
        setTimeout(() => {
          setpLoading(false)
        }, 500)
        dispatch({ type: "CHANGE_ASSETS", payload: TotalTippedNfts })


      }
    }
  }
  const handleAscChange = (e) => {
    handleAscendingOrder(e)
  }
  const handleDescChange = (e) => {
    handleDesendingOrder(e)
  }
  const handleRoute = (value) => {
    navigate(`/project/${value.id}`, { state: value })
  }

  return (
    <BodyContainer>
      <MiddleContainer>


        <BodyHeader1>Projects</BodyHeader1>
        <BodyText>
          Discover the hottest nft projects right now!
        </BodyText>
        <SearchBar projectList={assets.assetsArr} queryname="projectname" setQuery={setQuery} />

        <br />
        <TableContainer>
          <TableHead>
            <InnerTableHead> {/* Displaying Table Header*/}
              <HashText2>#</HashText2>
              <NameText5>Project <ArrowUpwardIcon style={{ color: "#94A1B7", fontSize: "15px", top: "4px", left: "5px", position: "relative", cursor: "pointer", right: "5px" }} onClick={(e) => { return handleAscChange("Project") }} /> <ArrowDownwardIcon style={{ color: "#94A1B7", fontSize: "15px", position: "relative", top: "4px", left: "5px", cursor: "pointer", right: "5px" }} onClick={() => { return handleDescChange('Project') }} /> </NameText5>

              <Nametext>Total Supply <ArrowUpwardIcon style={{ color: "#94A1B7", fontSize: "15px", top: "4px", left: "5px", position: "relative", cursor: "pointer", right: "5px" }} onClick={(e) => { return handleAscChange("Total Supply") }} /> <ArrowDownwardIcon style={{ color: "#94A1B7", fontSize: "15px", position: "relative", top: "4px", left: "5px", cursor: "pointer", right: "5px" }} onClick={(e) => { return handleDescChange("Total Supply") }} /></Nametext>

              <NameText3>Tipped NFTs <ArrowUpwardIcon style={{ color: "#94A1B7", fontSize: "15px", top: "4px", left: "5px", position: "relative", cursor: "pointer", right: "5px" }} onClick={(e) => { return handleAscChange("Tipped NFTs") }} /><ArrowDownwardIcon style={{ color: "#94A1B7", fontSize: "15px", position: "relative", top: "4px", left: "5px", cursor: "pointer", right: "5px" }} onClick={(e) => { return handleDescChange("Tipped NFTs") }} /></NameText3>
              <FanText3> Fans <ArrowUpwardIcon style={{ color: "#94A1B7", fontSize: "15px", top: "4px", left: "5px", position: "relative", cursor: "pointer", right: "5px" }} onClick={(e) => { return handleAscChange("Fans") }} /><ArrowDownwardIcon style={{ color: "#94A1B7", fontSize: "15px", position: "relative", left: "5px", top: "4px", cursor: "pointer", right: "5px" }} onClick={(e) => { return handleDescChange("Fans") }} /></FanText3>
              <TextHeadFloor2>Tipped Value <ArrowUpwardIcon style={{ color: "#94A1B7", top: "4px", fontSize: "15px", left: "5px", position: "relative", cursor: "pointer", right: "5px" }} onClick={(e) => { return handleAscChange("Tipped Value") }} /><ArrowDownwardIcon style={{ color: "#94A1B7", fontSize: "15px", top: "4px", position: "relative", left: "5px", cursor: "pointer", right: "5px" }} onClick={(e) => { return handleDescChange("Tipped Value") }} /></TextHeadFloor2>

            </InnerTableHead>
          </TableHead>

          {ploading ?    //Displaying Table Content
            <div style={{ display: 'flex', flex: 1, justifyContent: "center", alignItems: "center", padding: "20px" }}>
              <Spin />
            </div> : project?.projectArr?.length > 0 && project?.projectArr?.map((item, i) => {
              if (i <= cardIncrement) {
                return (
                  <TableContent key={i + 1} onClick={() => { return handleRoute(item) }}>
                    <SerialNo1>{i + 1}</SerialNo1>
                    <CardIcon2 src={item.banner_image_url} />
                    <CardName2>
                      <CardNameText1 >{item?.name?.slice(0, 25) + "..."}</CardNameText1>
                      <CardImageName src={BlueVerified} />
                    </CardName2>
                    <CardButtonLaunched2>
                      <CardNameText>-</CardNameText>
                    </CardButtonLaunched2>
                    <VolumeContainer2>
                      <VolumeContent>{item.no_fans}</VolumeContent>
                    </VolumeContainer2>
                    <VolumeContainer3>
                      <VolumeContent>{item.no_fans}</VolumeContent>
                    </VolumeContainer3>
                    <FloorContainer2>
                      <VolumeContent>{item.tippedValue}</VolumeContent>
                    </FloorContainer2>

                  </TableContent>
                )
              }
            })


          }
        </TableContainer>

        <ResponsivePage />

        <SpinContainer>

          {/* Loading Spinner After ViewMore */}

          {loading && <Spin />}
        </SpinContainer>

        {project.projectArr && project.projectArr.length <= 0 && !ploading ?
          <BodyFooterContainer1>
            <BodyFooterText>No Projects</BodyFooterText>
          </BodyFooterContainer1>
          :
          <BodyFooterContainer>

            <BodyFooterText>
              You're seeing {cardIncrement >= project?.projectArr?.length ? project?.projectArr?.length : cardIncrement + 1} of {assets?.assetsArr?.length} projects

            </BodyFooterText>
            <ViewButton>
              <ViewButtonText
                onClick={ViewMore}>View more</ViewButtonText>
              <img src={ViewMoreImg} />
            </ViewButton>
            <Toaster />

          </BodyFooterContainer>

        }
      </MiddleContainer>
    </BodyContainer >
  )
}

export default LandingPage